import { Box, Button, TextField, Typography, ButtonGroup } from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import * as React from "react";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { useSupabase, useAuth } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import { ProtectedAdmin } from "./ProtectedAdmin";
import { Navigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { AdminHeader } from "./components/AdminHeader";
import { UsersTable } from "./components/UsersTable";
import { getTableColumns } from "./components/TableColumns";
import { LoadingSpinner } from "./components/LoadingSpinner";

export const Admin_Panel = () => {
  const [Table, setTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeView, setActiveView] = useState("allUsers");
  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let query = supabase.from("users");

        if (activeView === "referrals") {
          const { data: _Table, error } = await query
            .select(
              `
            id,
            full_name,
            referral_code,
            referred_by,
            last_subscription,
            subscription_count,
            status
          `
            )
            .not("referred_by", "is", null) // null olanları hariç tut
            .neq("referred_by", ""); // boş string olanları hariç tut

          if (error) throw error;
          
          const enrichedData = await Promise.all(
            _Table.map(async (user) => {
              const { data: referralCoachData } = await supabase
                .from("users")
                .select("full_name")
                .eq("referral_code", user.referred_by)
                .single();

              return {
                ...user,
                referral_coach: referralCoachData?.full_name || "—",
                invitedCoachStatus: user.status || "—",
              };
            })
          );

          setTable(enrichedData || []);
        } else {
          query = query.select(`
            id,
            full_name,
            phone_number,
            email,
            referral_code,
            last_dashboard_access,
            free_account
          `);

          const { data: _Table, error } = await query;
          if (error) throw error;
          setTable(_Table || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setTable([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeView, supabase]);

  const handleToggleFreeAccount = async (userId, free_account) => {
    try {
      const { error } = await supabase
        .from("users")
        .update({
          free_account: !free_account,
          updated_at: new Date().toISOString(),
        })
        .eq("id", userId);

      if (error) throw error;

      // Refresh the table data
      window.location.href = "/admin";
    } catch (error) {
      console.error("Error updating user account type:", error);
      alert("Failed to update user account type");
    }
  };

  if (user === undefined || user === null) {
    return <LoadingSpinner />;
  }

  if (!ProtectedAdmin(user)) {
    return <Navigate to="/" replace />;
  }

  const columns = getTableColumns(handleToggleFreeAccount);

  return (
    <PageContainer>
      <AdminHeader activeView={activeView} setActiveView={setActiveView} />
      <UsersTable
        data={Table}
        columns={columns}
        loading={loading}
        activeView={activeView}
      />
    </PageContainer>
  );
};
