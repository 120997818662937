import { Button } from "@mui/material";

export const getTableColumns = (handleToggleFreeAccount) => ({
  allUsers: [
    { field: "id", headerName: "ID", width: 20 },
    { field: "coachname", headerName: "Coach Name", width: 120 },
    { field: "email", headerName: "E-mail", width: 150 },
    { field: "referralCode", headerName: "Referral Code", width: 130 },
    { field: "phonenumber", headerName: "Phone Number", width: 150 },
    { 
      field: "lastDashboardAccess", 
      headerName: "Last Dashboard Access", 
      width: 180,
      renderCell: (params) => {
        if (!params.value) return "—";
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    },
    { 
      field: "freeAccount", 
      headerName: "Account Type", 
      width: 200,
      renderCell: (params) => (
        <Button
          variant={params.row.isFreeAccount ? "contained" : "outlined"}
          onClick={() => handleToggleFreeAccount(params.row.userId, params.row.isFreeAccount)}
          sx={{
            backgroundColor: params.row.isFreeAccount ? '#02BD36' : 'transparent',
            color: params.row.isFreeAccount ? '#fff' : '#02BD36',
            borderColor: '#02BD36',
            '&:hover': {
              backgroundColor: params.row.isFreeAccount ? '#01a62f' : 'rgba(2, 189, 54, 0.1)',
              borderColor: '#02BD36'
            },
            textTransform: 'none',
            borderRadius: '20px',
            padding: '5px 15px',
            fontSize: '12px'
          }}
        >
          {params.row.isFreeAccount ? 'Switch to Regular Account' : 'Switch to Free Account'}
        </Button>
      )
    }
  ],
  referrals: [
    { field: "id", headerName: "ID", width: 70 },
    { 
      field: "referralCoach", 
      headerName: "Coach Name", 
      width: 200,
      renderCell: (params) => params.value || "—"
    },
    { field: "coachname", headerName: "Coach via Invitation", width: 200 },
    { 
      field: "invitedCoachStatus", 
      headerName: "Status", 
      width: 130,
      renderCell: (params) => params.value || "—"
    },
    { field: "referralCode", headerName: "Referral Code", width: 130 },
    { 
      field: "referredBy", 
      headerName: "Referred By", 
      width: 130,
      renderCell: (params) => params.value || "—"
    },
    { 
      field: "lastSubscription", 
      headerName: "Last Subscription", 
      width: 150,
      renderCell: (params) => {
        if (!params.value) return "—";
        const date = new Date(params.value);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      }
    },
    { 
      field: "subscriptionCount", 
      headerName: "Subscription Count", 
      width: 150,
      renderCell: (params) => params.row.subscriptionCount ?? "0"
    }
  ]
}); 