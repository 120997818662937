import React, { useEffect, useRef, useState } from "react";
import {
  ListItem,
  ListItemText,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FirstVideoImage } from "../../components/TPDisplay/TPDisplay";
import { MenuIcon } from "./style";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { getYouTubeVideoId } from "../../utils/validate";
import { TPModal } from "../../components/TPDisplay/OpenmodelTP";
import { upsertExercise } from "../../services/query";
import { ExerciseModalLibrary } from "./Exercise_libraryModal";
import CheckIcon from "@mui/icons-material/Check"; // Import CheckIcon

export const ExerciseLibrary = ({ user, eSearch, supabase, reload }) => {
  const [displayedExercises, setDisplayedExercises] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openExerciseModal, setOpenExerciseModal] = useState(false);
  const [SelectedVideo, setSelectedVideo] = useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const [SelectedExercise, setSelectedExercise] = useState(null);
  const [saving, setSaving] = useState(false); // State for saving
  const [saveSuccess, setSaveSuccess] = useState(false); // State for save success

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index);
  };

  const observer = useRef();
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
    setSaveSuccess(false); // Reset success state when menu closes
  };

  const handleClickExercise = (videoId) => {
    setSelectedVideo(videoId);
    setOpenModal(true);
  };

  useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      console.log("Fetching exercises with search term:", eSearch); // Log search term
      const find = displayedExercises.find((e) => e.title === eSearch);
      if (!find) {
        const exercises = await getExercisesFromSupabase(
          page,
          supabase,
          eSearch
        );
        console.log("Fetched exercises:", exercises); // Log fetched exercises
        setDisplayedExercises((prev) => [...prev, ...exercises]);
      }
      setLoading(false);
    };

    fetchExercises();
  }, [page, eSearch]);

  const lastExerciseElementRef = useRef();

  useEffect(() => {
    const observerCallback = (entries) => {
      if (entries[0].isIntersecting && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    const currentObserver = observer.current;
    if (currentObserver) {
      currentObserver.disconnect();
    }

    const newObserver = new IntersectionObserver(observerCallback);
    if (lastExerciseElementRef.current) {
      newObserver.observe(lastExerciseElementRef.current);
    }

    observer.current = newObserver;

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
    };
  }, [loading]);

  return (
    <>
      {displayedExercises
        .filter((e) => e.title.toLowerCase().includes(eSearch.toLowerCase()))
        .map((exercise, index) => {
          const isLastElement = displayedExercises.length === index + 1;
          return (
            <ListItem
              key={index}
              sx={{
                padding: "12px",
                backgroundColor: "#F1F3F4",
                borderRadius: "8px",
                marginBottom: "8px",
              }}
              ref={isLastElement ? lastExerciseElementRef : null}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {exercise.video_link ? (
                      <>
                        <FirstVideoImage
                          videoId={getYouTubeVideoId(exercise.video_link)}
                          height="56px"
                          width="100px"
                          borderRadius="10px"
                          handleClickExercise={() =>
                            handleClickExercise(
                              getYouTubeVideoId(exercise.video_link)
                            )
                          }
                        />

                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            component="span"
                            fontWeight="600  !important"
                            fontSize={"12px"}
                            color={"#525A65"}
                            ml={1}
                            mb={1}
                          >
                            {exercise.title}
                          </Typography>
                          <Typography
                            component="span"
                            fontWeight="400  !important"
                            fontSize={"10px"}
                            color={"#525A65"}
                            ml={1}
                          >
                            {exercise.description?.substring(0, 150)}
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <img
                          src={require("../../assets/Rectangle-ccc.png")}
                          alt={"empty"}
                          style={{
                            maxWidth: 100,
                            maxHeight: 56,
                            width: "100%",
                            height: "100%",
                          }}
                        />
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            component="span"
                            fontWeight="600  !important"
                            fontSize={"12px"}
                            color={"#525A65"}
                            ml={1}
                            mb={1}
                          >
                            {exercise.title}
                          </Typography>
                          <Typography
                            component="span"
                            fontWeight="400  !important"
                            fontSize={"10px"}
                            color={"#525A65"}
                            ml={1}
                          >
                            {exercise.description?.substring(0, 150)}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                }
              />
              <IconButton
                id="basic-button"
                aria-controls={
                  openMenuIndex === index ? "basic-menu" : undefined
                }
                aria-haspopup="true"
                sx={{ padding: "16px 10px 16px 10px !important" }}
                aria-expanded={openMenuIndex === index ? "true" : undefined}
                onClick={(event) => handleClick(event, index)}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenuIndex === index}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  sx={{ fontSize: "14px", color: "#525A65" }}
                  onClick={() => {
                    setSelectedExercise(exercise);
                    setOpenExerciseModal(true);
                    handleClose();
                  }}
                >
                  View Exercise
                </MenuItem>
                <Divider sx={{ my: 1, mr: 1, ml: 1 }} />
                <MenuItem
                  sx={{ fontSize: "14px", color: "#525A65" }}
                  onClick={async () => {
                    setSaving(true); // Set saving state to true
                    setSaveSuccess(false); // Reset success state
                    const payload = {
                      description: exercise.description,
                      title: `${exercise.title}`,
                      tags: exercise.Muscle_Equipment_type,
                      requires_equipment: false,
                      images: [],
                      videos: [
                        {
                          id: getYouTubeVideoId(exercise.video_link),
                          url: exercise.video_link,
                        },
                      ],
                      coach_id: user.id,
                    };
                    try {
                      await upsertExercise(supabase, payload);
                      setSaveSuccess(true); // Set success state to true
                      reload();
                    } catch (error) {
                      console.error("Error saving exercise:", error);
                    } finally {
                      setSaving(false); // Reset saving state
                      handleClose();
                    }
                  }}
                >
                  {saving ? (
                    <CircularProgress size={24} />
                  ) : saveSuccess ? (
                    <CheckIcon color="success" />
                  ) : (
                    "Save to My Exercises"
                  )}
                </MenuItem>
              </Menu>
            </ListItem>
          );
        })}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {openExerciseModal && (
        <ExerciseModalLibrary
          handleClose={() => setOpenExerciseModal(false)}
          exercise={SelectedExercise}
          open={openExerciseModal}
        />
      )}
      {openModal && (
        <TPModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          selected={SelectedVideo}
          exerciseplan={displayedExercises}
        />
      )}
    </>
  );
};

export const getExercisesFromSupabase = async (page, supabase, eSearch) => {
  const pageSize = 10;
  const offset = (page - 1) * pageSize;

  if (eSearch !== "") {
    const { data, error } = await supabase
      .from("fill_exercise")
      .select("*")
      .eq("type", "Solo") // type'ı "Solo" olanları filtrele
      .ilike("title", `%${eSearch}%`); // title'a benzer olanları getir
    if (error) {
      console.error("Error fetching exercises:", error);
      return [];
    }
    return data;
  } else {
    const { data, error } = await supabase
      .from("fill_exercise")
      .select("*")
      .eq("type", "Solo")
      .order("title", { ascending: true })
      .range(offset, offset + pageSize - 1);
    if (error) {
      console.error("Error fetching exercises:", error);
      return [];
    }
    return data;
  }
};
