import React from 'react';
import { Container, Typography, Box, ListItemText, ListItem, List } from '@mui/material';

const PrivacyPolicy = () => {
  const sectionStyle = {
    mt: 4,
    mb: 2,
    color: '#013B12',
    fontFamily: 'Bricolage Grotesque',
    fontWeight: 600,
    fontSize: '1.5rem'
  };

  const paragraphStyle = {
    mb: 1,
    color: '#4A4A4A',
    lineHeight: 1.7
  };

  const subSectionStyle = {
    mt: 3,
    mb: 1,
    color: '#013B12',
    fontFamily: 'Bricolage Grotesque',
    fontWeight: 600,
    fontSize: '1.1rem'
  };

  return (
    <Container maxWidth="md" sx={{ py: 8, px: 4 }}>
      <Typography variant="h3" sx={{
        color: '#013B12',
        fontFamily: 'Bricolage Grotesque',
        fontWeight: 600,
        mb: 2
      }}>
        Privacy Policy
      </Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 6 }}>
        Last updated: October 15, 2024
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography sx={paragraphStyle}>
          Thank you for your interest in the Coach Plus applications, which include the application available through your browser (hereinafter referred to as "Web Application"). The Web Application is hereinafter referred to as the "CoachPlus" or simply the "Service". This privacy policy also applies to our website available at www.coachplus.club (hereinafter referred to as the "Coach Plus Website").
        </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          1. DEFINITIONS
        </Typography>
        <Typography sx={paragraphStyle}>
          "Personal Data" - means any information linked or linkable to a specific individual.
        </Typography>
        <Typography sx={paragraphStyle}>
          "Service" - means the Coach Plus Services.
        </Typography>
        <Typography sx={paragraphStyle}>
          "Visitor" - means an individual other than a User that uses the Public Part, but does not have access to the part of the Coach Plus Service that require a login.
        </Typography>
        <Typography sx={paragraphStyle}>
          "Public Part" - means that part of the Coach Plus Service and the Coach Plus Website that can be visited by both Users and Visitors without logging in.
        </Typography>
        <Typography sx={paragraphStyle}>
          "Restricted Part" - means that part of the Coach Plus Service and the Coach Plus Site that can only be visited by Users using their Login Credentials.
        </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          2. THE INFORMATION WE COLLECT
        </Typography>
        <Typography sx={paragraphStyle}>
        We collect various types of information by or through the Coach Plus  Service. We process personal data through the Coach Plus Service because (1) it is necessary to provide the Coach Plus Services in accordance with the Terms and (2) it may be necessary for the legitimate interests of the Coach Plus  Services, as further explained in the "How We Use the Information We Collect" section of this Policy. We may also process data with your consent by asking for it when necessary.

        </Typography>

        <Typography variant="h6" sx={subSectionStyle}>
          2.1 Information Provided by You
        </Typography>
        <Typography sx={paragraphStyle}>
        When you use the Coach Plus  Services, as a User or Visitor, you may provide, and we may collect, Personal Data. Examples of Personal Data include your name, email address or cell phone number. They also include information about workouts performed, such as time, distance, workout location as a list of coordinates, heart rate during the workout, among others. Personal Data also includes other information such as geographic area or preferences when such information is linked to personally identifiable information. You may provide Personal Data to us in various ways as part of the Coach Plus  Services. For example, during:
        <List>
          <ListItem>
            <ListItemText>
            - registration for Coach Plus Services,            
            
            
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>

            - posting of workout plans by Coaches for Athletes,

            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
            - the posting by Athletes of information related to their workouts,
            
            
                        </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
            - posting notes or health information,
            
            
            
        </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
            
            
            - interact with other Users through communication features or messages or send customer service requests.            
            
            
        </ListItemText>
          </ListItem>
        </List>

        </Typography>
        <Typography variant="h6" sx={subSectionStyle}>
         2.2 Information Collected Automatically
        </Typography>

        <Typography sx={paragraphStyle}>
        When a User or Visitor uses the Coach Plus Site  or the Coach Plus  Website, we may automatically record certain information from the User's or Visitor's device using various types of technologies, including cookies and "local storage" technologies. This automatically collected information may include the IP address or other address or device identifier, the type of Internet browser and device, the pages or other content that the User or Visitor views or interacts with on the Coach Plus  Service or Coach Plus Site , and the dates and times of visiting, accessing or using the Coach Plus  Service or Coach Plus Site . This information is collected from all Users and Visitors. This information is not linked to a specific individual.

</Typography>
        <Typography variant="h5" sx={sectionStyle}>
          3. HOW WE USE THE INFORMATION WE COLLECT
        </Typography>
        <Typography sx={paragraphStyle}>
          We use the information we collect in a variety of ways to provide the Coach Plus Services and conduct related Supplier business, including but not limited to the following:
        </Typography>

        <Typography variant="h6" sx={subSectionStyle}>
          3.1 Operational Objectives
        </Typography>
        <Typography sx={paragraphStyle}>
        We use the information we collect to make it available to connected Coach and Athlete users which is the essence of the Coach Plus  Services. In addition, it is necessary to operate and maintain the features of the Coach Plus  Services, as well as respond to comments and questions and provide support to Users.        </Typography>

        <Typography variant="h6" sx={subSectionStyle}>
          3.2 Improvements
        </Typography>
        <Typography sx={paragraphStyle}>
        We use this information to understand and analyze usage trends and preferences of our Users and Visitors, to improve the Coach Plus  Services and the Coach Plus  Website, and to develop new products, services, features and functionality. If this purpose requires the Supplier to process data related to a Coach or Athlete, then this data will only be used in anonymized or aggregated form.      
        </Typography>
        <Typography variant="h6" sx={subSectionStyle}>
        3.3 Communications
        
        
        
        </Typography>
        <Typography sx={paragraphStyle}>
        We may use a Visitor's or User's email address to contact the Visitor or User (i) for administrative purposes, such as customer service, to address intellectual property infringement, privacy or defamation issues related to Personal Data posted on the Service or (ii) to update the Visitor or User on promotions and events related to products and services offered by us and third parties working with us. You have the opportunity to opt-out of receiving any promotional communications as described below under "Your Choices."        </Typography>

        <Typography variant="h6" sx={subSectionStyle}>
        3.4 Cookies and Tracking Technologies
                </Typography>
        <Typography sx={paragraphStyle}>
        We use automatically collected information and other information collected on the Service through cookies and similar technologies (e.g., local storage) that are necessary for us to (i) personalize the Coach Plus  Services, such as remembering User or Visitor information so that the User or Visitor does not have to re-enter it on a subsequent visit or visits, (ii) monitor and analyze the effectiveness of the Coach Plus  Services and third-party marketing efforts, and (iii) monitor aggregate site usage data, such as total number of visitors and pages viewed. You can read more about this in Section 10.        </Typography>



        <Typography variant="h6" sx={subSectionStyle}>
        3.5 Analytics
                </Typography>
        <Typography sx={paragraphStyle}>
        We use Google Analytics to measure and analyze access to the Coach Plus  Services and traffic to the Public Part of the Coach Plus Site . Google operates independently of us and has its own privacy policy, which we recommend you review. Google may use information collected through Google Analytics to evaluate User and Visitor activity on the Coach Plus Site . For more information, see Google Analytics privacy and data sharing.        </Typography>



        <Typography sx={paragraphStyle}>
        We take steps to protect the technical information collected when using Google Analytics. The data collected will only be used as necessary to troubleshoot technical issues, administer the Coach Plus Site  or Coach Plus  Service, and determine Visitor preferences, but in this case the data will be in a form that does not identify you. We do not use any of this information to identify Visitors or Users and link them to collected data.        </Typography>












        <Typography variant="h5" sx={sectionStyle}>
          4. TO WHOM WE DISCLOSE THE INFORMATION
        </Typography>
        <Typography sx={paragraphStyle}>
        Except as described in this Policy, we do not intentionally disclose Personal Data that we collect or store on the Coach Plus  Services to third parties without the consent of the applicable Visitor, User or Customer. We may disclose information to third parties if the Visitor, User or Customer consents, and in the following circumstances:        </Typography>



        <Typography variant="h6" sx={subSectionStyle}>
        4.1 Information Made Available Without Limitation
                        </Typography>
        <Typography sx={paragraphStyle}>
        Any information that a User voluntarily chooses to post in the Public Part, such as information available on a coach's business card, will be available to any Visitor or User who has access to that content        
        
           </Typography>

           <Typography variant="h6" sx={subSectionStyle}>
           4.2 Non-Personally Identifiable Information                        </Typography>
        <Typography sx={paragraphStyle}>
        We may share certain automatically collected, aggregated or otherwise non-personally identifiable information with third parties for a variety of purposes, including (i) to comply with various reporting obligations; (ii) to extend the range of functionalities offered to users, especially when making AI analyses using external services; (iii) for business or marketing purposes; or (iv) to help such parties understand the interests, habits and usage patterns of our Customers, Users and Visitors with respect to certain content, services or features available through the Coach Plus  Service.        
           </Typography>
           <Typography variant="h6" sx={subSectionStyle}>
           4.3 Law Enforcement, Legal Processes and Compliance
                                   </Typography>
        <Typography sx={paragraphStyle}>
        Supplier may disclose Personal Data or other information if required to do so by law or in the good faith belief that such action is necessary to comply with applicable law, in response to a valid court order, or to cooperate with law enforcement or other government agencies.        
           </Typography>

           <Typography sx={paragraphStyle}>
           Supplier also reserves the right to disclose Personal Data or other information as he, in good faith, deems appropriate or necessary to (i) take steps to protect him from liability, (ii) protect him or others from unfair, improper or unlawful use or activity, (iii) defend against any third party claims or allegations, (iv) protect the security or integrity of the Coach Plus  Services and the Coach Plus Site  and any facilities or equipment used to make them available, or (v) protect Supplier's property or other rights and the property or safety of others.            </Typography>
           <Typography variant="h6" sx={subSectionStyle}>
           4.4 Change of Ownership                                   </Typography>
        <Typography sx={paragraphStyle}>
        Information about Users and Visitors, including Personal Data, may be disclosed and otherwise transferred to an acquirer, successor or assignee as part of an acquisition, conversion, debt financing, asset sale or similar transaction, as well as in the event of insolvency, bankruptcy or receivership, in which information is transferred to one or more third parties as one of our business assets. In such event, Users will be notified of such change.           </Typography>



        <Typography variant="h5" sx={sectionStyle}>
          5. YOUR CHOICES
        </Typography>
        <Typography variant="h6" sx={subSectionStyle}>
        5.1 Access, Correction, Deletion
                 </Typography>
        <Typography sx={paragraphStyle}>
        We respect your privacy rights and provide you with reasonable access to Personal Data that you may have provided in the course of using the Coach Plus  Services. If you wish to access or correct your Personal Data or request deletion of your information, you may contact us as described in the "How to Contact Us" section. Upon your request, we will delete or block any reference to you in our database.   
        
             </Typography>
             <Typography sx={paragraphStyle}>
             You may update, correct or delete your Account information and preferences at any time by accessing the Account Settings page on the Coach Plus  Service. Please note that while any changes you make will be reflected in the active user databases immediately or within a reasonable time, we may retain any information you submit for backup, archiving, fraud and abuse prevention, analysis, to comply with legal obligations, or where we otherwise believe we have a legitimate reason to do so.        
             </Typography>
             <Typography sx={paragraphStyle}>
             A User or Visitor may object to the processing of their Personal Data at any time for legitimate reasons, except where applicable law provides otherwise. If a User, Visitor or Customer believes that their privacy rights granted by applicable data protection laws have been violated, please contact the Coach Plus  Website Data Protection Officer at contact@coachplus.club. You also have the right to lodge a complaint with the data protection authorities.             </Typography>
             
             <Typography variant="h6" sx={subSectionStyle}>
             5.2 Opting Out of Email Communications                 </Typography>
        <Typography sx={paragraphStyle}>
        If you receive email communications from us, you may unsubscribe at any time by following the instructions in the email or by sending an email to the address provided in the "How to Contact Us" section.         
             </Typography>
             <Typography sx={paragraphStyle}>
             Users can review and modify their settings regarding the nature and frequency of promotional communications they receive from us by accessing their Account settings on the Coach Plus  Service.             </Typography>
             <Typography sx={paragraphStyle}>
             Please note that if you opt out of receiving communications from us or otherwise change the nature or frequency of promotional communications you receive from us, it may take up to three business days to process your request.              </Typography>



             <Typography sx={paragraphStyle}>
             In addition, even after you opt out of receiving commercial messages from us, you will continue to receive administrative messages from us regarding the Coach Plus  Services.             </Typography>
        <Typography variant="h5" sx={sectionStyle}>
          6. REDIRECTION TO EXTERNAL PAGES
        </Typography>
        <Typography sx={paragraphStyle}>
        The Coach Plus  Services may contain features or links to websites and services provided by third parties. Any information you provide to third-party sites or services is provided directly to the operators of such services and is subject to the policies of those operators, if any, governing privacy and security, even if accessed through the Coach Plus  Service or the Coach Plus Site . We are not responsible for the content or the privacy and security policies of any third party sites or services to which links or access are provided through the Coach Plus  Service or the Coach Plus Site . We encourage you to review the privacy and security policies of third parties before providing any information to them.        </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          7. YOUR PRIVACY RIGHTS IN CALIFORNIA
        </Typography>
        <Typography sx={paragraphStyle}>
        We will not share any Personal Data with third parties for their direct marketing purposes to the extent prohibited by California law. If our practices change, we will do so in accordance with applicable law and will notify you in advance.

</Typography>

        <Typography variant="h5" sx={sectionStyle}>
          8. INTEREST-BASED ADVERTISING (PROFILING)
        </Typography>
        <Typography sx={paragraphStyle}>
        We do not engage in interest-based advertising, which involves collecting data from various sources and platforms to predict an individual's preferences or interests and delivering to that individual, his or her computer, smartphone or tablet, advertising based on his or her assumed preferences or interests inferred from the collection of data about that individual or other individuals who may have a similar profile or similar interests.        </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          9. DO NOT TRACK POLICY
        </Typography>
        <Typography sx={paragraphStyle}>
        California law requires operators of websites and online services to disclose how they respond to "Do Not Track" signals. Some browsers have built-in "Do Not Track" features. Most of these features, when enabled, send a signal or preference to the website or online service the user is visiting, indicating that the user does not want to be tracked. Because there is not yet a common understanding of how to interpret Do Not Track signals, we do not currently respond to Do Not Track signals. 

</Typography>

        <Typography variant="h5" sx={sectionStyle}>
          10. COOKIES AND DATA STORAGE
        </Typography>
        <Typography sx={paragraphStyle}>
        A cookie is a small text file that a website saves on your computer or mobile device when you browse the website. 
                </Typography>

                <Typography variant="h6" sx={subSectionStyle}>
                10.1 Third Party Cookies
                                        </Typography>
        <Typography sx={paragraphStyle}>
        California law requires operators of websites and online services to disclose whether other third parties may collect personally identifiable information about an individual's online activities from their website or service. Please be advised that we do not allow third parties to use cookies or other technologies to collect information about an identified User's use of the Coach Plus  Services. We do not share with third parties any information that could easily identify you (such as your name, email or phone address).        
           </Typography>
           <Typography variant="h6" sx={subSectionStyle}>
           10.2 Coach Plus  Website Cookies                        </Typography>
        <Typography sx={paragraphStyle}>
        We may use cookies in the Web Application and on the Coach Plus  Website, in particular for analytical purposes. We primarily use "local storage" technology for operational purposes.        
           </Typography>
           <Typography sx={paragraphStyle}>
           Strictly necessary (operational) data - We store this data using "local storage" technology. This is necessary to enable you to navigate the Web Application and the Coach Plus  Website and to use their functions correctly, such as, but not limited to, accessing secure areas of the Web Application. Without the storage of this data, the Coach Plus  Services cannot be provided. This data does not include information that identifies you.        
           </Typography>
           <Typography sx={paragraphStyle}>
           User Preference Data - This data allows us to remember choices you have made (such as your email or the language you have chosen) and provide improved, more personal features of the Coach Plus  Services. This information may be collected using "local storage" technology and is anonymous and cannot track User activity on other websites.        
           </Typography>
           <Typography sx={paragraphStyle}>
           User behavior information (analytics) data - We store this data in cookies or through "local storage" technology. They include information about how Visitors and Users use the site, for example, which subpages they visit most often and whether they receive error messages. This data does not include information that identifies the Visitor or User. All information collected is aggregated and anonymous. They are used solely to improve the performance of the website.         
           </Typography>
           <Typography sx={paragraphStyle}>
           Cookies are stored for no longer than two years, while data stored in "local storage" technology is stored until deleted by the User. The User can delete all data stored by both cookies and local storage technology by deleting the browser history. Please note, however, that by doing so you may lose the information you have saved (e.g. saved login data, page preferences).        
           </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          11. DATA SECURITY
        </Typography>
        <Typography sx={paragraphStyle}>
        We follow generally accepted industry standards to protect the information provided to us, both during transmission and after receipt. Supplier maintains appropriate administrative, technical and physical safeguards to protect Personal Data from accidental or unlawful destruction, accidental loss, unauthorized alteration, unauthorized disclosure or access, misuse, and any other unlawful forms of processing of Personal Data in Supplier's possession.         </Typography>
        <Typography sx={paragraphStyle}>
        This includes, for example, firewalls, password protection, encryption and other access control and authentication measures. We use SSL technology to encrypt data during transmission over the public Internet, and we use application layer security features to further anonymize Personal Data.         </Typography>

        <Typography sx={paragraphStyle}>
        In particular, we do not store or transmit user passwords in plain text, they are stored in the database as double hashed and salted keys. Sensitive data such as messages sent by users, training plan content, notes and health descriptions are additionally encrypted before being stored in the database. Workout files uploaded by external services are stored in a way that makes it impossible to easy associate them with a particular User.        </Typography>
        <Typography sx={paragraphStyle}>
        While we make every effort to store your information securely, no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee the security of information transmitted to us or stored on the Service, and you do so at your own risk. Supplier also cannot guarantee that Personal Data will not be accessed, disclosed, altered or destroyed as a result of a breach of any of our physical, technical or managerial safeguards. If you believe that your Personal Data has been compromised, you should contact us as described in the "How to Contact Us" section.        </Typography>
        <Typography sx={paragraphStyle}>
        If we become aware of a breach of security systems, we will notify you and the authorities of the occurrence in accordance with applicable law.
                </Typography>



        

        <Typography variant="h5" sx={sectionStyle}>
          12. DATA RETENTION PERIOD
        </Typography>
        <Typography sx={paragraphStyle}>
        We will retain Personal Data collected from you for as long as your account has not been deleted or as long as we need it to fulfill the purposes for which we originally collected it, unless otherwise required by law. We will retain and use information as necessary to fulfill our legal obligations, resolve disputes, and enforce our agreements as follows:        </Typography>

        <List>
          <ListItem>
            <ListItemText>
            - The contents of deleted accounts are deleted at the time of deletion,            
            
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>

            - Backups are kept for 6 months,
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
            - Billing information is stored for a period of 6 years after it is transferred to the Supplier in accordance with Polish law.            
            
                        </ListItemText>
          </ListItem>
       
        </List>
        <Typography variant="h5" sx={sectionStyle}>
          13. DATA STORAGE LOCATION
        </Typography>
        <Typography sx={paragraphStyle}>
        The service is provided from a server physically located in Germany. The databases are also located in the Coach Plus  Service data center located in Germany. 
                </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          14. DATA CONTROLLER AND DATA PROCESSOR
        </Typography>
        <Typography sx={paragraphStyle}>
        Supplier does not own any Customer Data submitted by a Customer or User through the Coach Plus  Service. Only the Customer or User is authorized to access, retrieve, and direct the use of such Customer Data, except when, due to the nature of the Coach Plus  Service, the Customer shares such data via the Service with another person (e.g., an Athlete shares data with a Coach). Supplier is largely unaware of what Customer Data is actually stored or shared by a Customer or User on the Service and does not have direct access to such Customer Data except with Customer's consent or to the extent necessary to provide Services to Customer and its Users.        </Typography>
        <Typography sx={paragraphStyle}>
        Because Supplier does not collect or specify the use of any Personal Data submitted to the Service by Coaches and Athletes, and because Supplier does not specify the purposes for which such Personal Data is collected, the means by which such Personal Data is collected, or the means by which such Personal Data is used, Supplier is not acting as a data controller within the meaning of the European Union's General Data Protection Regulation (Regulation (EU) 2016/679, hereinafter "GDPR") and does not have related obligations under the GDPR. The Supplier should only be treated as a Data Processor on behalf of its Customers and Users in relation to any Customer Data containing Personal Data that is subject to the requirements of the GDPR. Except as provided in this Privacy Policy, Supplier shall not transfer or otherwise make available to third parties any Customer Personal Data held in connection with the provision of Coach Plus  Services.        </Typography>
        <Typography sx={paragraphStyle}>
        Coach Plus  is not responsible for the content of Personal Data contained in Customer Data or other information stored on its servers at the discretion of Customer or User, nor is Coach Plus  responsible for the manner in which Customer or User collects, shares, distributes or otherwise processes such information.        </Typography>
        <Typography sx={paragraphStyle}>
        If you need a signed Data Processing Addendum in addition to the Terms and Privacy Policy, you can find the document signed on behalf of Coach Plus  contact us.

</Typography>

        <Typography variant="h5" sx={sectionStyle}>
          15. MINORS AND THE PRIVACY OF CHILDREN
        </Typography>
        <Typography sx={paragraphStyle}>
        Protecting the privacy of children is especially important to us. The Coach Plus  Services are not directed to children under the age of 18, and we do not knowingly collect Personal Data from children under the age of 18 without their parents' consent. If you are under the age of 18, please do not use the Coach Plus  Services. If we learn that Personal Data has been collected from persons under the age of 18 without verifiable parental consent, we will take reasonable steps to delete that information. If you are a parent or guardian and discover that your child under the age of 18 has an Account on the Coach Plus  Services, you may notify us at contact@coachplus.club and request that we delete that child's Personal Data from our systems.        </Typography>
        <Typography sx={paragraphStyle}>
        The Coach Plus  Services are not intended for use by minors and is not intended for the posting of content to be shared publicly or with friends. To the extent that a minor has posted such content on the Coach Plus  Services, the minor has the right to remove that content using the removal options detailed in this Privacy Policy. If you have any questions regarding this topic, please contact us as indicated in the "How to Contact Us" section. Please note that while we offer the ability to remove content, removal of content may not ensure complete or comprehensive removal of that content or information.

</Typography>
        <Typography variant="h5" sx={sectionStyle}>
          16. AMENDMENTS AND UPDATES TO THIS POLICY
        </Typography>
        <Typography sx={paragraphStyle}>
        Please visit this page periodically to be aware of any changes to this Policy, which we may update from time to time. If we modify the Privacy Policy, we will make it available through the Service and indicate the date of the most recent change, and we will comply with applicable law. Your continued use of the Coach Plus  Services after the effective date of the revised Policy indicates that you have read, understand and agree to the current version of the Privacy Policy.        </Typography>

        <Typography variant="h5" sx={sectionStyle}>
          17. HOW TO CONTACT US
        </Typography>
        <Typography sx={paragraphStyle}>
        If you have any questions or comments about this Privacy and Personal Data Policy, our practices regarding the use and disclosure of Personal Data, or your consent election, please email us at contact@coachplus.club     
       </Typography>
       <Typography sx={paragraphStyle}>
       If you have any concerns or complaints regarding this Privacy Policy or your Personal Data, you may contact the Coach Plus  Service Data Protection Officer via email at contact@coachplus.club       </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;