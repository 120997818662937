import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, TextField, Typography, Paper } from "@mui/material";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useEffect, useState } from "react";
import {
  getAthleteByEmail,
  getCoachAthlete,
  getOnboardingFormsByUserId,
  insertAthlete,
  insertCoachAthlete,
  insertOnboardingFormResponse,
  upsertOnboardingForm,
} from "../../services/query";
import { trimAndValidatePhone } from "../../utils/validations";
import InputLabel from "@mui/material/InputLabel";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { countries, sortedCountries } from "../countrycode/countrycode";
import { close, invitestyle } from "./style";

export default function AthleteInviteModal({ open, handleClose, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [email, setEmail] = useState("");
  const [areaCode, setAreaCode] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedForm, setSelectedForm] = useState(null);
  const [default_forms, setDefaultfromst] = useState(false);

  const supabase = useSupabase();
  const { user } = useAuth();

  const handleInvite = async () => {
    setLoading(true);
    if (!email || !email.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      setLoading(false);
      return alert("Please enter a valid Gmail address");
    }
    if (email === user.email) {
      setLoading(false);
      return alert("Please do not enter your own email address");
    }
    if (!selectedForm) {
      setLoading(false);
      return alert("Please select an onboarding form");
    }

    const phone_number = `${areaCode}${phone}`;
    if (trimAndValidatePhone(phone_number) === false) {
      setLoading(false);
      return alert(
        "Please enter a valid phone number consisting of digits only"
      );
    }

    let athlete = {
      status: "PENDING",
      email,
      phone_number,
    };

    const { data: existingAthlete, error: _athleteError } =
      await getAthleteByEmail(supabase, email);

    if (existingAthlete.length > 0) {
      setLoading(false);
      return alert(
        "An athlete with this email already exists in the system for you or another coach."
      );
    } else {
      const { data: newAthlete, error: nae } = await insertAthlete(
        supabase,
        athlete
      );
      if (nae) {
        setLoading(false);
        return alert(
          "An athlete with this phone number already exists in the system for you or another coach."
        );
      }
      if (newAthlete) {
        athlete = newAthlete[0];
      }
    }

    // create "onboarding_form_response"

    const { data: newResponse, error: newResponseError } =
      await insertOnboardingFormResponse(supabase, {
        athlete_id: athlete.id,
        coach_id: user.id,
        form_id: selectedForm.id,
        status: "sent",
      });

    if (newResponseError) {
      const { data: default_forms } = await supabase
        .from("default_forms")
        .select("*")
        .eq("id", selectedForm.id);
      for (let i of default_forms) {
        const { data: insert_default_forms, error } =
          await upsertOnboardingForm(supabase, {
            user_id: user.id,
            custom_questions: i.custom_questions,
            title: i.title,
            welcome_message: i.welcome_message,
            icon: i.icon,
          });
        for (let x of insert_default_forms) {
          const { data: newResponse, error: newResponseError } =
            await insertOnboardingFormResponse(supabase, {
              athlete_id: athlete.id,
              coach_id: user.id,
              form_id: x.id,
              status: "sent",
            });

          const { data: coachAthlete, error: coachAthleteError } =
            await getCoachAthlete(supabase, user.id, athlete.id);

          if (coachAthlete.length === 0) {
            const { data: newCoachAthlete, error: newCoachAthleteError } =
              await insertCoachAthlete(supabase, [
                {
                  coach_id: user.id,
                  athlete_id: athlete.id,
                },
              ]);
          }
          // send welcome message
          await supabase.functions.invoke("welcome-wp", {
            body: {
              phone: phone_number,
              onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
              coach_name: user.full_name,
              client_name: email,
            },
          });
        }
      }
    } else {
      // find coach_athlete
      const { data: coachAthlete, error: coachAthleteError } =
        await getCoachAthlete(supabase, user.id, athlete.id);

      if (coachAthlete.length === 0) {
        await insertCoachAthlete(supabase, [
          {
            coach_id: user.id,
            athlete_id: athlete.id,
          },
        ]);
      }

      // send welcome message
      await supabase.functions.invoke("welcome-wp", {
        body: {
          phone: phone_number,
          onboarding_url: `https://app.coachplus.club/onboarding-form/${newResponse[0].id}`,
          coach_name: user.full_name,
          client_name: email,

        },
      });
    }
    setLoading(false);
    onSuccess();
    handleClose();
    // create coach_athletes
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const getForms = async () => {
      const { data: _forms } = await getOnboardingFormsByUserId(
        supabase,
        user.id
      );
      if (_forms.length === 0) {
        const { data: _forms } = await supabase
          .from("default_forms")
          .select("*");
        setDefaultfromst(true);
        setSelectedForm(_forms[0]);
        setForms(_forms || []);
      } else {
        setDefaultfromst(false);
        setSelectedForm(null);
        setForms(_forms || []);
      }
    };

    getForms();
  }, [user, open, supabase]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          borderRadius: "24px",
          width: "fit-content",
          backgroundColor: "#FBFBFB",
        },
        ".css-13680nc .MuiOutlinedInput-root": {
          padding: "7px 18px !important",
        },
      }}
    >
      <Box sx={{ padding: "40px 23px 16px 23px" }}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          pl={3}
          pr={3}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600 !important",
              color: "#000000",
            }}
          >
            Invite your client
          </Typography>
          <Button
            startIcon={<CloseIcon />}
            size="small"
            onClick={handleClose}
            sx={close}
          ></Button>
        </Box>
        <DialogContent sx={{ paddingBottom: "5px !important" }}>
          <DialogContentText
            sx={{
              mb: 2,
              fontSize: "14px",
              color: "#000000",
              fontWeight: "400 !important",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Select an onboarding form, enter your clients Gmail address and
            invite them to join you in Coach Plus.
          </DialogContentText>
          <Box sx={{ mb: 3 }}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{
                paddingLeft: "24px",
                color: "#838F99",
                fontWeight: "400 !important",
              }}
            >
              Gmail address
            </InputLabel>
            <TextField
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              sx={{
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "0px solid #DAD9D8",
                },
                ".MuiInputBase-input": {
                  fontSize: "12px",
                  padding: "14px 24px",
                  color: "#525A65",
                  fontWeight: "400 !important",
                },
              }}
              fullWidth
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Country code
                </InputLabel>
                <Autocomplete
                  onChange={(e, v) => setAreaCode(v?.phone)}
                  options={sortedCountries}
                  sx={{
                    width: "84%",
                    ".MuiSvgIcon-root": {
                      fontSize: "14px",
                    },
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      padding: "0px !important",
                      height: "50px",
                      border: "0px solid #DAD9D8",
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "7.5px 5px 7.5px 18px !important",
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="+00" />
                  )}
                  getOptionLabel={(option) => `+${option.phone} | ${option.label}`}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Typography sx={{ fontSize: '14px', color: '#525A65' }}>
                          +{option.phone}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ borderRadius: "16px", backgroundColor: "#FBFBFB" }} />
                  )}
                  ListboxProps={{
                    sx: {
                      '& .MuiAutocomplete-option': {
                        color: "#525A65",
                        fontSize: "14px",
                        borderBottom: '1px solid #CECECE',
                        padding: '10px 10px',
                        margin: "0px 10px",
                        '&:last-child': {
                          borderBottom: 'none'
                        }
                      }
                    }
                  }}
                  popupIcon={
                    <div style={{ display: 'flex', width: "15px", height: "15px", alignItems: 'center', paddingRight: "10px !important", pointerEvents: 'none' }}>
                      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z" fill="#DAD9D8" />
                      </svg>
                    </div>
                  }
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  paddingLeft: "0px !important",
                  ".css-cmlpbm": {
                    widht: "100% !important",
                  },
                  ".MuiFormControl-root": {
                    widht: "100% !important",
                  },
                }}
              >
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Telephone number
                </InputLabel>
                <TextField
                  name="phone"
                  type="number"
                  placeholder="Telephone number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      border: "0px solid #DAD9D8",
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "14 24px",
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          {default_forms === true ? (
            <></>
          ) : (
            <>
              <Box mb={3}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Form
                </InputLabel>
                <Autocomplete
                  options={forms}
                  sx={{
                    width: "100%",
                    ".MuiInputBase-root": {
                      borderRadius: "20px",
                      border: "0px solid #DAD9D8",
                    },
                    ".MuiInputBase-input": {
                      fontSize: "12px",
                      padding: "14px 24px",
                      color: "#525A65",
                      fontWeight: "400 !important",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select a Form" />
                  )}
                  onChange={(e, v) => setSelectedForm(v)}
                  getOptionLabel={(option) => ` ${option.title}`}
                  getOptionKey={(option) => option.id}
                  PaperComponent={(props) => (
                    <Paper {...props} sx={{ borderRadius: "16px", backgroundColor: "#FBFBFB" }} />
                  )}
                  ListboxProps={{
                    sx: {
                      '& .MuiAutocomplete-option': {
                        color: "#525A65",
                        fontSize: "14px",
                        borderBottom: '1px solid #CECECE', // Add a line between options
                        padding: '10px 10px', // Add space on the right and left
                        margin: "0px 10px",
                        '&:last-child': {
                          borderBottom: 'none' // Remove the line after the last option
                        }
                      }
                    }
                  }}
                  popupIcon={
                    <div style={{ display: 'flex', width: "15px", height: "15px", alignItems: 'center', paddingRight: "10px !important", pointerEvents: 'none' }}>
                      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z" fill="#DAD9D8" />
                      </svg>
                    </div>
                  }
                />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ paddingRight: "21px !important" }}>
          <Button onClick={handleInvite} disabled={loading} sx={invitestyle}>
            Invite
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
