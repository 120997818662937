import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { supabase } from "../../services/supabase";
import {
  getPlansByCoachId,
  getExercisesByCoachId,
  insertEvent,
  getPlanByIdPublic,
  deletePlanEvents,
  plan_client_upsert,
  insertExercisePlans,
} from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { TrainingPlanModal } from "../TrainingPlanModal/TrainingPlanModal";
import { button3Style, searchStyle, LeftArrow, buttonStyle } from "./style";
import { getAthleteName } from "../../utils/selectors";
import {
  getTimeZone,
  renderGCalDescription,
  getSQLDate,
} from "../../utils/calendar";
import { addDays, format } from "date-fns";
import { useCalendar } from "../../providers/CalendarProvider";
import { TimeSelectionModal } from "./TimeSelectionModal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from '@mui/material/CircularProgress';

const loadingOverlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(251, 251, 251, 0.7)',
  zIndex: 1000,
  borderRadius: '24px',
};

export const AddNewTraining = ({
  back,
  athlete,
  title,
  date,
  reload,
  onEventDelete,
  onSuccess,
}) => {
  const { user } = useAuth();
  const [trainingPlanOpen, setTrainingPlanOpen] = useState(false);
  const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open
  const [SelectedPlan, setSelectedPlan] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tpSearch, setTpSearch] = useState("");
  const [plans, setPlans] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [selectedPlanForTime, setSelectedPlanForTime] = useState(null);
  const [isTrainingDialogOpen, setIsTrainingDialogOpen] = useState(false);
  const [pendingPlan, setPendingPlan] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index); // Set the index of the clicked plan
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null); // Close the menu
  };
  const loadPlans = () => {
    getPlansByCoachId(supabase, user.id).then(({ data }) => {
      // More strict validation of plans
      const validPlans = (data || []).filter((plan) => {
        return (
          plan && typeof plan.name === "string" && plan.name.trim().length > 0
        );
      });
      setPlans(validPlans);
    });
  };
  const loadExercises = () => {
    getExercisesByCoachId(supabase, user.id).then(({ data }) => {
      setExercises(data || []);
    });
  };

  useEffect(() => {
    title("Select Training Plan");
    if (!user) {
      return;
    }
    loadPlans();
    loadExercises();
    setIsInitializing(false);
  }, [user]);

  const { createEvent } = useCalendar();

  const handleSubmit = async (plan, time = null) => {
    try {
      // Add validation check at the start
      if (!plan || typeof plan !== "object") {
        console.error("Invalid plan object:", plan);
        throw new Error("Invalid plan object");
      }

      const currentDate = new Date(date);
      const gcal_id = athlete.calendars[0].gcal_id;

      const start = format(currentDate, "yyyy-MM-dd");
      const end = format(addDays(currentDate, 1), "yyyy-MM-dd");
      const sb_start = getSQLDate(currentDate);
      const planID = plan?.plan_id ? plan.plan_id : plan.id;

      if (!planID) {
        console.error("Plan ID is missing");
        throw new Error("Plan ID is missing");
      }

      // Add error handling for getPlanByIdPublic
      const planResponse = await getPlanByIdPublic(supabase, planID);

      if (!planResponse || !planResponse.data) {
        console.error("Invalid response from getPlanByIdPublic:", planResponse);
        throw new Error("Failed to get plan details");
      }

      const getplan = planResponse.data;

      if (!getplan || !getplan[0]) {
        console.error("Plan not found:", planID);
        throw new Error("Plan not found");
      }


      const jsonPlan_Client = {
        name: plan.type === "Group" 
          ? (getplan[0].exercise_plans?.[0]?.exercises?.title || "Untitled Class")
          : (plan.name || getplan[0].name || "Untitled Plan"),
        type: getplan[0].type || "Unknown",
        start_time: time?.start_time || null,
        end_time: time?.end_time || null,
        overview: plan.overview || getplan[0].overview || "",
        created_at: new Date(),
        coach_id: user.id,
      };


      const { data: plan_client, error: planClientError } =
        await plan_client_upsert(supabase, jsonPlan_Client);

      if (planClientError) {
        console.error("Plan client error:", planClientError);
        throw new Error(
          `Failed to create plan client: ${planClientError.message}`
        );
      }

      if (!plan_client || !plan_client[0]) {
        console.error("No plan client data returned");
        throw new Error("No plan client data returned from database");
      }

      const timezoneName = time?.start_time?.split(" ")[1] || "Europe/Paris";

      const gcal_event = {
        summary: `C+ ${getAthleteName(athlete)} - ${
          plan.type === "Group" 
          ? (getplan[0].exercise_plans?.[0]?.exercises?.title || "Untitled Class")
          : (plan.name || getplan[0].name || "Untitled Plan")
        }`,
        description: renderGCalDescription(
          plan_client[0],
          athlete.email,
          plan_client[0].id
        ),
        start: time?.start_time
          ? {
              dateTime: `${start}T${time.start_time.split(" ")[0]}:00`,
              timeZone: timezoneName,
            }
          : {
              date: start,
            },
        end: time?.end_time
          ? {
              dateTime: `${start}T${time.end_time.split(" ")[0]}:00`,
              timeZone: timezoneName,
            }
          : {
              date: end,
            },
        reminders: {
          useDefault: true,
        },
      };

      const createdGcalEvent = await createEvent(gcal_id, gcal_event);
      if (!createdGcalEvent) {
        console.error("Failed to create Google Calendar event");
        throw new Error("Failed to create Google Calendar event");
      }

      const sb_event = {
        date: sb_start,
        calendar_id: athlete.calendars[0].id,
        gcal_id,
        payload: createdGcalEvent,
        coach_id: user.id,
        athlete_id: athlete.id,
        plan_client_id: plan_client[0].id,
        plan_id: planID,
        workout: getplan[0].exercise_plans?.[0]?.exercises?.workout || "",
      };

      const { data: eventData, error: eventError } = await insertEvent(
        supabase,
        sb_event
      );

      if (eventError) {
        console.error("Event insert error:", eventError);
        throw new Error(`Failed to insert event: ${eventError.message}`);
      }

      const exercisePlans = (getplan[0].exercise_plans || []).map((e) => ({
        plan_client_id: plan_client[0].id,
        exercise_id: e.exercise_id,
        order: e.order,
        notes: e.notes || "",
        sets: e.sets || 0,
        isSuperset: e.isSuperset || false,
        supersetGroup: e.supersetGroup || null,
      }));

      if (exercisePlans.length > 0) {
        await insertExercisePlans(supabase, exercisePlans);
      }

      if (reload) {
        reload();
      }
      if (onSuccess) {
        onSuccess();
      }
      back();
    } catch (error) {
      console.error("Detailed error:", {
        message: error.message,
        stack: error.stack,
        error,
      });
      alert(`Failed to create training plan: ${error.message}`);
      throw error;
    }
  };

  const throttledHandleSubmit = useCallback(
    async (plan, time = null) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      try {
        await handleSubmit(plan, time);
      } finally {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000);
      }
    },
    [isSubmitting]
  );

  const handlePlanSelection = (plan) => {
    if (!plan || !plan.type) {
      console.error("Invalid plan object:", plan);
      return;
    } 

    if (plan.type === "Group") {
      throttledHandleSubmit(plan);
    } else {
      setPendingPlan(plan);
      setIsTrainingDialogOpen(true);
    }
  };

  const handleTrainingResponse = (isTraining) => {
    if(isTraining!==false && isTraining!==true){
      return;
    }
    setIsTrainingDialogOpen(false);

    if (isTraining) {
      if (pendingPlan.type === "Solo") {
        setTimeModalOpen(true);
        setSelectedPlanForTime(pendingPlan);
      } else {
        throttledHandleSubmit(pendingPlan);
      }
    } else if (pendingPlan.type === "Solo") {
      throttledHandleSubmit(pendingPlan);
    }
    setPendingPlan(null);
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#FBFBFB",
          borderRadius: "24px",
          height: "555px",
          display: trainingPlanOpen ? "none" : "block",
          border: "none",
          boxShadow: "unset",
          position: 'relative',
        }}
      >
        {(isInitializing || isSubmitting) && (
          <Box sx={loadingOverlayStyle}>
            <CircularProgress 
              size={40}
              sx={{
                color: '#2998E9',
              }}
            />
          </Box>
        )}
        <Button
          startIcon={<LeftArrow />}
          onClick={back}
          sx={{
            fontSize: "12px",
            textTransform: "none",
            ml: 2,
            mt: 2,
            mb: 1,
            ".MuiButton-icon": {
              marginRight: "3px",
            },
          }}
        >
          <span style={{ marginTop: "1px" }}>Back</span>
        </Button>
        <Box p={3} pb={0} pt={1} sx={{ height: "100px" }}>
          <Box pb={2} display={"flex"} alignItems={"flex-end"}>
            <Typography
              sx={{
                fontSize: {
                  xl: "16px",
                  lg: "16px",
                  md: "13px",
                  sm: "13px",
                  xs: "13px",
                },
                fontWeight: "600 !important",
                color: "#525A65",
              }}
            >
              My training plans
            </Typography>
            <Button
              ml={"auto !important"}
              sx={button3Style}
              onClick={() => setTrainingPlanOpen(true)}
            >
              + Add new training plan
            </Button>
          </Box>

          <TextField
            id="outlined-search"
            label="Search plan by name..."
            type="search"
            fullWidth
            value={tpSearch}
            sx={searchStyle}
            onChange={(e) => setTpSearch(e.target.value)}
          />
        </Box>
        <Box pl={2} pr={2}>
          <TableContainer sx={{ height: 390 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                borderSpacing: "10px 11px",
                borderCollapse: "unset",
                border: "none !important",
              }}
            >
              <TableBody>
                {plans
                  .filter((plan) => {
                    if (!tpSearch) return true; // Show all plans if search is empty
                    if (!plan?.name) return false; // Filter out plans without names
                    return plan.name
                      .toLowerCase()
                      .includes(tpSearch.toLowerCase());
                  })
                  .map((plan, i) => (
                    <TableRow key={i} role="checkbox" tabIndex={-1}>
                      <TableCell
                        onClick={(e) => {
                          if (
                            e.target.closest("#basic-button") ||
                            e.target.closest("#basic-menu")
                          ) {
                            return;
                          }
                          handlePlanSelection(plan);
                        }}
                        sx={{
                          borderRadius: "8px",
                          backgroundColor: "#F1F3F4",
                          padding: "17px 0px 11px 22px !important",
                          borderBottom: "none !important",
                          lineHeight: "0px !important",
                          cursor: isSubmitting ? "wait" : "pointer",
                          "&:hover": {
                            backgroundColor: "#E8EAEB",
                          },
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm container>
                            <Grid
                              item
                              xs
                              container
                              direction="column"
                              spacing={2}
                            >
                              <Grid item xs>
                                <Typography
                                  sx={{
                                    mb: 1,
                                    display: "inline-block",
                                    color: "#525A65",
                                    borderBottom: "1px solid gray",

                                    fontSize: {
                                      xl: "14px",
                                      lg: "13px",
                                      md: "12px",
                                      sm: "12px",
                                      xs: "12px",
                                    },
                                    fontWeight: "800 !important",
                                    lineHeight: "1 !important",
                                    letterSpacing: "0 !important",
                                  }}
                                >
                                  <Box>{plan?.type==="Solo" ? <>Training Plan</> :<>Group/Gym Class</>}</Box>
                                </Typography>
                                <Typography
                                  sx={{
                                    mb: 1,
                                    color: "#525A65",
                                    fontSize: {
                                      xl: "12px",
                                      lg: "10px",
                                      md: "9px",
                                      sm: "9px",
                                      xs: "9px",
                                    },
                                    fontWeight: "600 !important",
                                    lineHeight: "1 !important",
                                    letterSpacing: "0 !important",
                                  }}
                                >
                                  {plan?.name || ""}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    mb: 1,
                                    color: "#525A65",
                                    fontSize: {
                                      xl: "12px",
                                      lg: "10px",
                                      md: "9px",
                                      sm: "9px",
                                      xs: "9px",
                                    },
                                    fontWeight: "400 !important",
                                    lineHeight: "1 !important",
                                    letterSpacing: "0 !important",
                                  }}
                                >
                                  {plan?.overview?.substring(0, 150) || ""}
                                </Typography>
                                <Typography
                                  sx={{
                                    mb: 1,
                                    color: "#525A65",
                                    fontSize: {
                                      xl: "12px",
                                      lg: "10px",
                                      md: "9px",
                                      sm: "9px",
                                      xs: "9px",
                                    },
                                    fontWeight: "400 !important",
                                    lineHeight: "1 !important",
                                    letterSpacing: "0 !important",
                                  }}
                                >
                                  {plan?.exercise_plans?.map(
                                    (t) => t?.exercises?.title + " · "
                                  ) || ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item pt={"12px"}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                paddingRight: "8px",
                              }}
                            >
                              <IconButton
                                id="basic-button"
                                aria-controls={
                                  openMenuIndex === i ? "basic-menu" : undefined
                                }
                                aria-haspopup="true"
                                sx={{
                                  padding: "16px 10px 16px 10px !important",
                                }}
                                aria-expanded={
                                  openMenuIndex === i ? "true" : undefined
                                }
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleClick(event, i);
                                }}
                              >
                                <svg
                                  width="16"
                                  height="4"
                                  viewBox="0 0 16 4"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="2"
                                    cy="2"
                                    r="2"
                                    transform="rotate(-90 2 2)"
                                    fill="#525A65"
                                  />
                                  <circle
                                    cx="8"
                                    cy="2"
                                    r="2"
                                    transform="rotate(-90 8 2)"
                                    fill="#525A65"
                                  />
                                  <circle
                                    cx="14"
                                    cy="2"
                                    r="2"
                                    transform="rotate(-90 14 2)"
                                    fill="#525A65"
                                  />
                                </svg>
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenuIndex === i}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setTrainingPlanOpen(true);
                                    setSelectedPlan(plan);
                                    handleClose();
                                  }}
                                >
                                  View/Edit
                                </MenuItem>
                                <MenuItem onClick={() => handleSubmit(plan)}>
                                  Select
                                </MenuItem>
                                <MenuItem
                                  onClick={async () => {
                                    if (!plan?.name) {
                                      console.error("Plan name is missing");
                                      return;
                                    }
                                    if (
                                      window.confirm(
                                        `Deleting training plan: "${plan.name}". Please confirm.`
                                      )
                                    ) {
                                      await deletePlanEvents(supabase, plan.id);
                                      loadPlans();
                                      reload();
                                    }
                                    handleClose();
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </div>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      {trainingPlanOpen && (
        <TrainingPlanModal
          exercises={exercises}
          open={trainingPlanOpen}
          plan={SelectedPlan}
          isvisible={true}
          handleClose={() => {
            setTrainingPlanOpen(false);
            setSelectedPlan(null);
          }}
          onSuccess={() => {
            loadPlans();
            reload();
          }}
          onCreateEvent={(planId, time) => {
              
            handleSubmit(planId, time);
          }}
        />
      )}
      <TimeSelectionModal
        open={timeModalOpen}
        onClose={() => {
          setTimeModalOpen(false);
          setSelectedPlanForTime(null);
        }}
        onSubmit={({ start_time, end_time }) => {
          
          throttledHandleSubmit(selectedPlanForTime, { start_time, end_time });
          setTimeModalOpen(false);
          setSelectedPlanForTime(null);
        }}
        type={selectedPlanForTime?.type}
      />
      <Dialog
        open={isTrainingDialogOpen}
        onClose={() => handleTrainingResponse(false)}
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#525A65",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Is this session an in-person training?
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "24px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={() => handleTrainingResponse(true)}
              variant="contained"
              sx={{
                backgroundColor: "#2196F3",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1976D2",
                },
                borderRadius: "8px",
                textTransform: "none",
                padding: "8px 24px",
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => handleTrainingResponse(false)}
              variant="outlined"
              sx={{
                color: "#525A65",
                borderColor: "#525A65",
                "&:hover": {
                  borderColor: "#2196F3",
                  color: "#2196F3",
                },
                borderRadius: "8px",
                textTransform: "none",
                padding: "8px 24px",
              }}
            >
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
