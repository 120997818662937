import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
  Box,
  Typography,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "../../providers/AuthContextProvider";

const CloseButtonIcon = () => (
  <Box sx={{ position: 'relative' }}>
    <svg width="40" height="40"  viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#E3E2E0"/>
    </svg>
    <Box sx={{
      position: 'absolute',
      top: '47%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <CloseIcon sx={{ color: '#013B12', fontSize: '24px',fontWeight:"800!important"}} />
    </Box>
  </Box>
);

export const PricingModal = ({ open, onClose }) => {
  const { signIn } = useAuth();

  const handleTryClick = async () => {
    try {
      await signIn();
      onClose();
    } catch (error) {
      console.error('Error during sign in:', error);
    }
  };

  const PricingCard = ({ title, price, features, isPopular }) => (
    <Box
      sx={{
        backgroundColor: isPopular ? '#E3E2E0' : '#0000004D',
        borderRadius: '24px',
        p: 3,
        border: isPopular ? '1px solid #02BD36' : '1px solid none',
        height: '100%',
        position: 'relative',
      }}
    >
      <Typography variant="h6" sx={{ 
        color: isPopular ? '#000' : '#E3E2E0',
        fontWeight: isPopular ? '900' : '500',
        fontSize: '20px',
        fontFamily: 'Barlow Condensed, sans-serif',
        mb: 3 
      }}>
        {title}
      </Typography>
      <Typography sx={{ 
        color: isPopular ? '#000' : '#E3E2E0',
        fontSize: '36px',
        fontWeight: isPopular ? 'bold' : '500',
        mb: 4 
      }}>
        {price.split('€')[0]}
        <span style={{ fontSize: '24px' }}>
          €
        </span>
        {price.includes('/mo') ? '/mo' : ''}
      </Typography>
      <Box sx={{ mb: 3 }}>
        {features.map((feature, index) => (
          <Box key={index} sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            mb: 2,
            justifyContent: 'center'
          }}>
            <Typography sx={{ 
              color: isPopular ? '#000' : '#E3E2E0',
              fontSize: '14px',
              fontWeight: (feature === 'Up to 3 clients' || feature === 'Free plan +') ? '800!important' : '400',
              lineHeight: '1.6',
              whiteSpace: 'pre-line'
            }}>
              {feature}
            </Typography>
          </Box>
        ))}
      </Box>
      {isPopular&&(
      <Button
        variant="outlined"
        onClick={handleTryClick}
        sx={{
          width: '50%',
          bgcolor: isPopular ? '#013B12' : '#FFFFFF',
          color: '#02BD36',
          border: '1px solid #02BD36',
          borderRadius: '20px',
          py: 1,
          marginTop: '20px',
          textTransform: 'none',
          '&:hover': {
            bgcolor: isPopular ? '#013B12' : '#FFFFFF',
            borderColor: '#02BD36',
          }
        }}
      >
        Try for free
      </Button>)}
    </Box>
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: '24px',
          bgcolor: '#013B12',
          p: 2,
          border: '1px solid #02BD36',
          '& .MuiDialogContent-root': {
            padding: '20px',
            paddingTop:"0"
          }
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton 
          onClick={onClose}
          sx={{
            padding: 0,
            '&:hover': { bgcolor: 'transparent' }
          }}
        >
          <CloseButtonIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Typography sx={{ display:"flex", justifyContent:"center", color: '#E3E2E0', mb: 4,paddingTop:"0", marginTop:"0",fontSize: '20px' }}>
        Manage all your clients in one place.
        </Typography>

        <Grid container spacing={6} justifyContent="center" >
          <Grid item xs={12} md={6}  width={400} sx={{textAlign:"center"}}>
            <PricingCard
              title="Free Plan"
              price="0€"
              features={[
                'Up to 3 clients',
                'Automation flow integrations\n(Email,Calendar,WhatsApp)',
                '2,000+ Exercises with Videos',
                'Custom Check-in Forms',
                'Custom Exercises Creation & Bulk Upload',
                'Unlimited Workout Plans',
                'Email support'
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} width={300} sx={{textAlign:"center",color:"#000!important"}}>
            <PricingCard
            
              title="Pro Plan"
              price="34"
              features={[
                'Free plan +',
                'Up to 35 clients',
                'Chat support',
                'Cancel anytime'
              ]}
              isPopular
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}; 