import { useEffect, useState } from "react"
import {
    Box,
    Button,
    Divider,
    IconButton,
    Paper,
    Typography,
    Snackbar,
    Alert,
} from "@mui/material";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { supabase } from "../../services/supabase";
import { deleteSBEvent, deletePlanClient } from "../../services/query";
import { useAuth } from "../../providers/AuthContextProvider";
import { useCalendar } from "../../providers/CalendarProvider";
import { buttonStyle, MenuIcon } from "./style"
import { AddNewTraining } from "./AddNewTrainnig";
import { ViewExerciseAndPlan } from "./ViewExerciseAndPlan";
import CircularProgress from '@mui/material/CircularProgress';

const loadingOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(251, 251, 251, 0.7)',
    zIndex: 1000,
    borderRadius: '24px',
};

export const ClientDetailTraining = ({ events, height, date, onNewEventClick, onEventDelete = () => null, currentAthlete, reload }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuIndex, setOpenMenuIndex] = useState(null); // Track which menu is open
    const { deleteEvent } = useCalendar();
    const [SelectedPlan, setSelectedPlan] = useState([])
    const [newTraining, setnewTraining] = useState(false);
    const [Title, setTitle] = useState("");
    const [prevLength, setPrevLength] = useState(0);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success",
    });
    const [isLoading, setIsLoading] = useState(false);

    const deleteCEvent = async (calId, evId, gc_id, plcid) => {
        await deleteEvent(calId, gc_id);
        await deleteSBEvent(supabase, evId);
        await deletePlanClient(supabase, plcid)
        onEventDelete(calId, evId);
        reload();
    };

    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(index); // Set the index of the clicked plan
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null); // Close the menu
    };

    useEffect(() => {
        setPrevLength(events.length);
        
        setnewTraining(false);
        setOpenMenuIndex(null);
        setSelectedPlan([]);
        
        // If a new training was added
        if (prevLength < events.length && prevLength !== 0 && newTraining) {
            setIsLoading(false);
            setSnackbar({
                open: true,
                message: "Training plan assigned successfully!",
                severity: "success"
            });
        } else{
            const timeout = setTimeout(() => {
                setIsLoading(false);
            }, 500);
            
            // Cleanup function
            return () => clearTimeout(timeout);
        }
    }, [events, prevLength]);
  

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") return;
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    return (
        <>
            <Paper variant="outlined" sx={{ 
                borderRadius: "24px", 
                height: height, 
                backgroundColor: "#FBFBFB", 
                border: "none",
                position: 'relative' 
            }}>
                {isLoading && (
                    <Box sx={loadingOverlayStyle}>
                        <CircularProgress 
                            size={40}
                            sx={{
                                color: '#2998E9',
                            }}
                        />
                    </Box>
                )}
                <Box sx={{ height: "45px", backgroundColor: "#F0F9FF", borderTopLeftRadius: "24px", borderTopRightRadius: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography color={"#043A8D"} fontSize={"12px"}>{SelectedPlan.length === 0 && !newTraining ? date : Title}</Typography>
                </Box>
                <Divider mb={2} />
                {!newTraining && SelectedPlan.length === 0 &&
                    (<>
                        <Box pt={4} pb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                           {events.length>0&&(
                            <Typography color={"#525A65"} fontSize={"16px"} fontWeight={"600 !important"}>Training Program</Typography>
                           )} 
                        </Box>
                        <Box sx={{
                            maxHeight: "450px",
                            display: "flex",
                            height: events.length > 0 ? Array.isArray(events.events) && !events.events[0] ? "450px" : "auto" : "450px",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            pr: 1,
                            pl: 1
                        }}>

                            {events.length === 0 ? (
                                <>
                                    <Typography color={"#525A65"} fontSize={"20px"} >No scheduled training for today</Typography>
                                </>
                            ) : <>
                                <TableContainer sx={{ height: "100%" }}>
                                    <Table stickyHeader aria-label="sticky table" sx={{
                                        borderSpacing: "10px 11px",
                                        borderCollapse: "unset",
                                        border: "none !important",
                                    }}>
                                <TableBody>
                                        {events.map((plan, i) => (
                                            <TableRow key={i} role="checkbox" tabIndex={-1}>
                                                <TableCell sx={{
                                                        borderRadius: "8px", backgroundColor: "#F1F3F4",
                                                        padding: "12px",
                                                        borderBottom: "none !important", lineHeight: "0px !important"
                                                    }}>
                                                        <Grid container spacing={2}>
                                                                 <Grid item xs={12} sm container display={"flex"} alignItems={"center"}>
                                                                <Typography
                                                                    sx={{
                                                                    color: "#525A65",
                                                                    fontSize: { md: "14px", xs: "10px" },
                                                                    fontWeight: "500 !important",
                                                                    lineHeight: "1 !important",
                                                                    }}
                                                                >
                                                                 { plan.plan[0]?.type==="Solo" ? <>Training Plan</> :<>Group/Gym Class</>}
                                                                </Typography>
                                                                <span style={{
                                                                    margin: "0 8px",
                                                                    width: "4px",
                                                                    height: "4px",
                                                                    backgroundColor: "#525A65",
                                                                    borderRadius: "50%",
                                                                    display: "inline-block"
                                                                }}></span>
                                                                
                                                                <Typography
                                                                    sx={{
                                                                    color: "#525A65",
                                                                    fontSize: { md: "14px", xs: "10px" },
                                                                    fontWeight: "400 !important",
                                                                    lineHeight: "1 !important",
                                                                    marginRight: "8px"
                                                                    }}
                                                                >
                                                                    {plan.plan[0]?.name}
                                                                </Typography>
                                                                <Typography
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '4px',
                                                                color: "#525A65",
                                                                fontSize: { md: "14px", xs: "13px" },
                                                                fontWeight: "400 !important",
                                                                lineHeight: "1 !important",
                                                                marginLeft: "auto",
                                                                backgroundColor: "#f5f7fa",
                                                                padding: "4px 12px",
                                                                borderRadius: "16px",
                                                                "& span.time-separator": {
                                                                color: "#838F99",
                                                                mx: 1
                                                                }
                                                            }}
                                                            >
                                                            <span>{plan.plan[0]?.start_time}</span>
                                                            <span className="time-separator">-</span>
                                                            <span>{plan.plan[0]?.end_time}</span>
                                                            </Typography>
                                                                                                                            </Grid>
                                                        <Grid item pt={"12px"}>
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", paddingRight: "8px" }}>
                                                                <IconButton
                                                                    id="basic-button"
                                                                    aria-controls={openMenuIndex === i ? 'basic-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    sx={{ padding: "16px 10px 16px 10px !important" }}
                                                                    aria-expanded={openMenuIndex === i ? 'true' : undefined}
                                                                    onClick={(event) => handleClick(event, i)} // Pass the index
                                                                >
                                                                    <MenuIcon />
                                                                </IconButton>
                                                                <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={openMenuIndex === i}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                                PaperProps={{
                                                                    style: {
                                                                    width: '160px',  // Set the width
                                                                    height:"100px",
                                                                    display:"flex",
                                                                    flexDirection:"column",
                                                                    justifyContent:"center",
                                                                    padding:"4px",
                                                                    background:"#F1F3F4",
                                                                    border:"1px solid #EEEEEE",
                                                                    overflow:"hidden",
                                                                    },

                                                                }}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom', // Align vertically to the top of the anchorEl
                                                                    horizontal: 'right', // Align horizontally to the left of the anchorEl
                                                                  }}
                                                                  transformOrigin={{
                                                                    vertical: 'top',  // Keep the top aligned
                                                                    horizontal: 'right',  // Align the menu to the right of the anchorEl, opening to the left
                                                                  }}
                                                                >
                                                         
                                                                <MenuItem 
                                                                sx={{borderRadius:"15px",marginBottom:"5px",color:"#525A65"}}
                                                                onClick={() => {
                                                                     setSelectedPlan([plan])
                                                                        handleClose();
                                                                    }}>View</MenuItem>
                                                                    <Divider sx={{margin:"0px auto!important", width:"80%!important" }}></Divider>
                                                                    <MenuItem 
                                                                sx={{borderRadius:"15px",marginTop:"5px",color:"#525A65"}}
                                                                    
                                                                    onClick={() => {
                                                                        if (window.confirm("Are you sure you want to delete this event?")) {
                                                                            deleteCEvent(plan.calendarId, plan.eventId, plan.resource.id, plan.plan[0].id);
                                                                            onEventDelete(plan.calendarId, plan.eventId, plan.resource.id);
                                                                        }
                                                                        handleClose();

                                                                    }}>Delete</MenuItem>
                                                                </Menu>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>}
                        <Button sx={buttonStyle} onClick={() => {
                            if (!date) return alert("Select a Date")
                            setnewTraining(true)
                        }}>+ Assign a new training</Button>
                    </Box>
                </>)}
                {newTraining && (
                    <AddNewTraining 
                        back={() => setnewTraining(false)} 
                        onEventDelete={onEventDelete} 
                        athlete={currentAthlete} 
                        title={setTitle} 
                        date={date} 
                        reload={reload} 
                        onSuccess={() => {
                            setIsLoading(true);
                            // Snackbar'ı burada göstermeyi kaldırdık çünkü events değiştiğinde gösterilecek
                        }} 
                    />
                )}
                {SelectedPlan.length > 0 && <ViewExerciseAndPlan athelete={SelectedPlan} back={() => setSelectedPlan([])} title={setTitle} reload={reload} />}
            </Paper >
            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{
                        width: "100%",
                        "& .MuiAlert-message": {
                            fontSize: "14px",
                            fontWeight: 500,
                        },
                    }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    )
}










