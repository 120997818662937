import React from "react";
import moment from "moment-timezone";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

export const TimeSelectionModal = ({ open, onClose, onSubmit, type }) => {
  const [startHours, setStartHours] = React.useState("");
  const [startMinutes, setStartMinutes] = React.useState("");
  const [endHours, setEndHours] = React.useState("");
  const [endMinutes, setEndMinutes] = React.useState("");
  const [timezone, setTimezone] = React.useState("Europe/Paris");

  const timezones = moment.tz
    .names()
    .map((name) => {
      const offset = moment.tz(name).format("Z");
      const formattedName = `(UTC${offset}) ${name.replace(/_/g, " ")}`;
      return {
        value: name,
        label: formattedName,
      };
    })
    .sort((a, b) => {
      const offsetA = moment.tz(a.value).utcOffset();
      const offsetB = moment.tz(b.value).utcOffset();
      return offsetB - offsetA;
    });

  const handleSubmit = () => {
    if (!startHours || !startMinutes || !endHours || !endMinutes) {
      alert("Please enter both start and end times");
      return;
    }

    // Convert to numbers for validation
    const startHoursNum = parseInt(startHours, 10);
    const startMinutesNum = parseInt(startMinutes, 10);
    const endHoursNum = parseInt(endHours, 10);
    const endMinutesNum = parseInt(endMinutes, 10);

    // Validate hour and minute ranges
    if (startHoursNum < 0 || startHoursNum > 23 || endHoursNum < 0 || endHoursNum > 23) {
      alert("Hours must be between 0 and 23");
      return;
    }
    if (startMinutesNum < 0 || startMinutesNum > 59 || endMinutesNum < 0 || endMinutesNum > 59) {
      alert("Minutes must be between 0 and 59");
      return;
    }

    // Format with leading zeros
    const formattedStartHours = startHoursNum.toString().padStart(2, "0");
    const formattedStartMinutes = startMinutesNum.toString().padStart(2, "0");
    const formattedEndHours = endHoursNum.toString().padStart(2, "0");
    const formattedEndMinutes = endMinutesNum.toString().padStart(2, "0");

    // Create time strings with PostgreSQL time with time zone format
    const offset = moment.tz(timezone).format('Z'); // Gets timezone offset like '+01:00'
    const startTimeString = `${formattedStartHours}:${formattedStartMinutes}:00${offset}`;
    const endTimeString = `${formattedEndHours}:${formattedEndMinutes}:00${offset}`;

    // Validate start time is before end time
    if (startHoursNum > endHoursNum || 
        (startHoursNum === endHoursNum && startMinutesNum >= endMinutesNum)) {
      alert("Start time must be before end time");
      return;
    }

    // Sadece start_time ve end_time gönder
    onSubmit({
      start_time: startTimeString,
      end_time: endTimeString
    });

    // Reset form
    setStartHours("");
    setStartMinutes("");
    setEndHours("");
    setEndMinutes("");
    setTimezone("Europe/Paris");
  };

  const handleStartHoursChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 23)) {
      setStartHours(value);
    }
  };

  const handleStartMinutesChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 59)) {
      setStartMinutes(value);
    }
  };

  const handleEndHoursChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 23)) {
      setEndHours(value);
    }
  };

  const handleEndMinutesChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 0 && Number(value) <= 59)) {
      setEndMinutes(value);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "24px",
          padding: "16px",
          minWidth: "400px",
        },
      }}
    >
      <DialogTitle
        sx={{
          color: "#525A65",
          fontSize: "16px",
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        Set Time for In-Person Training Session
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Stack spacing={2}>
            {/* Start Time */}
            <Box>
              <Typography
                sx={{
                  color: "#838F99",
                  fontSize: "14px",
                  mb: 1,
                  fontWeight: 400,
                }}
              >
                Start Time
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  autoFocus
                  type="number"
                  value={startHours}
                  onChange={handleStartHoursChange}
                  placeholder="HH"
                  inputProps={{
                    min: 0,
                    max: 23,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "80px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                    },
                  }}
                />
                <Typography sx={{ color: "#525A65", fontSize: "20px" }}>
                  :
                </Typography>
                <TextField
                  type="number"
                  value={startMinutes}
                  onChange={handleStartMinutesChange}
                  placeholder="MM"
                  inputProps={{
                    min: 0,
                    max: 59,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "80px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                    },
                  }}
                />
              </Stack>
            </Box>

            {/* End Time */}
            <Box>
              <Typography
                sx={{
                  color: "#838F99",
                  fontSize: "14px",
                  mb: 1,
                  fontWeight: 400,
                }}
              >
                End Time
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <TextField
                  type="number"
                  value={endHours}
                  onChange={handleEndHoursChange}
                  placeholder="HH"
                  inputProps={{
                    min: 0,
                    max: 23,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "80px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                    },
                  }}
                />
                <Typography sx={{ color: "#525A65", fontSize: "20px" }}>
                  :
                </Typography>
                <TextField
                  type="number"
                  value={endMinutes}
                  onChange={handleEndMinutesChange}
                  placeholder="MM"
                  inputProps={{
                    min: 0,
                    max: 59,
                    style: { textAlign: "center" },
                  }}
                  sx={{
                    width: "80px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "16px",
                      backgroundColor: "#FBFBFB",
                    },
                  }}
                />
              </Stack>
            </Box>

            <FormControl fullWidth>
              <InputLabel
                id="timezone-label"
                sx={{ position: "absolute", top: "17%", color: "#838F99" }}
              >
                Timezone
              </InputLabel>
              <Select
                labelId="timezone-label"
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                sx={{
                  borderRadius: "16px",
                  backgroundColor: "#FBFBFB",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E0E0E0",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 290,
                      maxWidth: 100,
                    },
                  },
                }}
              >
                {timezones.map(({ value, label }) => (
                  <MenuItem sx={{ fontSize: "15px" }} key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: "#525A65",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#2998E9",
            borderRadius: "30px",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#2180c9",
            },
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
