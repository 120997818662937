import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, Paper, Button } from "@mui/material";

export const UsersTable = ({ 
  data, 
  columns, 
  loading, 
  activeView 
}) => {
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  const rows = data.map((i, index) => {
    if (activeView === 'referrals') {
      return {
        id: index,
        referralCoach: i.referral_coach,
        coachname: i.full_name,
        invitedCoachStatus: i.status,
        referralCode: i.referral_code,
        referredBy: i.referred_by,
        lastSubscription: i.last_subscription,
        subscriptionCount: i.subscription_count,
      };
    }

    return {
      id: index,
      userId: i.id,
      coachname: i.full_name,
      email: i.email,
      referralCode: i.referral_code,
      phonenumber: i.phone_number,
      lastDashboardAccess: i.last_dashboard_access,
      isFreeAccount: i.free_account || false
    };
  });

  return (
    <Paper sx={{ 
      height: 800, 
      width: "100%",
      '& .MuiDataGrid-root': { border: 'none' },
      '& .MuiDataGrid-cell': { borderBottom: '1px solid #f0f0f0' },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#f5f5f5',
        borderBottom: 'none',
      },
    }}>
      {data.length > 0 ? (
        <DataGrid
          rows={rows}
          columns={columns[activeView]}
          initialState={{ 
            pagination: { 
              paginationModel: { page: 0, pageSize: 20 } 
            } 
          }}
          pageSizeOptions={[20, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          sx={{ border: 0 }}
        />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography>No data available</Typography>
        </Box>
      )}
    </Paper>
  );
}; 