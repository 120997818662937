import { Alert, Box, Typography } from '@mui/material'
import React from 'react'

export const TypeError = () => {
  return (

    <Box >
    <Alert sx={{borderRadius:"20px",padding:"6px",margin:"10px 0",width:"100%",fontSize:"14px"}}  severity="warning">Select a type.</Alert>

  </Box>
)
}
