import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Autocomplete,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import {
  deleteExercisePlans,
  insertExercisePlans,
  upsertPlan,
  get_fill_exercise,
  upsertExercise,
  getPlanByIdClient,
  plan_client_upsert,
  deleteExercisePlanClient,
} from "../../services/query";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LeftArrow } from "../ClientDetailTraining/style";
import {
  formtextfieldStyle,
  formtextfiel2dStyle,
  formtextfiel3dStyle,
  formtextselectStyle,
  formDescriptionStyle,
  close,
  button2Style,
  CreateUpdateStyle,
  DeleteIcon,
  DragDropIcon,
  button1Style,
  SuperSetIcon,
} from "./style";
import PropTypes from "prop-types";
import { GroupSelect } from "./GroupSelect";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TimeSelectionModal } from "../ClientDetailTraining/TimeSelectionModal";
import { getYouTubeVideoId } from "../../utils/validate";

export const TrainingPlanModal = ({
  open,
  handleClose,
  exercises = [], // Add default value
  plan,
  isvisible,
  onSuccess,
  onCreateEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [exerciseGroupedBy, setExerciseGroupedBy] = useState([]);
  const [fillexercise, setFillexercise] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const [triggered, setTriggered] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(true);
  const [firstType, setFirstType] = useState("");
  const [time, setTime] = useState({
    start_time: null,
    end_time: null
  });
  const [type, setType] = useState("");
  const isUpdate = Boolean(plan?.id);
  const [isTrainingDialogOpen, setIsTrainingDialogOpen] = useState(false);
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [pendingSubmitData, setPendingSubmitData] = useState(null);
  const [pendingInsertPlan, setPendingInsertPlan] = useState(null);

  const onDragStart = (result) => {
    setDragging(true);
    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);
    setDraggingItem(movedExercise.boxid);
  };
  const handleDelete = useCallback(
    (index) => {
      setSelectedExercises((prev) => {
        const updatedExercises = prev.filter((_, i) => i !== index);
        const supersetGroups = new Map();
        updatedExercises.forEach((exercise) => {
          if (exercise.supersetGroup) {
            if (!supersetGroups.has(exercise.supersetGroup)) {
              supersetGroups.set(exercise.supersetGroup, []);
            }
            supersetGroups.get(exercise.supersetGroup).push(exercise);
          }
        });

        supersetGroups.forEach((exercises, groupId) => {
          if (exercises.length === 1) {
            exercises[0].isSuperset = false;
            exercises[0].supersetGroup = null;
          }
        });

        return updatedExercises;
      });
    },
    [selectedExercises]
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      setDragging(false);
      setDraggingItem(null);
      return;
    }

    const reorderedExercises = Array.from(selectedExercises);
    const [movedExercise] = reorderedExercises.splice(result.source.index, 1);

    const destinationIndex = result.destination.index;
    const destinationExercise = reorderedExercises[destinationIndex];

    if (destinationExercise?.isSuperset) {
      movedExercise.isSuperset = true;
      movedExercise.supersetGroup = destinationExercise.supersetGroup;
    } else {
      movedExercise.isSuperset = false;
      movedExercise.supersetGroup = null;
    }

    reorderedExercises.splice(destinationIndex, 0, movedExercise);

    const updatedExercises = reorderedExercises.map((exercise, index) => ({
      ...exercise,
      boxid: index + 1,
      exercise: {
        ...exercise.exercise,
        order: index + 1,
      },
    }));

    setSelectedExercises(updatedExercises);
    setDragging(false);
    setDraggingItem(null);
    handleDelete();
  };

  const handleAddExercise = useCallback(() => {
    setTriggered(true);
    if (fetchLoading) return;
    if (!["Solo", "Group"].includes(type)) {
      return;
    }
    const newId = selectedExercises.length + 1;
    setSelectedExercises((prev) => [
      ...prev,
      {
        boxid: newId,
        exercise: [],
        notes: "",
        sets: 1,
        supersetGroup: null,
        isSuperset: false,
      },
    ]);

  }, [selectedExercises.length, type]);

  function generateUUID() {
    const timestamp = Date.now();
    const randomValues =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return `superset-${timestamp}-${randomValues}`;
  }

  const addSuperSet = (index) => {
    if (index < 0 || index >= selectedExercises.length - 1) return;

    setSelectedExercises((prevExercises) => {
      const newExercises = [...prevExercises];
      const current = newExercises[index];
      const next = newExercises[index + 1];

      if (!current.supersetGroup && next.supersetGroup) {
        return prevExercises;
      }

      const createNewSuperset = () => {
        const supersetId = generateUUID();
        [current, next].forEach((exercise) => {
          exercise.supersetGroup = supersetId;
          exercise.isSuperset = true;
        });
      };

      const extendSuperset = () => {
        next.supersetGroup = current.supersetGroup;
        next.isSuperset = true;
      };

      const mergeSupersets = () => {
        const targetId = current.supersetGroup;
        newExercises.forEach((exercise) => {
          if (exercise.supersetGroup === next.supersetGroup) {
            exercise.supersetGroup = targetId;
            exercise.isSuperset = true;
          }
        });
      };

      if (!current.supersetGroup && !next.supersetGroup) createNewSuperset();
      else if (current.supersetGroup && !next.supersetGroup) extendSuperset();
      else if (current.supersetGroup !== next.supersetGroup) mergeSupersets();

      return newExercises;
    });
  };

  const shouldShowSupersetIcon = useCallback(
    (index) => {
      if (index + 1 >= selectedExercises.length) return false;

      const currentExercise = selectedExercises[index];
      const nextExercise = selectedExercises[index + 1];

      if (dragging && draggingItem === currentExercise.boxid) return false;

      return (
        !currentExercise.supersetGroup ||
        (currentExercise.supersetGroup && !nextExercise.supersetGroup) ||
        (currentExercise.supersetGroup &&
          nextExercise.supersetGroup &&
          currentExercise.supersetGroup !== nextExercise.supersetGroup)
      );
    },
    [selectedExercises, dragging, draggingItem]
  );

  const groupedExercises = useMemo(() => {
    const groupExercisesBySuperset = (exercises) => {
      const groups = [];
      let currentGroup = [];
      let currentSupersetId = null;

      const pushGroup = (type) => {
        if (currentGroup.length > 0) {
          const groupSets =
            type === "superset" ? currentGroup[0].exercise.sets : undefined;
          groups.push({ type, exercises: currentGroup, sets: groupSets });
          currentGroup = [];
        }
      };

      exercises.forEach((exercise, index) => {
        const isPartOfSuperset = !!exercise.supersetGroup;

        if (isPartOfSuperset) {
          if (exercise.supersetGroup !== currentSupersetId) {
            pushGroup(currentSupersetId ? "superset" : "normal");
            currentSupersetId = exercise.supersetGroup;
          }
          currentGroup.push({ exercise, index });
        } else {
          pushGroup(currentSupersetId ? "superset" : "normal");
          currentSupersetId = null;
          currentGroup.push({ exercise, index });
        }
      });

      pushGroup(currentSupersetId ? "superset" : "normal");

      return groups;
    };

    return groupExercisesBySuperset(selectedExercises);
  }, [selectedExercises]);

  const { register, handleSubmit } = useForm({
    defaultValues: plan
      ? { name: plan.name, overview: plan.overview, type: plan.type }
      : { name: "", overview: "", type: "" },
  });

  const { user } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    if (!plan) {
      setFetchLoading(false);
      return;
    }

    setType(plan.type);
    setFirstType(plan.type);
    
    // Plan'dan gelen time bilgilerini ayarla
    if (plan.start_time && plan.end_time) {
      setPendingSubmitData({
        ...plan,
        start_time: plan.start_time,
        end_time: plan.end_time
      });
    }

    const selected = plan.exercise_plans.map((e, i) => ({
      boxid: i + 1,
      exercise: { ...e.exercises, order: e.order, type: e.type },
      notes: e.notes,
      sets: e.sets,
      isSuperset: e.isSuperset,
      supersetGroup: e.supersetGroup,
    }));

    setFetchLoading(false);
    setSelectedExercises(
      selected.sort((a, b) => a.exercise.order - b.exercise.order)
    );
  }, [plan]);

  useEffect(() => {
  
    // Log all group exercises
    const groupExercises = fillexercise.filter(ex => ex.type === "Group");
    
    if (type == null || type == undefined) {
      return;
    }
    if (!plan || firstType !== type) {
      setSelectedExercises([]);
    }
    
    const filtered = fillexercise.filter((exercise) => exercise.type === type);
    
    setExerciseGroupedBy(filtered);
  }, [type, plan, fillexercise]);

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const { data: _fillexercise } = await get_fill_exercise(supabase);
    
        const newItems = _fillexercise.filter(
          (item) => item.title !== "Custom"
        );
       
        
        const filteredExercises = newItems
       
        const finalSet = [...exercises, ...filteredExercises];
        
        setFillexercise(finalSet);
      } catch (error) {
        console.error("Error fetching exercises:", error);
      }
    };

    fetchExercises();
  }, []);

  useEffect(() => {
    if (pendingInsertPlan && time.start_time && time.end_time) {
      if (onCreateEvent) {
        onCreateEvent(pendingInsertPlan, time);
        setPendingInsertPlan(null);
      }
    }
  }, [time, pendingInsertPlan]);

  const handleError = (error) => {
    console.error("Error saving training plan:", error);
    const errorMessage =
      error.message ||
      "An unexpected error occurred while saving the training plan";
    alert(errorMessage);
  };

  const validateExercises = (exercises) => {
    const errors = [];
    exercises.forEach((exercise, index) => {
      if (!exercise.exercise.title) {
        errors.push(`Exercise at position ${index + 1} is empty`);
      }
    });
    return errors;
  };

  const handleTrainingResponse = async (isTraining) => {
    setIsTrainingDialogOpen(false);

    if (isTraining) {
      setTimeModalOpen(true);
    } else {
      // Execute the regular submit without time
      if (isvisible && !onCreateEvent && isUpdate) {
        await onSubmitClient(pendingSubmitData);
      } else {
        await onSubmit(pendingSubmitData);
      }
    }
  };

  const handleTimeSubmit = async ({ start_time, end_time }) => {
    const submitData = {
      ...pendingSubmitData,
      start_time,
      end_time
    };

    if (isvisible && !onCreateEvent && isUpdate) {
      await onSubmitClient(submitData);
    } else {
      await onSubmit(submitData, { start_time, end_time });
    }
    setTimeModalOpen(false);
  };

  const handleFormSubmit = (data) => {
    if (type === "Group" || type === "Solo") {
      data.type = type;
    }

    // Create butonundan geliyorsa direkt submit et
    if (!isvisible) {
      return onSubmit(data, { start_time: null, end_time: null });
    }

    // Edit modunda direkt update et
    if (isUpdate) {
      return onSubmitClient(data);
    }
    
    // Group tipi için direkt submit et
    if (type === "Group") {
      if (isvisible && !onCreateEvent) {
        return onSubmitClient(data);
      } else {
        return onSubmit(data, { start_time: null, end_time: null });
      }
    }
    
    // Solo tipi için ve plan seçiminden geliyorsa training sorusu sor
    if (type === "Solo" && isvisible) {
      setPendingSubmitData(data);
      setIsTrainingDialogOpen(true);
    } else {
      // Create butonundan geliyorsa direkt submit et
      return onSubmit(data, { start_time: null, end_time: null });
    }
  };

  const onSubmit = handleSubmit(async (data, timeData = null) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      setLoading(true);
      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        // Group tipi için ilk egzersizin adını plan adı olarak kullan
        name: type === "Group" && selectedExercises[0]?.exercise?.title 
          ? selectedExercises[0].exercise.title 
          : data.name,
        type,
        coach_id: user.id,
      };

      const { data: newPlan, error: newPlanError } = await upsertPlan(
        supabase,
        planPayload
      );

      if (newPlanError) throw newPlanError;

      if (plan?.id) {
        await deleteExercisePlans(supabase, plan.id);
      }

      const supersetSets = new Map();
      selectedExercises.forEach((exercise) => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            description: e.exercise.description,
            title: e.exercise.title,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos: e.exercise.video_link ? [
              {
                id: getYouTubeVideoId(e.exercise.video_link),
                url: e.exercise.video_link,
              },
            ] : [],
            images: e.exercise.images || [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id
            ? {
                ...e,
                exercise: { ...e.exercise, id: data[0].id },
              }
            : e;
        })
      );

      const exercisePlans = processedExercises.map((e) => ({
        exercise_id: e.exercise.id,
        plan_id: newPlan[0].id,
        plan_client_id: null,
        order: e.exercise.order,
        notes: e.notes,
        // Eğer superset ise grubun set değerini kullan, değilse kendi set değerini
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup,
      }));

      const { data: insertPlan, error: insertError } = await insertExercisePlans(
        supabase,
        exercisePlans
      );

      if (insertError || !insertPlan || !insertPlan[0]) {
        throw new Error('Failed to insert exercise plans');
      }

      setPendingInsertPlan(insertPlan[0]);
      onSuccess();
      if (onCreateEvent && !isUpdate && insertPlan[0]) {
        onCreateEvent(insertPlan[0], timeData);
      }
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });

  const onSubmitClient = handleSubmit(async (data) => {
    const errors = validateExercises(selectedExercises);
    if (errors.length > 0) {
      alert(errors.join("\n"));
      return;
    }

    try {
      setLoading(true);
      const planPayload = {
        ...(plan?.id ? { id: plan.id } : {}),
        ...data,
        name: type === "Group" && selectedExercises[0]?.exercise?.title 
          ? selectedExercises[0].exercise.title 
          : data.name,
        type,
        coach_id: user.id,
        created_at: plan?.created_at || new Date(),
        start_time: data.start_time,
        end_time: data.end_time,
      };

      const { data: newPlan, error: newPlanError } = await plan_client_upsert(
        supabase,
        planPayload
      );

      if (newPlanError) throw newPlanError;
      if (!newPlan || !newPlan[0]) throw new Error('Failed to create/update plan');

      const { data: planId, error: planIdError } = await getPlanByIdClient(supabase, newPlan[0].id);
      
      if (planIdError) throw planIdError;
      if (!planId || !planId[0] || !planId[0].exercise_plans || !planId[0].exercise_plans[0]) {
        throw new Error('Invalid plan data structure received');
      }

      const planClientId = newPlan[0].id;
      const originalPlanId = planId[0].exercise_plans[0].plan_id;

      if (plan?.id) {
        await deleteExercisePlanClient(supabase, plan.id);
      }

      const supersetSets = new Map();
      selectedExercises.forEach((exercise) => {
        if (exercise.supersetGroup) {
          supersetSets.set(exercise.supersetGroup, exercise.sets);
        }
      });

      const processedExercises = await Promise.all(
        selectedExercises.map(async (e) => {
          if (!e.exercise.type) return e;

          const payload = {
            description: e.exercise.description,
            title: `${e.exercise.title}`,
            tags: e.exercise.tags,
            workout: e.exercise.title,
            requires_equipment: e.exercise.requires_equipment,
            videos: e.exercise.video_link ? [
              {
                id: getYouTubeVideoId(e.exercise.video_link),
                url: e.exercise.video_link,
              },
            ] : [],
            images: e.exercise.images || [],
            coach_id: user.id,
          };

          const { data } = await upsertExercise(supabase, payload);
          return data[0]?.id
            ? {
                ...e,
                exercise: { ...e.exercise, id: data[0].id },
              }
            : e;
        })
      );

      const exercisePlans = processedExercises.map((e) => ({
        exercise_id: e.exercise.id,
        plan_id: originalPlanId,
        plan_client_id: planClientId,
        order: e.exercise.order,
        notes: e.notes,
        sets: e.supersetGroup ? supersetSets.get(e.supersetGroup) : e.sets,
        isSuperset: e.isSuperset,
        supersetGroup: e.supersetGroup,
      }));

      const { data: insertPlan, error: insertError } = await insertExercisePlans(
        supabase,
        exercisePlans
      );

      if (insertError || !insertPlan || !insertPlan[0]) {
        throw new Error('Failed to insert exercise plans');
      }

      setPendingInsertPlan(insertPlan[0]);
      onSuccess();
      if (onCreateEvent && insertPlan[0]) {
        const timeData = {
          start_time: data.start_time,
          end_time: data.end_time
        };
        onCreateEvent(insertPlan[0], timeData);
      }
      handleClose();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#F1F3F4",
          borderRadius: isvisible ? "0px 0px 24px 24px" : "24px",
          border: "none",
          boxShadow: "unset",
          minHeight: isvisible ? "555px" : "684px",
        }}
      >
        <Box p={4} sx={{ paddingBottom: "0px" }}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box pb={3} display={"flex"} alignItems={"baseline"} position={"relative"}>
              {isvisible ? (
                <Button
                  startIcon={<LeftArrow />}
                  onClick={handleClose}
                  sx={{
                    fontSize: "12px",
                    textTransform: "none",
                    ".MuiButton-icon": {
                      marginRight: "3px",
                    },
                  }}
                >
                  <span style={{ marginTop: "1px" }}>Back</span>
                </Button>
              ) : (
                <Typography
                  sx={{
                    fontSize: {
                      xl: "16px",
                      lg: "16px",
                      md: "13px",
                      sm: "13px",
                      xs: "13px",
                    },
                    fontWeight: "600 !important",
                    color: "#2998E9",
                  }}
                >
                  {isUpdate
                    ? "Update Training Plan"
                    : "Create New Training Plan"}
                </Typography>
              )}

              {isvisible && type === "Group" && <></>}
              {isvisible ? (
                <div style={{ display: 'flex', position:"absolute", right: "0", gap: '10px' }}>
                  <Button
                    sx={{
                      ...button2Style,
                      
                    }}
                    disabled={loading || selectedExercises.length === 0}
                    onClick={() => {
                      handleSubmit(async (data) => {
                        const originalIsVisible = isvisible;
                        const originalOnCreateEvent = onCreateEvent;
                        isvisible = false;
                        onCreateEvent = null;
                        
                        const timeData = { start_time: null, end_time: null };
                        await onSubmit(data, timeData);
                        
                        isvisible = originalIsVisible;
                        onCreateEvent = originalOnCreateEvent;
                      })();
                    }}
                  >
                    Save Plan
                  </Button>
                  <Button
                    sx={button2Style}
                    disabled={loading || selectedExercises.length === 0}
                    type="submit"
                  >
                    Save & Assign Plan
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={handleClose}
                  startIcon={<CloseIcon />}
                  size="small"
                  sx={close}
                ></Button>
              )}
            </Box>
            {(type==="Solo")&& (
            <Box sx={{ mt: 3 }}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important",
                }}
              >
                Plan name
              </InputLabel>

              <TextField
                {...register("name")}
                placeholder="Plan name"
                required
                sx={formtextfieldStyle}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Box>
            )}
            <GroupSelect type={type} setType={setType} triggered={triggered} />

            {(type === "Solo" &&

              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Overview
                </InputLabel>
                <TextField
                  {...register("overview")}
                  variant="outlined"
                  type="textarea"
                  placeholder="Overview"
                  minRows={4}
                  multiline
                  sx={formDescriptionStyle}
                  fullWidth
                  inputProps={{ maxLength: 1000 }}
                  value={
                    type === "Group"
                      ? selectedExercises
                          .filter((ex) => ex.exercise?.description)
                          .map((ex) => ex.exercise.description)
                          .join("\n\n")
                      : undefined
                  }
                  disabled={type === "Group"}
                />
              </Box>
            )}
               
            <Box mt={1}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                sx={{
                  paddingLeft: "24px",
                  color: "#838F99",
                  fontWeight: "400 !important",
                  paddingLeft: type == "Group" && "8px",
                }}
              >
                {type==="Solo" && <>Exercises</>}
                {(
                  (type === "Group" && selectedExercises.length == 1)) && (
                  <>Class Name</>
                )}
              </InputLabel>
              <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      {groupedExercises.map((group, groupIndex) => (
                        <Box
                          key={groupIndex}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ...(group.type === "superset" && {
                              borderRadius: "8px",
                              position: "relative",
                            }),
                          }}
                        >
                          {(group.type === "superset" ||
                            group.exercises.length > 1) && (
                            <>
                              <Typography
                                sx={{
                                  position: "absolute",
                                  top: "45%",
                                  left: "10px",
                                  transform: "translateY(-50%)",
                                  fontSize: "12px",
                                  color: "#666",
                                  fontWeight: 600,
                                  zIndex: 1000,
                                  cursor: "pointer",
                                }}
                              >
                                {type === "Solo" && <DragDropIcon />}
                              </Typography>
                              {group.type === "superset" && (
                                <TextField
                                  value={`x${group.sets || ""}`}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /^x/,
                                      ""
                                    );
                                    setSelectedExercises((prevExercises) => {
                                      return prevExercises.map((ex) => {
                                        if (
                                          ex.supersetGroup ===
                                          group.exercises[0].exercise
                                            .supersetGroup
                                        ) {
                                          return { ...ex, sets: newValue };
                                        }
                                        return ex;
                                      });
                                    });
                                  }}
                                  type="text"
                                  sx={{
                                    ...formtextfiel2dStyle,
                                    position: "absolute",
                                    left: "20px",
                                    top: "0",
                                    height: "90%",
                                    borderRadius: "40px",
                                    width: "50px",
                                    zIndex: 1000,
                                    "& .MuiInputBase-root": {
                                      height: "100%",
                                      minWidth: "50px",
                                      minHeight: "40px",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      padding: "0 8px",
                                      textAlign: "center",
                                      minWidth: "34px",
                                      minHeight: "40px",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {},
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </>
                          )}

                          {group.exercises.map(({ exercise, index }) => (
                            <Draggable
                              key={`${groupIndex}-${index}`}
                              draggableId={`${groupIndex}-${index}`}
                              index={index}
                              type={
                                group.type === "superset" ? "group" : "normal"
                              }
                            >
                              {(provided) => (
                                <>
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      marginBottom: "8px",
                                      marginLeft:
                                        group.type === "superset"
                                          ? "90px"
                                          : "0",
                                      "& .MuiAutocomplete-root": {
                                        width: "calc(100% - 120px)",
                                      },
                                    }}
                                  >
                                    <>
                                      <Typography
                                        sx={{
                                          padding: type !== "Group" && "5px",
                                        }}
                                      >
                                        {type === "Solo" && <DragDropIcon />}
                                      </Typography>

                                      {type === "Solo" &&
                                        !exercise.isSuperset && (
                                          <TextField
                                            value={`x${exercise.sets || ""}`}
                                            onChange={(e) => {
                                              const newValue =
                                                e.target.value.replace(
                                                  /^x/,
                                                  ""
                                                );
                                              setSelectedExercises(
                                                (prevSets) => {
                                                  const updatedSets = [
                                                    ...prevSets,
                                                  ];
                                                  updatedSets[index].sets =
                                                    newValue;
                                                  return updatedSets;
                                                }
                                              );
                                            }}
                                            type="text"
                                            sx={{
                                              ...formtextfiel2dStyle,
                                              "& .MuiInputBase-root": {
                                                minWidth: "50px",
                                                minHeight: "40px",
                                              },
                                              "& .MuiOutlinedInput-input": {
                                                padding: "0 8px",
                                                textAlign: "center",
                                                minWidth: "34px",
                                                minHeight: "40px",
                                              },
                                            }}
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                          />
                                        )}
                                    </>

                                    <Autocomplete
                                      options={exerciseGroupedBy}
                                      sx={{
                                        ...formtextselectStyle,
                                        marginLeft: !exercise.isSuperset
                                          ? "40px"
                                          : "0",
                                        ...(type === "Group" && {
                                          width: "90%!important",
                                          padding: "0",
                                          margin: "0",
                                        }),
                                      }}
                                      value={exercise.exercise || null}
                                      onChange={(e, selectedOption) => {
                                        if (selectedOption) {
                                          setSelectedExercises(
                                            (prevExercises) => {
                                              const updatedExercises = [
                                                ...prevExercises,
                                              ];
                                              updatedExercises[index].exercise =
                                                {
                                                  ...selectedOption,
                                                  order: index + 1,
                                                };
                                              return updatedExercises;
                                            }
                                          );
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        placeholder={type === "Solo" ? "Search Exercise" : "Search Class"}
                                      />
                                      )}
                                      getOptionLabel={(option) =>
                                        option.title || ""
                                      }
                                      getOptionKey={(option) => option.id}
                                      PaperComponent={(props) => (
                                        <Paper
                                          {...props}
                                          sx={{
                                            borderRadius: "16px",
                                            backgroundColor: "#FBFBFB",
                                          }}
                                        />
                                      )}
                                      ListboxProps={{
                                        sx: {
                                          "& .MuiAutocomplete-option": {
                                            color: "#525A65",
                                            fontSize: "14px",
                                            borderBottom: "1px solid #CECECE", // Add a line between options
                                            padding: "10px 10px", // Add space on the right and left
                                            margin: "0px 10px",
                                            "&:last-child": {
                                              borderBottom: "none", // Remove the line after the last option
                                            },
                                          },
                                        },
                                      }}
                                      popupIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "15px",
                                            height: "15px",
                                            alignItems: "center",
                                            paddingRight: "10px !important",
                                            pointerEvents: "none",
                                          }}
                                        >
                                          <svg
                                            width="13"
                                            height="8"
                                            viewBox="0 0 13 8"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z"
                                              fill="#DAD9D8"
                                            />
                                          </svg>
                                        </div>
                                      }
                                    />
                                    {type === "Solo" && (
                                      <TextField
                                        sx={formtextfiel3dStyle}
                                        value={
                                          exercise.notes?.substring(0, 40) || ""
                                        }
                                        multiline
                                        minRows={1}
                                        onChange={(e) => {
                                          const newValue = e.target.value;
                                          setSelectedExercises((prevNotes) => {
                                            const updatedNotes = [...prevNotes];
                                            updatedNotes[index].notes =
                                              newValue;
                                            return updatedNotes;
                                          });
                                        }}
                                        placeholder="Add notes"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => handleDelete(index)}
                                      sx={{ marginLeft: "auto" }}
                                    >
                                      <DeleteIcon
                                        width={"20px"}
                                        height={"20px"}
                                      />
                                    </IconButton>
                                  </Box>
                                  {shouldShowSupersetIcon(index) && (
                                    <Button
                                      onClick={() => addSuperSet(index)}
                                      sx={{ mb: 1 }}
                                    >
                                      <SuperSetIcon />
                                    </Button>
                                  )}
                                </>
                              )}
                            </Draggable>
                          ))}
                        </Box>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
            { ((type === "Group" &&
                selectedExercises.length > 0 &&
                selectedExercises[0].exercise?.title)) && (
                  <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Overview
                </InputLabel>
                <TextField
                  {...register("overview")}
                  variant="outlined"
                  type="textarea"
                  placeholder="Overview"
                  minRows={4}
                  multiline
                  sx={formDescriptionStyle}
                  fullWidth
                  inputProps={{ maxLength: 1000 }}
                  value={
                    type === "Group"
                      ? selectedExercises
                          .filter((ex) => ex.exercise?.description)
                          .map((ex) => ex.exercise.description)
                          .join("\n\n")
                      : undefined
                  }
                  disabled={type === "Group"}
                />
              </Box>
            )}
            {((type === "Group" && selectedExercises.length < 1) ||
              type === "Solo") && (
              <Box mt={1} width={"100%"} height={"58px"}>
                <Button
                  startIcon={<AddIcon />}
                  sx={{
                    ...button1Style,
                    "& .MuiButton-label": {
                      color: type === "Group" ? "#2e7d32" : "inherit",
                    },
                  }}
                  onClick={handleAddExercise}
                >
                  {type === "Group" ? (
                    <span style={{ color: "#2e7d32" }}>Add Class</span>
                  ) : (
                    <>Add exercise</>
                  )}
                </Button>
              </Box>
            )}
            {!isvisible && (
              <Box width={"100%"} height={"80px"} sx={{ marginTop: "23%" }}>
                <Button
                  type="submit"
                  disabled={loading || selectedExercises.length === 0}
                  sx={CreateUpdateStyle}
                >
                  {isUpdate ? "Update" : "Create"}
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Paper>

      {/* Add the training session dialog */}
      <Dialog
        open={isTrainingDialogOpen}
        onClose={() => handleTrainingResponse(false)}
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#525A65",
            fontSize: "18px",
            fontWeight: 600,
          }}
        >
          Is this a training session?
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "24px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={() => handleTrainingResponse(true)}
              variant="contained"
              sx={{
                backgroundColor: "#2196F3",
                color: "white",
                "&:hover": {
                  backgroundColor: "#1976D2",
                },
                borderRadius: "8px",
                textTransform: "none",
                padding: "8px 24px",
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => handleTrainingResponse(false)}
              variant="outlined"
              sx={{
                color: "#525A65",
                borderColor: "#525A65",
                "&:hover": {
                  borderColor: "#2196F3",
                  color: "#2196F3",
                },
                borderRadius: "8px",
                textTransform: "none",
                padding: "8px 24px",
              }}
            >
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Add the time selection modal */}
      <TimeSelectionModal
        open={timeModalOpen}
        onClose={() => setTimeModalOpen(false)}
        onSubmit={handleTimeSubmit}
        type={type}
      />
    </>
  );
};

TrainingPlanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  exercises: PropTypes.array,
  plan: PropTypes.object,
  isvisible: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
};
