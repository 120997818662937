export const ProtectedAdmin = (user) => {
  if (user === undefined || user === null) {
    return null;
  }
  else if (user?.type !== "admin") {
    return false;
  } else {
    return true;
  }
};
