import { MobileDatePicker } from "@mui/x-date-pickers";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import {
  Box,
  Button,
  CardActions,
  CardContent,
  FormHelperText,
  Paper,
  TextField,
  Typography,
  Modal,
  Link,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../providers/AuthContextProvider";
import {
  getOnboardingFormResponseById,
  upsertAthlete,
  upsertOnboardingFormResponse,
  loops_send_form,
  get_user_onboarding_form_response,
} from "../../services/query";
import { useLocation } from "react-router-dom";

export const OnboardingFormDisplayRoute = () => {
  const [obf, setObf] = useState(null);
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const [phone, setPhone] = useState("");
  const [mail, setmail] = useState("");
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split("/");
  const uuid = parts[parts.length - 1];
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = handleSubmit(async ({ first_name, last_name, custom }) => {
    console.log("sa");

    try {
      const athleteData = {
        id: obf.athlete_id,
        first_name,
        last_name,
        full_name: `${first_name} ${last_name}`,
        status: "VERIFIED",
      };
      const formResponseData = {
        id: obf.id,
        custom_responses: custom,
        status: "completed",
      };

      const { data: get_user_name } = await get_user_onboarding_form_response(
        supabase,
        uuid
      );
      console.log("sa");

      await loops_send_form(
        supabase,
        mail,
        `${first_name} ${last_name}`,
        get_user_name[0].full_name
      );
      await upsertAthlete(supabase, athleteData);
      await upsertOnboardingFormResponse(supabase, formResponseData);
      console.log("sa");
      // Form başarılı olduğunda popup'ı göster
      setShowSuccess(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    }
  });

  const handleSuccessClose = () => {
    setShowSuccess(false);
    window.location.reload();
  };

  useEffect(() => {
    getOnboardingFormResponseById(supabase, params.id).then(
      async ({ data, error }) => {
        if (error) {
          console.error(error);
          return;
        }
        setObf(data[0]);
        const athletes = await supabase
          .from("athletes")
          .select("*")
          .eq("id", data[0].athlete_id);
        setPhone(athletes.data[0].phone_number);
        setmail(athletes.data[0].email);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!obf) {
    return <PageContainer>Loading...</PageContainer>;
  }

  if (obf?.status === "completed") {
    return <PageContainer>Form already completed</PageContainer>;
  }

  return (
    <PageContainer>
      <Paper
        variant="outlined"
        sx={{
          borderRadius: "30px",
          padding: "15px 10px",
          maxWidth: 600,
          margin: "auto",
          background: "#FBFBFB",
        }}
      >
        <form onSubmit={onSubmit}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mb: 4 }}
              color="#525A65"
            >
              {obf?.onboarding_forms?.title}
            </Typography>
            <Typography gutterBottom sx={{ mb: 4 }}>
              {obf?.onboarding_forms?.welcome_message}
            </Typography>
            <Box sx={{ mb: 4 }}>
              <TextField
                {...register("first_name", { required: true })}
                label="First Name"
                error={errors.first_name}
                fullWidth
                helperText={errors.first_name ? "This field is required" : ""}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8", // Static border color
                    },
                    "&:hover fieldset": {
                      border: "1px solid #DAD9D8", // Hover border
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #DAD9D8", // Focused border
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65", // Label color
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#525A65", // Helper text color
                  },
                }}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <TextField
                {...register("last_name", { required: true })}
                label="Last Name"
                fullWidth
                error={errors.last_name}
                helperText={errors.last_name ? "This field is required" : ""}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                    "&:hover fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#525A65",
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <TextField
                label="E-mail"
                fullWidth
                value={mail}
                disabled
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65",
                  },
                }}
              />
            </Box>

            <Box sx={{ mb: 4 }}>
              <TextField
                label="Phone Number"
                fullWidth
                value={phone}
                disabled
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "20px",
                    "& fieldset": {
                      border: "1px solid #DAD9D8",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    color: "#525A65",
                  },
                }}
              />
            </Box>

            {obf?.onboarding_forms?.custom_questions?.map((field, idx) => (
              <Box sx={{ mb: 3 }} key={field.name}>
                <Typography>{field.name}</Typography>
                <TextField
                  {...register(`custom[${field.name}]`, { required: true })}
                  fullWidth
                  error={errors[`custom[${field.name}]`]}
                  helperText={
                    errors[`custom[${field.name}]`]
                      ? "This field is required"
                      : ""
                  }
                />
              </Box>
            ))}
          </CardContent>
          <CardActions>
            <Button
              type="Submit"
              sx={{
                ml: "auto",
                fontSize: "14px",
                padding: "13px 19px",
                fontWeight: "500!important",
                fontFamily: "Bricolage Grotesque",
                lineHeight: "19px",
                type: "submit",
                border: "1px solid #02BD36", // Border color
                color: "#02BD36", // Text color
                borderRadius: "23px", // Border radius
              }}
            >
              Submit
            </Button>
          </CardActions>
        </form>
      </Paper>

      <Modal open={showSuccess} onClose={handleSuccessClose}>
        <Box
          sx={{
            background: "#013B12",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "540px",
            height: "540px",
            boxShadow: 24,
            p: 4,
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            outline: "none",
          }}
        >
          <Box>
            <SuccessIcon />
          </Box>

          {/* Main Content */}
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              my: 4,
            }}
          >
            <Typography
              sx={{
                fontSize: "36px",
                color: "#02BD36",
                mb: 2,
                fontWeight: "bold",
              }}
            >
              Thank You!
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#E3E2E0",
                maxWidth: "400px",
                mb: 3,
                textAlign: "center",
                width: "90%",
              }}
            >
              Your form has been successfully submitted. Your coach will review
              it and get back to you shortly with your personalized plan.
            </Typography>
            <Button
              onClick={handleSuccessClose}
              sx={{
                backgroundColor: "#EEEEEE",
                border: "1px solid #02BD36",
                borderRadius: "20px",
                px: 2,
                py: 1,
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#EEEEEE", // Same as default to prevent hover effect
                  border: "1px solid #02BD36", // Keep border same on hover
                },
              }}
            >
              Okay!
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#F5F5F5",
                textDecoration: "underline",
              }}
            >
              contact@coachplus.club
            </Typography>

            <Box sx={{ display: "flex", gap: 2 }}>
              <Link
                href="https://www.linkedin.com/company/coachplusclub"
                sx={{
                  fontSize: "14px",
                  color: "#F5F5F5",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#02BD36",
                  },
                }}
                target="_blank"
              >
                LinkedIn
              </Link>
              <Link
                href="https://www.instagram.com/coachplusclub/"
                sx={{
                  fontSize: "14px",
                  color: "#F5F5F5",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "#02BD36",
                  },
                }}
                target="_blank"
              >
                Instagram
              </Link>
            </Box>
          </Box>
        </Box>
      </Modal>
    </PageContainer>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      width="491"
      height="68"
      viewBox="0 0 491 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.4426 7.88828H61.6706C61.3251 7.88828 61.0423 7.60555 61.0423 7.25999V1.86919C61.0423 1.49535 60.7376 1.19063 60.3638 1.19063H50.2482C49.9529 1.19063 49.6921 1.38226 49.601 1.66185L48.9319 7.11548C48.8785 7.55529 48.3978 7.80661 48.0114 7.59298C47.5779 7.35423 47.1287 7.01181 46.8805 6.78562C46.1956 6.16675 42.806 3.21689 40.1483 1.97286C37.1513 0.56861 33.5134 0 29.351 0C25.1885 0 21.6323 0.703694 18.1013 2.11422C14.5702 3.52475 11.4445 5.6107 8.72078 8.37522C5.99711 11.1397 3.86403 14.5325 2.31528 18.5568C0.772807 22.5779 0 27.2053 0 32.4328C0 39.7116 1.4765 45.9946 4.43264 51.2754C7.38879 56.5563 11.5827 60.6214 17.0143 63.4675C22.446 66.3137 28.8703 67.7368 36.2874 67.7368C40.3368 67.7368 44.5181 67.1965 48.8282 66.1158C53.0127 65.0666 56.6599 63.433 59.7732 61.2088C59.9522 61.08 60.0559 60.8727 60.0559 60.6559V44.1222C60.0559 43.8552 59.8988 43.6102 59.6538 43.5034L59.3302 43.3589C59.0946 43.2552 58.8213 43.2929 58.6234 43.4594C56.0316 45.5956 53.2389 47.1883 50.2419 48.2376C47.1224 49.334 43.9338 49.8806 40.676 49.8806C35.147 49.8806 30.8589 48.3287 27.8053 45.2218C24.755 42.1148 23.2282 37.6351 23.2282 31.7762C23.2282 27.8085 23.8094 24.5508 24.9717 22.0061C26.1341 19.4615 27.6671 17.5735 29.5709 16.342C31.4715 15.1106 33.5197 14.4948 35.7093 14.4948C37.2769 14.4948 38.6843 14.7744 39.9378 15.3242V22.0972C39.9378 22.5088 40.2991 22.8261 40.7075 22.7695L46.1108 22.0344C46.5224 21.9779 46.8742 22.3297 46.8176 22.7413L46.1517 27.6514C46.1359 28.1226 46.4878 28.3739 46.8334 28.3739H60.3606C60.7345 28.3708 61.0392 28.0661 61.0392 27.6922V20.5548C61.0392 20.2406 61.2717 19.9736 61.5827 19.9328L66.5305 19.2605C66.8667 19.2134 67.118 18.9275 67.118 18.5882V8.56999C67.118 8.19615 66.8132 7.89143 66.4394 7.89143L66.4426 7.88828Z"
        fill="#E3E2E0"
      />
      <path
        d="M88.8061 67.2818C83.6603 67.2818 79.2214 66.3644 75.483 64.5298C71.7478 62.6952 68.8733 60.0815 66.8628 56.6887C64.8522 53.2958 63.8469 49.259 63.8469 44.5782C63.8469 39.8974 64.8522 35.8197 66.8628 32.4269C68.8733 29.0341 71.7478 26.4141 75.483 24.5638C79.2182 22.7166 83.6603 21.793 88.8061 21.793C93.9518 21.793 98.3908 22.7166 102.126 24.5638C105.861 26.411 108.736 29.031 110.746 32.4269C112.757 35.8197 113.765 39.8723 113.765 44.5782C113.765 49.2842 112.76 53.2958 110.746 56.6887C108.736 60.0815 105.861 62.6952 102.126 64.5298C98.3908 66.3644 93.9487 67.2818 88.8061 67.2818ZM88.8061 55.3347C92.5005 55.3347 94.3477 51.7345 94.3477 44.5374C94.3477 37.3402 92.5005 33.7401 88.8061 33.7401C85.1117 33.7401 83.2645 37.3402 83.2645 44.5374C83.2645 51.7345 85.1117 55.3347 88.8061 55.3347Z"
        fill="#E3E2E0"
      />
      <path
        d="M130.702 67.2848C127.884 67.2848 125.371 66.7162 123.169 65.5821C120.967 64.448 119.242 62.8804 117.995 60.8825C116.751 58.8845 116.125 56.5849 116.125 53.9869C116.125 49.9375 117.687 46.708 120.806 44.2985C123.926 41.889 128.085 40.6858 133.287 40.6858C135.094 40.6858 136.818 40.7958 138.461 41.0157C139.828 41.1979 141.21 41.4837 142.615 41.8733C143.045 41.9927 143.469 41.666 143.469 41.2199C143.469 38.7287 142.709 36.9223 141.191 35.8008C139.674 34.6793 137.243 34.117 133.903 34.117C131.494 34.117 129.059 34.4311 126.596 35.0625C124.249 35.6626 122.104 36.4856 120.153 37.5318C119.968 37.6291 119.751 37.6417 119.559 37.5569L119.157 37.3778C118.912 37.2679 118.755 37.026 118.755 36.759V25.6821C118.755 25.3993 118.928 25.1449 119.192 25.0475C121.959 24.0076 125.076 23.216 128.547 22.6694C132.201 22.0945 135.876 21.8086 139.57 21.8086C147.754 21.8086 153.732 23.1783 157.511 25.9145C161.288 28.6508 163.176 33.0457 163.176 39.0931V53.5628C163.176 53.9366 163.48 54.2413 163.854 54.2413H167.834C168.208 54.2413 168.513 54.5461 168.513 54.9199V65.8806C168.513 66.2544 168.208 66.5591 167.834 66.5591H146.598C146.303 66.5591 146.039 66.3675 145.951 66.0816L144.518 61.495C144.352 60.961 143.648 60.851 143.321 61.3034C142.109 62.9873 140.538 64.3507 138.606 65.3874C136.237 66.6597 133.605 67.2974 130.702 67.2974V67.2848ZM139.281 56.7765C140.02 56.7765 140.792 56.6477 141.6 56.387C142.26 56.1765 142.794 55.9032 143.208 55.5733C143.372 55.4414 143.469 55.2466 143.469 55.0361V48.9416C143.469 48.6558 143.287 48.4013 143.017 48.3008C142.479 48.0997 141.939 47.9552 141.396 47.8704C140.698 47.7605 140.048 47.707 139.445 47.707C138.05 47.707 136.878 48.1186 135.936 48.9385C134.993 49.7584 134.519 50.8548 134.519 52.2214C134.519 53.5879 134.956 54.7251 135.832 55.5451C136.709 56.365 137.858 56.7765 139.281 56.7765Z"
        fill="#E3E2E0"
      />
      <path
        d="M195.591 67.2818C187.819 67.2818 181.696 65.2366 177.219 61.1433C172.746 57.0531 170.506 51.3795 170.506 44.1258C170.506 39.5832 171.458 35.6407 173.358 32.3013C175.259 28.9619 177.807 26.3764 180.995 24.5418C184.184 22.7103 187.693 21.793 191.526 21.793C194.318 21.793 196.766 22.2799 198.874 23.2506C200.633 24.0611 202.172 25.1261 203.492 26.4424C203.85 26.7974 204.453 26.6717 204.619 26.1942L205.735 22.9899C205.829 22.7166 206.086 22.5344 206.375 22.5344H214.289C214.663 22.5344 214.967 22.8391 214.967 23.2129V40.0827C214.967 40.4566 214.663 40.7613 214.289 40.7613H203.749C203.41 40.7613 203.121 40.51 203.08 40.1738C202.813 38.113 202.191 36.5486 201.217 35.4836C200.149 34.3212 198.808 33.7401 197.193 33.7401C195.358 33.7401 193.725 34.5254 192.286 36.0993C190.85 37.6732 190.131 40.2932 190.131 43.9593C190.131 47.6255 191.13 50.4811 193.128 52.1084C195.126 53.7357 197.658 54.5524 200.721 54.5524C203.02 54.5524 205.245 54.1001 207.393 53.1985C209.394 52.3565 211.144 51.3356 212.636 50.1386C212.834 49.9784 213.105 49.9407 213.337 50.0444L213.664 50.1889C213.909 50.2989 214.066 50.5408 214.066 50.8078V62.1988C214.066 62.425 213.956 62.6355 213.771 62.7612C212.53 63.5842 210.971 64.3225 209.099 64.9822C207.073 65.6953 204.883 66.2545 202.53 66.666C200.177 67.0776 197.865 67.2818 195.594 67.2818H195.591Z"
        fill="#E3E2E0"
      />
      <path
        d="M223.3 66.5518C222.926 66.5518 222.621 66.2471 222.621 65.8732V54.2309V16.8126C222.621 16.4387 222.317 16.134 221.943 16.134H217.347C216.973 16.134 216.668 15.8293 216.668 15.4555V5.54405C216.668 5.19534 216.935 4.90318 217.284 4.86863L241.587 2.57534C241.986 2.53764 242.328 2.85179 242.328 3.25076V25.6025C242.328 26.1774 242.997 26.4915 243.44 26.1208C246.871 23.2401 251.222 21.7981 256.493 21.7981C261.447 21.7981 265.079 23.2024 267.394 26.0046C269.706 28.8099 270.862 33.0321 270.862 38.6679V53.5492C270.862 53.923 271.167 54.2277 271.541 54.2277H275.932C276.306 54.2277 276.611 54.5325 276.611 54.9063V65.867C276.611 66.2408 276.306 66.5455 275.932 66.5455H251.837C251.463 66.5455 251.159 66.2408 251.159 65.867V38.6711C251.159 37.0846 250.816 35.9286 250.131 35.2029C249.447 34.4772 248.545 34.1159 247.423 34.1159C246.494 34.1159 245.57 34.3892 244.653 34.9358C243.792 35.451 243.06 36.1422 242.454 37.0155C242.375 37.1286 242.334 37.2668 242.334 37.4051V53.5523C242.334 53.9262 242.639 54.2309 243.013 54.2309H245.35C245.724 54.2309 246.029 54.5356 246.029 54.9094V65.8701C246.029 66.2439 245.724 66.5487 245.35 66.5487L223.306 66.555L223.3 66.5518Z"
        fill="#E3E2E0"
      />
      <path
        d="M301.906 66.5513C301.532 66.5513 301.227 66.2466 301.227 65.8728V52.3832V16.0299C301.227 15.656 300.923 15.3513 300.549 15.3513H295.337C294.963 15.3513 294.659 15.0466 294.659 14.6727V1.86802C294.659 1.49418 294.963 1.18945 295.337 1.18945H329.922C338.844 1.18945 345.576 2.92041 350.118 6.38234C354.661 9.84426 356.932 15.0246 356.932 21.9202C356.932 28.8157 354.674 33.6536 350.159 37.0904C345.642 40.5241 339.035 42.2425 330.33 42.2425H322.756C322.382 42.2425 322.077 42.5472 322.077 42.921V53.5895C322.077 53.9634 322.382 54.2681 322.756 54.2681H333.305C333.679 54.2681 333.984 54.5728 333.984 54.9467V65.8665C333.984 66.2403 333.679 66.545 333.305 66.545L301.903 66.5513H301.906ZM327.541 14.9429H322.759C322.385 14.9429 322.081 15.2476 322.081 15.6215V29.0451C322.081 29.4189 322.385 29.7236 322.759 29.7236H327.129C329.922 29.7236 332.014 29.1362 333.409 27.9581C334.804 26.78 335.501 24.9077 335.501 22.3348C335.501 19.7619 334.823 17.8551 333.469 16.6896C332.115 15.5272 330.135 14.946 327.537 14.946L327.541 14.9429Z"
        fill="#E3E2E0"
      />
      <path
        d="M359.546 66.5531C359.173 66.5531 358.868 66.2483 358.868 65.8745V54.2321V16.3803V5.13378C358.868 4.78507 359.132 4.49606 359.477 4.45836L377.827 2.57975C378.226 2.53891 378.574 2.85306 378.574 3.25517V53.5504C378.574 53.9243 378.879 54.229 379.253 54.229H384.261C384.634 54.229 384.939 54.5337 384.939 54.9076V65.8682C384.939 66.2421 384.634 66.5468 384.261 66.5468L359.55 66.5531H359.546Z"
        fill="#E3E2E0"
      />
      <path
        d="M405.443 67.3235C395.315 67.3235 390.254 61.6312 390.254 50.2464V36.0626C390.254 35.6887 389.95 35.384 389.576 35.384H385.184C384.81 35.384 384.505 35.0793 384.505 34.7054V24.794C384.505 24.4453 384.772 24.1532 385.118 24.1186L409.213 21.8253C409.612 21.7876 409.955 22.1018 409.955 22.5008V50.2872C409.955 51.9554 410.325 53.1617 411.064 53.9C411.802 54.6382 412.747 55.0089 413.897 55.0089C415.898 55.0089 417.456 54.0068 418.575 52.0056C418.631 51.9051 418.66 51.7889 418.66 51.6726V36.0657C418.66 35.6919 418.355 35.3872 417.981 35.3872H415.644C415.27 35.3872 414.965 35.0824 414.965 34.7086V24.7909C414.965 24.4422 415.229 24.1532 415.575 24.1155L437.619 21.8348C438.018 21.7939 438.366 22.1081 438.366 22.5102V53.5513C438.366 53.9251 438.671 54.2298 439.045 54.2298H443.437C443.81 54.2298 444.115 54.5345 444.115 54.9084V65.869C444.115 66.2429 443.81 66.5476 443.437 66.5476H419.75C419.376 66.5476 419.071 66.2429 419.071 65.869V63.3496C419.071 62.7621 418.38 62.4574 417.94 62.8469C416.555 64.0815 414.981 65.0805 413.222 65.8502C410.963 66.8366 408.371 67.3267 405.443 67.3267V67.3235Z"
        fill="#E3E2E0"
      />
      <path
        d="M472.943 67.2847C470.15 67.2847 467.442 66.9549 464.816 66.2983C462.466 65.7108 460.358 64.8941 458.489 63.8479C458.137 63.65 457.694 63.8134 457.54 64.1841L456.736 66.1255C456.632 66.38 456.384 66.5433 456.111 66.5433H448.21C447.836 66.5433 447.531 66.2386 447.531 65.8648V51.7846C447.531 51.4108 447.836 51.106 448.21 51.106H457.543C457.779 51.2725 457.948 51.3919 458.184 51.5553C458.831 53.4779 459.877 55.0109 461.325 56.1544C462.887 57.3859 464.583 58.0016 466.415 58.0016C468.824 58.0016 470.027 57.0969 470.027 55.2937C470.027 53.9805 469.487 52.969 468.406 52.2558C467.326 51.5459 465.928 50.9427 464.219 50.4495C462.507 49.9563 460.694 49.4222 458.778 48.8473C456.861 48.2724 455.049 47.487 453.34 46.4881C451.628 45.4891 450.233 44.1225 449.152 42.3821C448.072 40.6449 447.531 38.3673 447.531 35.5462C447.531 33.0016 448.21 30.6895 449.564 28.6098C450.918 26.5302 452.972 24.8683 455.721 23.6211C458.473 22.3771 461.926 21.752 466.088 21.752C468.771 21.752 471.253 22.0315 473.54 22.5939C475.563 23.0902 477.476 23.8505 479.273 24.8746C479.637 25.0819 480.099 24.906 480.24 24.5102L480.787 22.9803C480.884 22.7101 481.139 22.531 481.425 22.531H489.3C489.674 22.531 489.979 22.8358 489.979 23.2096V35.9735C489.979 36.3473 489.674 36.652 489.3 36.652H479.955C479.672 36.652 479.411 36.4793 479.311 36.2154C478.61 34.3588 477.642 32.9765 476.408 32.0749C475.079 31.1042 473.65 30.6172 472.117 30.6172C469.434 30.6172 468.092 31.4372 468.092 33.0802C468.092 34.23 468.645 35.141 469.754 35.8101C470.863 36.4824 472.286 37.0636 474.023 37.5537C475.761 38.0469 477.602 38.6092 479.546 39.2375C481.488 39.8658 483.328 40.714 485.069 41.7821C486.806 42.8502 488.229 44.2733 489.338 46.0514C490.447 47.8295 491 50.1416 491 52.9878C491 57.3388 489.432 60.807 486.3 63.3956C483.165 65.981 478.71 67.2753 472.936 67.2753L472.943 67.2847Z"
        fill="#E3E2E0"
      />
    </svg>
  );
};
