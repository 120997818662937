import { Box, Typography, ButtonGroup, Button } from "@mui/material";

export const AdminHeader = ({ activeView, setActiveView }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Admin Panel</Typography>
      <ButtonGroup variant="contained" sx={{ mb: 2 }}>
        <Button 
          onClick={() => setActiveView('allUsers')}
          variant={activeView === 'allUsers' ? 'contained' : 'outlined'}
        >
          All Users
        </Button>
        <Button 
          onClick={() => setActiveView('referrals')}
          variant={activeView === 'referrals' ? 'contained' : 'outlined'}
        >
          Referrals
        </Button>
      </ButtonGroup>
    </Box>
  );
}; 