import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { Navigate } from "react-router-dom";
import { resetCoach } from "../../services/query";
import { Box, Typography } from "@mui/material";

export const OTP = () => {
  const [otpSent, setOtpSent] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const supabase = useSupabase();
  const [otpError, setOtpError] = useState(false);
  const { user } = useAuth();

  const onSend = async () => {
    setOtpSent(true);
    await supabase.functions.invoke("otp-wp", {
      body: { phone: user.phone_number, user_id: user.id },
    });
  };

  const onSubmit = async (data) => {
    const { data: res, error } = await supabase.functions.invoke("otp-verify", {
      body: { code },
    });
    if (res.error || error) {
      setOtpError(true);
      return;
    }
    const queryParams = new URLSearchParams({
      from: "otp",
      status: "success",
    });

    window.location.href = `/?${queryParams.toString()}`;
  };

  if (user && user.status === "VERIFIED") {
    return <Navigate to="/" />;
  }

  return (
    <PageContainer style={{ display: "flex", flexDirection: "column" }}>
      <h1 style={{ color: "525A65", padding: "0px" }}>
        One Time Password (OTP)
      </h1>
      <p style={{ color: "#525A65", padding: "0px", marginTop: "0px" }}>
        A verification code has been sent to your phone. Please enter the code
        below to continue.
      </p>
      {!otpSent && (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <button
            style={{
              borderRadius: "17px",
              border: "1px solid #32CD32",
              padding: "10px",
              backgroundColor: "transparent",
              color: "#32CD32",
              cursor: "pointer",
            }}
            disabled={loading}
            onClick={async () => {
              try {
                setLoading(true);
                await onSend();
                setOtpSent(true);
              } catch (error) {
                alert("Error sending OTP");
                setOtpSent(false);
              }
              setLoading(false);
            }}
          >
            Send OTP
          </button>
          <button
            style={{
              borderRadius: "17px",
              background: "transparent",
              border: "1px solid #36454F",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={async () => {
              await resetCoach(supabase, user.id);
              window.location.reload();
            }}
          >
            Change Phone Number
          </button>
        </Box>
      )}
      {otpSent && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "30%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <input
              type="text"
              value={code}
              placeholder="Enter OTP Code"
              style={{
                width: "176px",
                padding: "16px 16px",
                outline: "none",
                border: "1px solid #DAD9D8",
                borderRadius: "18px",
                background: "#FBFBFB",
              }}
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              style={{
                padding: "13px 20px",
                backgroundColor: "transparent",
                borderRadius: "15px",
                border: "1px solid #525A65",
                cursor: "pointer",
              }}
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
          {otpError == true && (
            <Typography
              color="error"
              variant="body2"
              sx={{
                color: "#EC0B43",
                fontSize: "12px",
                margin: "0px",
                padding: "0px",
              }}
            >
              Invalid or expired OTP. Please try again.
            </Typography>
          )}
          <div
            style={{
              fontSize: "11px",
              color: "#525A65",
              margin: "0px",
              padding: "0px",
            }}
          >
            You can{" "}
            <span
              onClick={async () => {
                try {
                  setLoading(true);
                  setOtpError(false);
                  setCode("");
                  await onSend();
                } catch (error) {
                  alert("Error sending OTP");
                }
                setLoading(false);
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#525A65",
              }}
            >
              Resend OTP
            </span>{" "}
            or{" "}
            <span
              onClick={async () => {
                await resetCoach(supabase, user.id);
                window.location.reload();
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#525A65",
              }}
            >
              Change The Number.
            </span>
          </div>
        </div>
      )}
      <br /> <br />
    </PageContainer>
  );
};
