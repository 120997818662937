import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const InviteRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // URL'den referral code'u al
    const searchParams = new URLSearchParams(location.search);
    const refCode = searchParams.get('ref');
    
    // Login sayfasına yönlendir
    if (refCode) {
      navigate(`/login?ref=${refCode}`);
    } else {
      navigate('/login');
    }
  }, [location, navigate]);

  return null; // Bu component sadece redirect yapacak
}; 