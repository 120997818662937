import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Divider, Typography, useMediaQuery, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const TPModal = ({ selected, onClose, open, exerciseplan }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videosAndImages, setVideosAndImages] = useState([]);

  useEffect(() => {
    const initialIndex = exerciseplan.findIndex(item => item.id === selected);
    if (initialIndex !== -1) {
      const exercise = exerciseplan[initialIndex];
      const videoItems = exercise.videos ? exercise.videos.map(vid => ({ vid: vid.id })) : [];
      const imageItems = exercise.images ? exercise.images.map(img => ({ image: img })) : [];
      setVideosAndImages([...videoItems, ...imageItems]);
    } else {
      setVideosAndImages([{vid:selected}]);

    }
  }, [selected, exerciseplan]);

  const handleNextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videosAndImages.length); // Loop back to the first video
  };

  const handlePreviousVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videosAndImages.length) % videosAndImages.length); // Loop back to the last video
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        backgroundColor: "unset",
        ".MuiPaper-root": {
          backgroundColor: "unset",
          maxWidth: '1040px',
          display: 'flex',
          height: "700px",
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: "unset"
        },
      }}
    >
      {/* Close Button at the Top Center */}
      <Button
        startIcon={<CloseIcon />}
        size="small"
        onClick={onClose}
        aria-label="Close dialog"
        sx={close}
      />

      {/* Container for Images and Iframe */}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', flex: '1 1 auto' }}>
        {/* Left SVG Icon for Previous Video */}
        <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', marginRight: '20px' }} onClick={handlePreviousVideo}>
          <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 22.6577L5.05 12.5L16 2.3423L13.475 2.20743e-07L2.525 10.1577L-1.09278e-06 12.5L13.475 25L16 22.6577Z" fill="#D9D9D9" />
          </svg>
        </div>

        {/* Iframe and Image Container */}
        <div style={{ position: 'relative', height: '538px', overflow: 'hidden', flex: '1 1 auto', maxWidth: '960px', marginTop: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* Display Image */}
          {videosAndImages[currentIndex]?.image && (
            <img
              src={videosAndImages[currentIndex].image}
              alt="Exercise"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain', // Use 'contain' to maintain aspect ratio
                borderRadius: "16px",
                zIndex: 1,
                display: 'block', // Ensures the image is treated as a block element
              }}
            />
          )}
          {/* Iframe for Video */}
          {videosAndImages[currentIndex]?.vid && (
            <iframe
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 0, borderRadius: "16px", zIndex: 2 }}
              loading="lazy"
              src={`https://www.youtube.com/embed/${videosAndImages[currentIndex].vid}`}
              title="Exercise Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>

        {/* Right SVG Icon for Next Video */}
        <div style={{ flex: '0 0 auto', display: 'flex', alignItems: 'center', marginLeft: '20px' }} onClick={handleNextVideo}>
          <svg width="16" height="25" viewBox="0 0 16 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.3423L10.95 12.5L0 22.6577L2.525 25L13.475 14.8423L16 12.5L2.525 0L0 2.3423Z" fill="#D9D9D9" />
          </svg>
        </div>
      </div>

      {/* Typography Section at the Bottom */}
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography align="center" sx={{ fontSize: "24px", color: "#FFFFFF", fontWeight: "400 !important" }}>
          {videosAndImages.length > 0 ? `${currentIndex + 1}/${videosAndImages.length}` : '0/0'}
        </Typography>
      </div>
    </Dialog>
  );
}

export const close = {
  position: 'absolute',
  top: '16px',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  marginLeft: "auto",
  color: "#525A65",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#EEEEEE",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65"
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd"
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px"
  },
}