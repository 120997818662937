import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Link from '@mui/material/Link';
import { useAuth } from "../../providers/AuthContextProvider";
import { useLocation } from "react-router-dom";
import { ContactModal } from "../../components/ContactModal/ContactModal";
import { PricingModal } from '../../components/PricingModal/PricingModal';

export const LoginRoute = () => {
  const location = useLocation();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [Open, setOpen] = useState(false);
  const { signIn } = useAuth();
  const [contactOpen, setContactOpen] = useState(false);
  const [pricingOpen, setPricingOpen] = useState(false);

  useEffect(() => {
    // URL'den referral code'u al
    const searchParams = new URLSearchParams(location.search);
    const refCode = searchParams.get('ref');
    
    if (refCode) {
      sessionStorage.setItem('referral_code', refCode);
    }
  }, [location]);

  return (
    <Paper>
      <Box
        sx={{
          backgroundColor: "#013B12",
          height: { xs: "auto", sm: "auto", lg: "382px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: "20px", sm: "10", xl: "0" },
        }}
      >
        <Box mt={{ xs: 2, sm: 4 }}>
          <img
            src={require("../../assets/Logos_login.png")}
            alt="Logo"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "1360px",
            }}
          />
        </Box>
        <Box
          sx={{
            mt: { xs: 2, sm: 6 },
            mb: { sm: 2 },
            display: "flex",
            alignItems: { xs: "end", sm: "center" },
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "1360px",
            color: "#02BD36",
          }}
        >
          {!Open && (
            <Typography
              sx={{
                fontSize: { xs: "24px", sm: "36px" },
                fontWeight: "900 !important",
                fontFamily: "Barlow Condensed, sans-serif !important",
                lineHeight: "1",
              }}
            >
              LESS TIME ON ADMIN,{isSmallScreen && <br />} MORE TIME WITH
              YOUR CLIENTS
            </Typography>
          )}
          {Open && (
            <>
              <Button
                variant="outlined"
                onClick={() => setPricingOpen(true)}
                sx={{
                  mr: { sm: 1 },
                  color: "#02BD36",
                  borderColor: "#02BD36",
                  borderRadius: "20px",
                  fontSize: "16px",
                  maxHeight: "48px",
                  "&:hover": {
                    backgroundColor: "unset !important",
                    borderColor: "#02BD36",
                  },
                  padding: "10.5px 18px",
                  textTransform: "unset",
                }}
              >
                Pricing
              </Button>
              <Button
                variant="outlined"
                onClick={() => setContactOpen(true)}
                sx={{
                  mx: { sm: 1 },
                  color: "#02BD36",
                  borderColor: "#02BD36",
                  borderRadius: "20px",
                  padding: "10.5px 18px",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "unset !important",
                    borderColor: "#02BD36",
                  },
                  maxHeight: "48px",
                  textTransform: "unset",
                }}
              >
                Contact
              </Button>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: { xs: 2, sm: 0 },
              justifyContent: { xs: "flex-start", sm: "flex-start" },
            }}
          >
            {isSmallScreen ? (
              <>
                {!Open && <SvgMenuIcon Open={() => setOpen(true)} />}

                {Open && (
                  <SvgMenuIconClose Close={() => setOpen(false)} />
                )}
              </>
            ) : (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setPricingOpen(true)}
                  sx={{
                    mr: { sm: 1 },
                    color: "#02BD36",
                    borderColor: "#02BD36",
                    borderRadius: "20px",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "unset !important",
                      borderColor: "#02BD36",
                    },
                    maxHeight: "48px",
                    padding: "10.5px 18px",
                    textTransform: "unset",
                  }}
                >
                  Pricing
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setContactOpen(true)}
                  sx={{
                    mx: { sm: 1 },
                    color: "#02BD36",
                    borderColor: "#02BD36",
                    borderRadius: "20px",
                    padding: "10.5px 18px",
                    fontSize: "16px",
                    maxHeight: "48px",
                    "&:hover": {
                      backgroundColor: "unset !important",
                      borderColor: "#02BD36",
                    },
                    textTransform: "unset",
                  }}
                >
                  Contact
                </Button>
                
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box

        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
        <Grid2
          container
          spacing={4}
          mt={6}
          display={"flex"}
          justifyContent={"center"}
          gap={5}
          ml={{ xs: 1, sm: 2, xl: 0 }}
          mr={{ xs: 1, sm: 2, xl: 0 }}
        >
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6} // Half width on medium and larger screens
            maxHeight={600}
            maxWidth={660}
            width={{ xs: "353px !important", sm: "660px !important" }}
            height={{ xs: "321px !important", sm: "600px !important" }}
            backgroundColor={"#E3E2E0"}
            borderRadius={"24px"}
            textAlign={"center"}
            p={2}

          >
            <Box width={{ sm: "auto" }}
            >
              <Typography
                sx={{
                  mt: { xs: 5, sm: 10 },
                  mb: { xs: 5, sm: 10 },
                  fontSize: { xs: "24px", sm: "64px" },
                  fontWeight: "900 !important",
                  fontFamily: "Barlow Condensed, sans-serif !important",
                  lineHeight: "1",
                  color: "#525A65",
                }}
              >
                LET COACHPLUS BUILD PLANS AND HANDLE SCHEDULING FOR YOU
              </Typography>
              <Typography
                sx={{
                  mb: { xs: 5, sm: 8 },
                  fontSize: { xs: "14px", sm: "20px" },
                  fontWeight: "600 !important",
                  lineHeight: "1",
                  color: "#525A65",
                }}
              >
                Try CoachPlus with no upfront costs.
                <br /> CoachPlus is free up until 3 client.
              </Typography>
              <Button
                variant="outlined"
                onClick={signIn}
                sx={{
                  backgroundColor: "#FBFBFB",
                  minWidth: {
                    xs: "306px !important",
                    sm: "360px !important",
                    xl: "420px !important",
                  },
                  "&:hover": {
                    border: "1px solid transparent",

                  },
                  fontSize: "12px",
                  minHeight: "48px",
                  color: "#525A65",
                  borderRadius: " 20px",
                  background:
                    "linear-gradient(#FBFBFB, #FBFBFB) padding-box, linear-gradient(to right, #02BD36 0%, #A080E8 35%, #E67B02 65%, #2998E9 100%) border-box",
                  border: "1px solid transparent",
                  textTransform: "none",
                }}
              >
                <div style={{ paddingRight: "9px", paddingTop: "6px" }}>
                  <GoogleButtonIcon />
                </div>
                Continue with Google
              </Button>
              <Typography
                variant="body1"
                sx={{
                  mt: 3,
                  color: "#525A65",
                  fontWeight: "400 !important",
                  fontSize: { xs: "8px", sm: "12px" },
                }}
              >
                By clicking continue, you agree to our
                <br />
                <a
                  href="https://coachplus.club/terms-and-conditions"
                  style={{ color: "#525A65" }}
                >
                  User Agreement
                </a>
                <span> and </span>
                <a
                  href="https://coachplus.club/privacy-policy"
                  style={{ color: "#525A65" }}
                >
                  Privacy Policy.
                </a>
              </Typography>
            </Box>
          </Grid2>
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6}
            maxHeight={600}
            maxWidth={660}
            borderRadius={"24px"}
            p={0}
            display={"flex"}
            justifyContent={"center"}
            width={{ xs: "353px !important", sm: "660px !important" }}
            height={{ xs: "321px !important", sm: "600px !important" }}
          >
            <Box>
              <img
                src={require("../../assets/loginpagephoto.png")}
                alt="GYM"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "660px",
                }}
              />
            </Box>

          </Grid2>
        </Grid2>
      </Box>
      <Box
        pl={{ xs: 1, sm: 2, xl: 0 }}
        pr={{ xs: 1, sm: 2, xl: 0 }}
        sx={{
          display: "flex",
          flexDirection: "column", // Stack text vertically
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",

          mt: 6, // Add some margin at the top
        }}
      >
        <Typography
          sx={{
            mb: 2,
            fontSize: { xs: "32px", sm: "64px" },
            fontWeight: "900 !important",
            fontFamily: "Barlow Condensed, sans-serif !important",
            lineHeight: "1",
            color: "#A080E8",
          }}
        >
          A PRODUCTIVITY ASSISTANT FOR
          {!isSmallScreen ? <br /> : " "}
          COACHES AND TRAINERS
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 2,
            fontSize: { xs: "14px", sm: "24px" },
            fontWeight: "600 !important",
            color: "#525A65",
          }}
        >
          Simplify your workflow, enhance client engagement, and focus on
          growth.
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 6,
            fontSize: { xs: "14px", sm: "24px" },
            fontWeight: "400 !important",
            color: "#525A65",
          }}
        >
          CoachPlus integrates with the tools you already use, like
          WhatsApp, email, and
          {!isSmallScreen && <br />}calendars—no extra downloads needed for you or your
          clients.
          <br />
          {isSmallScreen && <br />}
          {!isSmallScreen && <br />}It's a simple platform that brings scheduling, feedback,
          training plans, progress
          {!isSmallScreen && <br />}tracking, accountability, and communication into one
          place. By keeping everything {!isSmallScreen && <br />}
          organized, Coach+ helps you save time and manage your schedule
          more effectively.
        </Typography>
        <Button
          variant="otulined"
          sx={{
            color: "#A080E8",
            borderColor: "#A080E8",
            backgroundColor: "#46245D",
            border: "1px solid",
            borderRadius: "20px",
            fontSize: "16px",
            padding: "10.5px 20px",
            maxHeight: "48px",
            textTransform: "unset",
          }}
        >
          Try for free
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
        <Grid2
          container
          spacing={4}
          mt={6}
          display={"flex"}
          justifyContent={"center"}
          p={0}
          gap={5}
          ml={{ xs: 1, sm: 2, xl: 0 }}
          mr={{ xs: 1, sm: 2, xl: 0 }}
        >
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6} // Half width on medium and larger screens
            maxHeight={600}
            maxWidth={660}
            p={0}
            display={"flex"}
            width={{ xs: "353px !important", sm: "660px !important" }}
            height={{ xs: "321px !important", sm: "600px !important" }}
            justifyContent={"center"}
          >
            <Box  >
              <img
                src={require("../../assets/Rectangle 114.png")}
                alt="GYM"
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "660px",
                }}
              />
            </Box>
          </Grid2>
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6} // Half width on medium and larger screens
            maxHeight={600}
            maxWidth={660}
            width={{ xs: "353px !important", sm: "660px !important" }}
            height={{ xs: "321px !important", sm: "600px !important" }}
            backgroundColor={"#351E06"}
            borderRadius={"24px"}
            textAlign={"center"}
            p={0}
            sx={{
              display: "flex", // Use flexbox
              flexDirection: "column", // Stack children vertically
              justifyContent: "space-between", // Space between children
              padding: "20px", // Optional: Add padding for spacing
            }}
          >
            <img
              src={require("../../assets/logohome.png")}
              alt="GYM"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "613px",
                marginBottom: "auto", // Ensure the image is at the top
              }}
            />
            <Typography
              sx={{
                fontSize: { xs: "30px", sm: "60px" },
                fontWeight: "900 !important",
                color: "#E67B02",
                fontFamily: "Barlow Condensed, sans-serif !important",
                lineHeight: "1",
              }}
            >
              COACH MORE, MANAGE LESS
            </Typography>
          </Grid2>
        </Grid2>
      </Box>
      <Box
        pl={{ xs: 1, sm: 2, xl: 0 }}
        pr={{ xs: 1, sm: 2, xl: 0 }}
        sx={{
          mb: 5,
          display: "flex",
          flexDirection: "column", // Stack text vertically
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mt: 6, // Add some margin at the top
        }}
      >
        <Typography
          sx={{
            mb: 2,
            fontSize: { xs: "32px", sm: "64px" },
            fontWeight: "900 !important",
            fontFamily: "Barlow Condensed, sans-serif !important",
            lineHeight: "1",
            color: "#2998E9",
          }}
        >
          EFFORTLESS COACHING, <br />
          NO APP REQUIRED
        </Typography>
        <Typography
          sx={{
            mt: 2,
            mb: 5,
            fontSize: { xs: "14px", sm: "24px" },
            fontWeight: "400 !important",
            color: "#525A65",
          }}
        >
          CoachPlus makes it easy for coaches and athletes to
          <br />
          connect. No downloads or complicated steps—just a
          <br />
          seamless, hassle-free experience designed for your success.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#525A65",
            fontWeight: "600 !important",
            fontSize: { xs: "14px", sm: "20px" },
            mb: 5,
          }}
        >
          Try CoachPlus with no upfront costs.
          <br /> CoachPlus is{" "}
          <span style={{ color: "#2998E9" }}>free</span> up until{" "}
          <span style={{ color: "#2998E9" }}>3 client</span>.
        </Typography>
        <Button
          variant="otulined"
          sx={{
            color: "#2998E9",
            borderColor: "#2998E9",
            border: "1px solid",
            backgroundColor: "#043A8D",
            borderRadius: "20px",
            fontSize: "16px",
            padding: "10.5px 20px",
            maxHeight: "48px",
            textTransform: "unset",
          }}
        >
          Pricing
        </Button>
      </Box>
      <Box
        sx={{
          minHeight: "200px",
          padding: "0px !important",
          borderRadius: "0px !important",
          backgroundColor: "#E3E2E0",
          borderTop: "3px solid transparent",
          borderWidth: "2px",
          borderImage:
            "linear-gradient(to right, #02BD36 0%, #A080E8 35%, #E67B02 65%, #2998E9 100%) 1 0 0 0",
          backgroundOrigin: "padding - box, border - box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid2
          container
          spacing={2}
          mt={3}
          pl={{ xs: 2, sm: 2, xl: 0 }}
          pr={{ xs: 2, sm: 2, xl: 0 }}
          width={"100%"}
        >
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6}
            size={6} // Half width on medium and larger screens
            sx={{ float: "left", pl: { xs: 0, sm: 6 }, pt: 0 }}
          >
            <img
              src={require("../../assets/Logofoteer.png")}
              alt="GYM"
              style={{
                width: "100%",
                height: "auto",
                maxWidth: "413px",
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "#000000",
                fontSize: "12px",
                marginTop: "10px !important",
                paddingLeft: "2px",
                textAlign: { xs: "center", sm: "start" }
              }}
            >
              © 2024 CoachPlus ltd.{" "}
              <a href="#" style={{ color: "#525A65" }}>
                contact@coachplus.club
              </a>
            </Typography>
          </Grid2>
          <Grid2
            xs={12} // Full width on extra small screens
            sm={6}
            size={6}
            sx={{ pr: { xs: 0, sm: 6 }, pt: 0 }}
          >
            <Box sx={{ float: { xs: "none", sm: "right" }, display: { xs: "flex", sm: "block" }, justifyContent: "space-between", gap: 1, fontSize: { xs: "10px" }, mt: { xs: 1, sm: 0 } }}>
              <Link href="https://coachplus.club/terms-and-conditions" color={"#525A65"} underline="always" fontSize={{ xs: "10px", sm: "14px" }}>
                User Agreement
              </Link>

              {!isSmallScreen && <br />}
              <Link href="https://coachplus.club/privacy-policy" color={"#525A65"} underline="always" fontSize={{ xs: "10px", sm: "14px" }}>
                Privacy Policy
              </Link>
              {!isSmallScreen && <br />}
              <Link href="/blog" color={"#525A65"} underline="always" fontSize={{ xs: "10px", sm: "14px" }}>
                Blog
              </Link>
              {!isSmallScreen && <br />}
              <Link href="https://www.linkedin.com/company/coachplusclub" color={"#525A65"} underline="always" fontSize={{ xs: "10px", sm: "14px" }}>
                LinkedIn
              </Link>
              {!isSmallScreen && <br />}
              <Link href="https://www.instagram.com/coachplusclub/" color={"#525A65"} underline="always" fontSize={{ xs: "10px", sm: "14px" }}>
                Instagram
              </Link>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
      <ContactModal 
        open={contactOpen}
        onClose={() => setContactOpen(false)}
      />
      <PricingModal 
        open={pricingOpen}
        onClose={() => setPricingOpen(false)}
      />
    </Paper >
  );
};

export const SvgMenuIcon = ({ Open }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={Open}
    >
      <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#02BD36" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 17.6C14 16.7163 14.7163 16 15.6 16H31.6C32.4837 16 33.2 16.7163 33.2 17.6C33.2 18.4837 32.4837 19.2 31.6 19.2H15.6C14.7163 19.2 14 18.4837 14 17.6ZM14 24C14 23.1163 14.7163 22.4 15.6 22.4H31.6C32.4837 22.4 33.2 23.1163 33.2 24C33.2 24.8837 32.4837 25.6 31.6 25.6H15.6C14.7163 25.6 14 24.8837 14 24ZM15.6 28.8C14.7163 28.8 14 29.5163 14 30.4C14 31.2837 14.7163 32 15.6 32H31.6C32.4837 32 33.2 31.2837 33.2 30.4C33.2 29.5163 32.4837 28.8 31.6 28.8H15.6Z"
        fill="#02BD36"
      />
    </svg>
  );
};

export const SvgMenuIconClose = ({ Close }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={Close}
    >
      <rect
        x="16.1426"
        y="29.5762"
        width="19.2"
        height="3.2"
        rx="1.6"
        transform="rotate(-45 16.1426 29.5762)"
        fill="#02BD36"
      />
      <rect
        x="18.2637"
        y="16.1406"
        width="19.2"
        height="3.2"
        rx="1.6"
        transform="rotate(45 18.2637 16.1406)"
        fill="#02BD36"
      />
      <rect x="0.5" y="0.5" width="47" height="47" rx="11.5" stroke="#02BD36" />
    </svg>
  );
};

export const GoogleButtonIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9998 8.34945C15.9998 7.7706 15.9479 7.21401 15.8514 6.67969H8.16309V9.8411H12.5564C12.3635 10.8578 11.7846 11.7186 10.9163 12.2975V14.3532H13.5657C15.1093 12.9283 15.9998 10.8355 15.9998 8.34945Z"
        fill="#525A65"
      />
      <path
        d="M8.16355 16.3275C10.3676 16.3275 12.2155 15.6002 13.5661 14.3535L10.9168 12.2978C10.1895 12.7876 9.26188 13.0844 8.16355 13.0844C6.0411 13.0844 4.23776 11.6522 3.59212 9.72266H0.875977V11.8303C2.2192 14.4945 4.97245 16.3275 8.16355 16.3275Z"
        fill="#525A65"
      />
      <path
        d="M3.59184 9.71355C3.42857 9.22376 3.3321 8.70428 3.3321 8.16253C3.3321 7.62079 3.42857 7.10131 3.59184 6.61151V4.50391H0.875696C0.319109 5.60224 0 6.84157 0 8.16253C0 9.4835 0.319109 10.7228 0.875696 11.8212L2.99072 10.1737L3.59184 9.71355Z"
        fill="#525A65"
      />
      <path
        d="M8.16355 3.25046C9.36577 3.25046 10.4344 3.66605 11.2878 4.46753L13.6255 2.12987C12.2081 0.808905 10.3676 0 8.16355 0C4.97245 0 2.2192 1.83302 0.875977 4.50464L3.59212 6.61224C4.23776 4.68275 6.0411 3.25046 8.16355 3.25046Z"
        fill="#525A65"
      />
    </svg>
  );
};
