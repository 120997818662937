import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Autocomplete,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { getCoachById, updateCoach } from "../../services/query";
import { MobileDatePicker } from "@mui/x-date-pickers";
import {
  Typography1style,
  Typography2style,
  Typography3style,
  subsStyle,
  formtextfieldStyle,
  formautodStyle1,
  formautodStyle2,
  submitStyle,
  formDateFieldStyle,
  signoutStyle,
} from "./style";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import { sortedCountries } from "../../components/countrycode/countrycode";
import dayjs from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Snackbar, Alert } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ShareIcon from '@mui/icons-material/Share';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';
import { languages } from "./languages";
import Popper from '@mui/material/Popper';

const referralBoxStyle = {
  backgroundColor: "#F5F5F5",
  padding: "20px",
  borderRadius: "12px",
  marginTop: "34px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const copyButtonStyle = {
  minWidth: "auto",
  padding: "8px",
  color: "#666",
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
};

export const AccountSettings = () => {
  const { register, handleSubmit, reset, control } = useForm({
    resetOptions: {
      keepDirtyValues: true,
    },
  });
  const { user, signOut } = useAuth();
  const supabase = useSupabase();
  const [isInitializing, setIsInitializing] = useState(true);
  const [subData, setSubData] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getCoach = async () => {
    try {
      const { data } = await getCoachById(supabase, user.id);
      if (data?.[0]) {
        reset(data[0]);
      }
    } catch (error) {
      console.error("Get coach error:", error.message);
    }
  };

  const getSubStatus = async () => {
    try {
      const { data } = await supabase.functions.invoke("st-sub-status");
      setSubData(data);
    } catch (error) {
      console.error("Subscription status error:", error.message);
    }
  };

  const onSubmit = handleSubmit((data) => {
    setSubmitLoading(true);
    if (data.country_code === null) {
      setSubmitLoading(false);
      return alert("Please fill the code");
    }

    updateCoach(
      supabase,
      { ...data, full_name: `${data.first_name} ${data.last_name}` },
      user.id
    ).then((response) => {
      setSubmitLoading(false);
      setSnackbar({
        open: true,
        message: "Settings updated successfully!",
        severity: "success"
      });
    }).catch((error) => {
      setSubmitLoading(false);
      setSnackbar({
        open: true,
        message: "Failed to update settings. Please try again.",
        severity: "error"
      });
    });
  });

  const handleCopyFullLink = async () => {
    try {
      const referralLink = `${window.location.origin}/invite?ref=${user?.referral_code}`;
      await navigator.clipboard.writeText(referralLink);
      setSnackbar({
        open: true,
        message: "Referral link copied!",
        severity: "success",
      });
    } catch (err) {
      setSnackbar({
        open: true,
        message: "Failed to copy referral link",
        severity: "error",
      });
    }
    handleClose();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShare = (platform) => {
    const referralLink = `${window.location.origin}/invite?ref=${user?.referral_code}`;
    const message = "Join CoachPlus using my referral link:";
    
    switch (platform) {
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodeURIComponent(message + ' ' + referralLink)}`);
        break;
      case 'telegram':
        window.open(`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`);
        break;
      case 'email':
        window.open(`mailto:?subject=Join CoachPlus&body=${encodeURIComponent(message + ' ' + referralLink)}`);
        break;
      case 'linkedin':
        const linkedinUrl = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(message + ' ' + referralLink)}`;
        window.open(linkedinUrl, '_blank');
        break;
      case 'native':
        if (navigator.share) {
          navigator.share({
            title: 'Join CoachPlus',
            text: message,
            url: referralLink
          }).catch((error) => console.log('Error sharing:', error));
        } else {
          handleCopyFullLink();
        }
        break;
      default:
        handleCopyFullLink();
    }
    handleClose();
  };

  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (!user) return;
      
      try {
        setIsInitializing(true);
        await Promise.all([getCoach(), getSubStatus()]);
      } catch (error) {
        console.error("Initialization error:", error);
      } finally {
        if (isMounted) {
          setIsInitializing(false);
        }
      }
    };

    initializeData();

    return () => {
      isMounted = false;
    };
  }, [user]);

  if (isInitializing) {
    return <PageContainer>Loading...</PageContainer>;
  }

  return (
    <>
      <PageContainer>
        <Paper
          variant="outlined"
          sx={{
            borderRadius: "24px",
            backgroundColor: "#FBFBFB !important",
            height: "100%",
            border: "none",
          }}
        >
          <Box sx={{ padding: "40px", maxWidth: "600px" }}>
            <Typography variant="subtitle" sx={Typography1style}>
              Settings
            </Typography>
            {subData && (
              <Box mt={3} sx={{ marginTop: "34px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle" sx={Typography2style}>
                      Subscription Status:{" "}
                      <Typography variant="subtitle" sx={Typography3style}>
                        {subData.isSubscribed ? "Active" : "No subscription"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{ paddingTop: "0px !important", display: "flex" }}
                  >
                    {subData.isSubscribed && (
                      <Button
                        sx={subsStyle}
                        onClick={async () => {
                          setIsInitializing(true);
                          try {
                            await supabase.functions.invoke("st-cancel-sub");
                            getSubStatus();
                          } catch (error) {
                            alert(error.message);
                          }
                          setIsInitializing(false);
                        }}
                        disabled={isInitializing}
                      >
                        Cancel Subscription
                      </Button>
                    )}
                    {!subData.isSubscribed && (
                      <Button
                        sx={subsStyle}
                        disabled={isInitializing}
                        onClick={async () => {
                          setIsInitializing(true);
                          try {
                            const { data } = await supabase.functions.invoke(
                              "st-checkout",
                              {
                                body: {
                                  priceId:
                                    process.env.REACT_APP_STRIPE_PRICE,
                                  baseUrl: window.location.origin,
                                },
                              }
                            );
                            window.location = data.url;
                          } catch (error) {
                            alert(error.message);
                          }
                          setIsInitializing(false);
                        }}
                      >
                        Subscribe
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box sx={referralBoxStyle}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    color: "#666",
                    fontSize: "14px",
                    marginBottom: "4px",
                  }}
                >
                  Your Referral Code
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    letterSpacing: "1px",
                  }}
                >
                  {user?.referral_code || "N/A"}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                  sx={copyButtonStyle}
                  onClick={() => {
                    navigator.clipboard.writeText(user?.referral_code);
                    setSnackbar({
                      open: true,
                      message: "Referral code copied!",
                      severity: "success",
                    });
                  }}
                  startIcon={<ContentCopyIcon />}
                >
                  Copy Code
                </Button>
                <Button
                  sx={{
                    ...copyButtonStyle,
                    backgroundColor: '#02BD36',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#029c2d',
                    }
                  }}
                  onClick={handleShareClick}
                  startIcon={<ShareIcon />}
                >
                  Share
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'share-button',
                  }}
                  PaperProps={{
                    sx: {
                      borderRadius: '12px',
                      mt: 1,
                    }
                  }}
                >
                  <MenuItem onClick={handleCopyFullLink} sx={{ gap: 1 }}>
                    <LinkIcon sx={{ color: '#666' }} />
                    Copy Link
                  </MenuItem>
                  <MenuItem onClick={() => handleShare('whatsapp')} sx={{ gap: 1 }}>
                    <WhatsAppIcon sx={{ color: '#25D366' }} />
                    WhatsApp
                  </MenuItem>
                  <MenuItem onClick={() => handleShare('telegram')} sx={{ gap: 1 }}>
                    <TelegramIcon sx={{ color: '#0088cc' }} />
                    Telegram
                  </MenuItem>
                  <MenuItem onClick={() => handleShare('email')} sx={{ gap: 1 }}>
                    <EmailIcon sx={{ color: '#EA4335' }} />
                    Email
                  </MenuItem>
                  <MenuItem onClick={() => handleShare('linkedin')} sx={{ gap: 1 }}>
                    <LinkedInIcon sx={{ color: '#0A66C2' }} />
                    LinkedIn
                  </MenuItem>
                  {navigator.share && (
                    <MenuItem onClick={() => handleShare('native')} sx={{ gap: 1 }}>
                      <ShareIcon />
                      More Options
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
            <br />
            <form action="">
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  First name
                </InputLabel>
                <TextField
                  {...register("first_name")}
                  placeholder="First name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Surname
                </InputLabel>
                <TextField
                  {...register("last_name")}
                  placeholder="Surname"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <Controller
                  control={control}
                  name="dob"
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DateField"]}
                          sx={{
                            ".css-10o2lyd-MuiStack-root": {
                              paddingTop: "0px !important",
                            },
                          }}
                        >
                          <Box sx={{ mt: 3, width: "100%" }}>
                            <InputLabel
                              shrink
                              htmlFor="bootstrap-input"
                              sx={{
                                paddingLeft: "24px",
                                color: "#838F99",
                                fontWeight: "400 !important",
                              }}
                            >
                              Date of birth
                            </InputLabel>
                            <DateField
                              defaultValue={dayjs(
                                new Date(field.value ? field.value : null)
                              )}
                              format="LL"
                              sx={formDateFieldStyle}
                              inputRef={field.ref}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              fullWidth
                            />
                          </Box>
                        </DemoContainer>
                      </LocalizationProvider>
                    );
                  }}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  City
                </InputLabel>
                <TextField
                  {...register("city")}
                  placeholder="City Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Country
                </InputLabel>
                <TextField
                  {...register("country")}
                  placeholder="country Name"
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  Preferred Language
                </InputLabel>
                <Controller
                  name="preferred_language"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={languages}
                      getOptionLabel={(option) => 
                        typeof option === 'string' ? option : option.name
                      }
                      value={field.value ? languages.find(lang => lang.code === field.value) || null : null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue ? newValue.code : '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Language"
                          sx={formtextfieldStyle}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {params.InputProps.endAdornment}
                                <KeyboardArrowDownIcon 
                                  sx={{
                                    position: "absolute",
                                    right: "14px",
                                    pointerEvents: "none",
                                    color: "#666"
                                  }}
                                />
                              </>
                            ),
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props}>
                          {option.name}
                        </MenuItem>
                      )}
                      sx={{
                        "& .MuiAutocomplete-endAdornment": {
                          display: "none"
                        }
                      }}
                      disableClearable
                      componentsProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'flip',
                              enabled: false,
                            },
                          ],
                          placement: "bottom",
                        }
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: '250px',
                        }
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ mt: 3 }}>
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{
                    paddingLeft: "24px",
                    color: "#838F99",
                    fontWeight: "400 !important",
                  }}
                >
                  What sport or activity do you coach?
                </InputLabel>
                <TextField
                  {...register("sports_activities")}
                  required
                  sx={formtextfieldStyle}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Box>
              <br />
              <br />
              <Button
                type="submit"
                sx={{
                  ...submitStyle,
                  opacity: submitLoading ? 0.7 : 1,
                  cursor: submitLoading ? 'not-allowed' : 'pointer'
                }}
                onClick={onSubmit}
                disabled={submitLoading}
              >
                {submitLoading ? "Saving..." : "Submit"}
              </Button>
              <br />
              <br />
              <Button sx={signoutStyle} type="submit" onClick={signOut}>
                Signout
              </Button>
              <br />
            </form>
          </Box>
        </Paper>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{
              width: "100%",
              "& .MuiAlert-message": {
                fontSize: "14px",
                fontWeight: 500,
              },
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </PageContainer>
    </>
  );
};
