import { Box, CircularProgress } from "@mui/material";
import { PageContainer } from "../../../components/PageContainer/PageContainer";

export const LoadingSpinner = () => {
  return (
    <PageContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    </PageContainer>
  );
}; 