import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListSubheader,
  Paper,
  Autocomplete,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
  Close,
  Delete,
  Edit,
  Mail,
  Phone,
  WarningRounded,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useAuth, useSupabase } from "../../providers/AuthContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useCalendar } from "../../providers/CalendarProvider";
import { getAthleteName } from "../../utils/selectors";
import {
  deleteAthlete,
  getAthleteProfile,
  getCalendarsByCoachIdAthleteId,
  getPlanByIdPublic,
  insertCalendar,
  upsertAthlete,
  getAthletesByCoachId,
  getPlanByIdClient,
} from "../../services/query";
import { getTimeZone } from "../../utils/calendar";
import { CreateEventModal } from "../../components/CreateEventModal/CreateEventModal";
import { GetAthleteStatus } from "../../components/AthletesTable/AthletesTable";
import { Controller, useForm } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid";
import { ClientDetailTraining } from "../../components/ClientDetailTraining/ClientDetailTraining";
import { CardDayAtheletesPlan } from "../../components/ClientDetailTraining/CardDaysAtheletesPlan";
import { SuccessIcon } from "../Dashboard/Dashboard.component";

const EditModal = ({ editModalOpen, setEditModalOpen, athlete, onSuccess }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      first_name: athlete.first_name,
      last_name: athlete.last_name,
      city: athlete.city,
      country: athlete.country,
      phone_number: athlete.phone_number,
      dob: new Date(athlete.dob),
    },
  });

  const supabase = useSupabase();

  const onSubmit = handleSubmit(async (data) => {
    await upsertAthlete(supabase, {
      id: athlete.id,
      first_name: data.first_name,
      last_name: data.last_name,
      full_name: `${data.first_name} ${data.last_name}`,
      city: data.city,
      country: data.country,
      phone_number: data.phone_number,
      dob: data.dob,
    });
    setEditModalOpen(false);
    onSuccess && onSuccess();
  });
  return (
    <Dialog
      onClose={() => setEditModalOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={editModalOpen}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Edit Athlete
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setEditModalOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("first_name")}
              label="First Name"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("last_name")}
              label="Last Name"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("city")}
              label="City"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("country")}
              label="Country"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              {...register("phone_number")}
              label="Phone Number"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Box sx={{ maxWidth: 400, mt: 2, mb: 2 }}>
            <Controller
              control={control}
              name="dob"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <MobileDatePicker
                    label="Date of Birth"
                    format="yyyy-MM-dd"
                    value={field.value && new Date(field.value)}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    fullWidth
                  />
                );
              }}
            />
          </Box>
          <Button type="submit">Submit</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const AthleteRoute = () => {
  const [open, setOpen] = useState(false);
  const [athlete, setAthlete] = useState(null);

  const params = useParams();
  const supabase = useSupabase();
  const { user } = useAuth();
  const [calendars, setCalendars] = useState([]);
  const { createCalendar, getCalendar, gapiInited, createAcl } = useCalendar();
  const [createCalendarLoading, setCreateCalendarLoading] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [AllAthlete, setAllAthlete] = useState([]);
  const [SelectedEvents, setSelectedEvents] = useState([]);
  const [ChangeDateTable, setChangeDateTable] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const getEventDate = (event_id) => {
    if (!athlete) {
      return;
    }
    const event = athlete.events.find((e) => e.id === event_id);
    return event.date;
  };

  const reloadCalendars = () =>
    getCalendarsByCoachIdAthleteId(supabase, user.id, params.id).then(
      (calendars) => {
        setCalendars(calendars.data);
      }
    );
  useEffect(() => {
    if (!user) {
      return;
    }
    reloadCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const createAthleteCalendar = async (user) => {
    setCreateCalendarLoading(true);
    try {
      const calendarData = {
        summary: `${user.full_name} / ${getAthleteName(
          athlete
        )} (C+ Calendar) `,
        description: `Coach+ Training Calendar for ${getAthleteName(athlete)}`,
        timeZone: getTimeZone(),
      };
      const calendar = await createCalendar(calendarData);
      const the_calendar = await getCalendar(calendar.id);
      await createAcl(calendar.id, athlete.email);

      const sbCalendar = {
        gcal_id: calendar.id,
        payload: the_calendar,
        enabled: true,
        public_url: `https://calendar.google.com/calendar/embed?src=${decodeURIComponent(
          calendar.id
        )}&ctz=${decodeURIComponent(calendar.timeZone)}`,
        athlete_id: params.id,
        coach_id: user.id,
        time_zone: calendarData.timeZone,
      };
      await insertCalendar(supabase, sbCalendar);
      await fetchAthlete();
      setShowSuccess(true);
      setCreateCalendarLoading(false);

    } catch (e) {
      console.error(e);
      setCreateCalendarLoading(false);
    }
  };
  

  const [checkInElements, setCheckInElements] = useState([]);

  const fetchAthlete = async () => {
    const { data: athletes } = await getAthleteProfile(supabase, params.id);
    const { data: allAthlete } = await getAthletesByCoachId(supabase, user?.id);
    setAllAthlete(allAthlete.map((at) => at.athletes));
    setAthlete(athletes[0]);
  };

  useEffect(() => {
    if (gapiInited) {
      fetchAthlete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gapiInited]);

  useEffect(() => {
    const fetchData = async () => {
      const elements = await renderCheckIns();
      setCheckInElements(elements);
    };
    if (athlete) {
      fetchData();
    }
  }, [athlete]);

  const isWaitingOnboarding =
    athlete && athlete.onboarding_form_response[0].status !== "completed";

  const renderCheckIns = async () => {
    const checkInElements = await Promise.all(
      athlete.check_ins.map(async (c, i) => {
        // Get the event ID from the check-in
        const checkInEventId = c.event_id;

        // Find the corresponding event in athlete.events
        const matchingEvent = athlete.events.find(
          (event) => event.id === checkInEventId
        );

        // Check if a matching event was found
        if (matchingEvent) {
          const eventDate = getEventDate(checkInEventId);
          const date = new Date(eventDate);
          const options = { weekday: "short", month: "short", day: "numeric" };
          const formattedDate = date.toLocaleDateString("en-US", options);

          const { data: plan } = await getPlanByIdClient(
            supabase,
            matchingEvent.plan_client_id
          );

          return (
            <React.Fragment key={i}>
              <Box pl={3} pt={2} pr={3}>
                <ListItemText
                  sx={{
                    ".css-mbfek": {
                      paddingLeft: "16px",
                      marginTop: "14px",
                    },
                    ".css-yb0lig": {
                      display: "flex",
                    },
                  }}
                  primary={
                    <div style={{display:"flex",alignItems:"center"}}>
                      <div
                        style={{
                          backgroundColor: " #013B12",
                          minHeight: "25px",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          color: "#02BD36",
                          fontSize: "12px",
                          alignItems: "center",
                          padding: "0px 11px",
                          width: "fit-content",
                        }}
                      >
                        {formattedDate}
                      </div>
                      <div
                        style={{
                          minHeight: "25px",
                          display: "flex",
                          justifyContent: "center",
                          color: "#013B12",
                          fontSize: "14px",
                          alignItems: "center",
                          width: "fit-content",
                          marginLeft: "10px",
                        }}
                      >
                        {plan[0].name}
                      </div>
                    </div>
                  }
                  secondary={
                    <div
                      style={{
                        backgroundColor: "#E9FFCE",
                        color: "#525A65",
                        fontWeight: "400 !important",
                        fontSize: "12px",
                        display: "flex",
                        padding: "1px 16px",
                        alignItems: "center",
                        width: "fit-content",
                        borderTopLeftRadius: "16px",
                        borderBottomRightRadius: "16px",
                        borderTopRightRadius: "16px",
                        lineHeight: "1 !important",
                        marginTop:"13px",
                        marginLeft:"15px",
                      }}
                    >
                      <pre>{c.feedback ? c.feedback : "Not answered yet"}</pre>
                    </div>
                  }
                />
              </Box>
            </React.Fragment>
          );
        }
      })
    );

    return checkInElements;
  };

  useEffect(() => {
    if (!user) return;
    
    getAthletesByCoachId(supabase, user.id)
      .then(({ data }) => {
        if (data) {
          setAllAthlete(data.map(a => a.athletes));
        }
      })
      .catch(error => {
        console.error("Error fetching athletes:", error);
      });
  }, [user, supabase]);

  if (!athlete) {
    return null;
  }
  return (
    <div>
      {eventModalOpen && (
        <CreateEventModal
          open={eventModalOpen}
          handleClose={() => setEventModalOpen(false)}
          onSuccess={() => {
            reloadCalendars();
          }}
          athleteId={params.id}
        />
      )}
      {editModal && (
        <EditModal
          editModalOpen={editModal}
          setEditModalOpen={setEditModal}
          athlete={athlete}
          onSuccess={() => fetchAthlete()}
        />
      )}
      <Dialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={!!open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Feedback for {new Date(open?.created_at).toLocaleDateString()}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            {open?.feedback || "No feedback provided"}
          </Typography>
        </DialogContent>
      </Dialog>
      <PageContainer>
        <Grid container spacing={2} sx={{ height: "100px", mb: 2 }}>
          <Grid
            item
            xs={6}
            sx={{
              paddingLeft: "35px !important",
              paddingTop: "32px !important",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Autocomplete
              options={AllAthlete}
              value={athlete}
              onChange={(e, v) => {
                if (v?.id) {
                  navigate(`/athlete/${v.id}`, { replace: true });
                  setAthlete(v);
                  setSelectedEvents([]);
                  setChangeDateTable(null);
                  getCalendarsByCoachIdAthleteId(supabase, user.id, v.id).then((calendars) => {
                    setCalendars(calendars.data);
                  });
                }
              }}
              sx={{
                maxWidth: "295px",
                width: "100%",
                maxHeight: "48px",
                ".MuiInputBase-root": {
                  borderRadius: "20px",
                  border: "1px solid #DAD9D8",
                  backgroundColor: "#FBFBFB",
                },
                ".MuiInputBase-input": {
                  fontSize: "12px",
                  padding: "14px 24px",
                  color: "#525A65",
                  fontWeight: "400 !important",
                },
              }}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  placeholder="Choose Client..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <div style={{ 
                        display: "flex",
                        alignItems: "center",
                        pointerEvents: "none" 
                      }}>
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.782 0L6.5 5.475 1.218 0 0 1.2625 5.282 6.7375 6.5 8 13 1.2625 11.782 0Z" fill="#DAD9D8"/>
                        </svg>
                      </div>
                    ),
                    sx: {
                padding:"6px 15px!important",

                      "&.MuiOutlinedInput-root": {
                        "& fieldset": { border: "none" },
                        "&:hover fieldset": { border: "none" },
                        "&.Mui-focused fieldset": { border: "none" },
                      },
                    },
                  }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{ 
                    borderRadius: "16px", 
                    backgroundColor: "#FBFBFB",
                    marginTop: "8px",
                    
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  "& .MuiAutocomplete-option": {
                    color: "#525A65",
                    fontSize: "14px",
                    borderBottom: "1px solid #CECECE",
                    padding: "10px 10px",
                    margin: "0px 10px",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  },
                },
              }}
              getOptionLabel={(option) => option.full_name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: "32px !important",
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => window.location.href = `mailto:${athlete.email}`}
              type="button"
              sx={buttonStyle2}
            >
              Send an email
            </Button>
            <Button
              onClick={() => window.location.href = `tel:${athlete.phone_number}`}
              type="button"
              sx={buttonStyle}
            >
              Give a call
            </Button>
            <Button
              onClick={() => {
                if (window.confirm("Are you sure you want to delete this athlete?")) {
                  deleteAthlete(supabase, athlete.id).then(() => navigate("/"));
                }
              }}
              type="button"
              sx={buttonStyle3}
            >
              Delete Client
            </Button>
          </Grid>
        </Grid>
        <Paper
          sx={{
            mb: 4,
            backgroundColor: "transparent",
            borderRadius: "24px",
            border: "none",
            display: athlete && athlete.calendars.length > 0 ? "none" : "",
          }}
          variant="outlined"
        >
          <Box sx={{ padding: 3 }}>
            {isWaitingOnboarding && (
              <Box display="flex" alignItems="center" mb={1}>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="900"
                    sx={{ mr: 2, mb: 1 }}
                  >
                    {getAthleteName(athlete)}
                  </Typography>
                  <GetAthleteStatus athlete={athlete} />
                  {/* {isWaitingOnboarding && ( */}
                  <Button
                    target="_blank"
                    href={`/onboarding-form/${athlete.onboarding_form_response[0].id}`}
                  >
                    Go to Onboarding Form
                  </Button>
                  {/* )} */}
                </Box>
                <IconButton
                  size="small"
                  href={`tel:${athlete.phone_number}`}
                  sx={{ mr: 1, marginLeft: "auto" }}
                >
                  <Phone />
                </IconButton>
                <IconButton size="small" href={`mailto:${athlete.email}`}>
                  <Mail />
                </IconButton>
                <IconButton size="small" onClick={() => setEditModal(true)}>
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete this athlete?"
                      )
                    ) {
                      deleteAthlete(supabase, athlete.id).then(() =>
                        navigate("/")
                      );
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            )}

            {!isWaitingOnboarding && (
              <Box>
                {athlete.calendars.length === 0 && (
                  <Typography variant="subtitle2">
                    <Box display="flex" flexDirection="column" alignItems="start" mb={3}>
                <Box 
                      sx={{
                        height: '19px',
                        fontFamily: "'Bricolage Grotesque'",
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#525A65',
                        paddingLeft:"4px",
                      }}
                    >
              {getAthleteName(athlete)} has no calendar! Create one to assign trainings to them.
            </Box>

                      <Button
                        onClick={() => createAthleteCalendar(user)}
                        color="success"
                        sx={{fontFamily:"Bricolage Grotesque",mt:3,ml:0,fontSize:"12px",fontWeight:"500",lineHeight:"14px",letterSpacing:"1px",borderRadius:"16px",padding:"15px 15px",border:"1px solid #02BD36",color:"#02BD36"}}
                        disabled={createCalendarLoading}
                      >
                        Create Calendar
                      </Button>
                      {createCalendarLoading && (
                        <CircularProgress
                          size={20}
                          sx={{ ml: 2 }}
                          color="info"
                        />
                      )}
                     { /*<Button
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you want to delete this athlete?"
                            )
                          ) {
                            deleteAthlete(supabase, athlete.id).then(() =>
                              navigate("/")
                            );
                          }
                        }}
                        type="button"
                        sx={{ ...buttonStyle3, marginLeft: "auto !important" }}
                      >
                        Delete Client
                      </Button>*/}
                    </Box>
                  </Typography>
                )}
                {athlete.onboarding_form_response[0] &&
                  athlete.onboarding_form_response[0].custom_responses && (
                    <Box>
                      {Object.keys(
                        athlete.onboarding_form_response[0].custom_responses
                      ).map((key) => (
                        <Box key={key} mb={1}>
                          <Typography variant="subtitle2" mb={0.5}>
                            {key}
                          </Typography>
                          <Typography>
                            {
                              athlete.onboarding_form_response[0]
                                .custom_responses[key]
                            }
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        </Paper>
        {!isWaitingOnboarding && (
          <>
            {athlete && athlete.calendars.length > 0 && (
              <CardDayAtheletesPlan
                athlete={athlete}
                OnchangeEvents={setSelectedEvents}
                onChangeDateTable={setChangeDateTable}
                curDate={ChangeDateTable}
              />
            )}
            <Grid2 container spacing={2}>
              <Grid2 xs={12} md={6}>
                {athlete && athlete.calendars.length > 0 && (
                  <ClientDetailTraining
                    events={SelectedEvents}
                    height={600}
                    date={ChangeDateTable}
                    currentAthlete={athlete}
                    reload={() => {
                      fetchAthlete();
                    }}
                    onNewEventClick={() => {
                      setEventModalOpen(true);
                    }}
                    onEventDelete={() => {
                      reloadCalendars();
                    }}
                  />
                )}
              </Grid2>
              <Grid2 xs={12} md={6} sx={{ borderRadius: "24px" }}>
                {athlete && athlete.calendars.length > 0 && (
                  <Paper
                    variant="outlined"
                    sx={{
                      overflow: "auto",
                      height: 600,
                      backgroundColor: "#FBFBFB",
                      borderRadius: "24px",
                      border: "none",
                    }}
                  >
                    <Box sx={{ maxHeight: "600px" }}>
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                          minHeight: "580px",
                        }}
                        subheader={
                          <ListSubheader
                            sx={{
                              color: "#525A65",
                              fontWeight: "600 important",
                              fontSize: "16px ",
                              pb: 1,
                              pl: 3,
                              pt: 2,
                            }}
                          >
                            Client Training Feeback
                          </ListSubheader>
                        }
                      >
                        <Divider />
                        {checkInElements}
                      </List>
                    </Box>
                  </Paper>
                )}
              </Grid2>
            </Grid2>
          </>
        )}
      </PageContainer>
      <Modal open={showSuccess} onClose={() => setShowSuccess(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "540px",
            height: "550px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            outline: "none",
          }}
        >
          <Typography sx={{ fontSize: "16px" }} variant="h6" component="h2">
            Success!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              <SuccessIcon />
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: "14px",
                fontFamily: "Bricolage Grotesque!important",
                textAlign: "center",
                maxWidth: "370px",
                textAlign:"center",
                color: "#000!important",
                fontWeight:"400!important"
              }}
            >
The calendar has been created!
                
                <br />
              You can quickly begin assigning training sessions and managing their schedule.
            </Typography>
          </Box>
          <Button
            onClick={() => setShowSuccess(false)}
            sx={{
              backgroundColor: '#EEEEEE',
              borderRadius: '20px',
              color:"#0F0F0F",
              px: 2,
              fontSize:"15px",
              fontWeight:"400!important",
              border: '1px solid #525A65',
              py: 1,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#EEEEEE',
              }
            }}
          >
            Okay!
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

const buttonStyle = {
  color: "#525A65",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "76px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#525A65",
  },
  float: "right",
  lineHeight: "1",
  height: "26px",
  textTransform: "none",
};

const buttonStyle2 = {
  color: "#525A65",
  float: "right",
  lineHeight: "1",

  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  maxWidht: "99px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#525A65",
  },
  margin: "0px  0px 0px 15px !important",
  height: "26px",
  textTransform: "none",
};

const buttonStyle3 = {
  color: "#EC0B43",
  float: "right",
  border: "solid 1px",
  borderRadius: "30px",
  fontSize: "12px",
  backgroundColor: "unset",
  padding: "6px 9px 6px 9px",
  lineHeight: "1",
  maxWidht: "99px",
  cursor: "pointer",
  ".buttonCal:hover": {
    backgroundColor: "unset",
    color: "#EC0B43",
  },
  margin: "0px  15px 0px 0px !important",
  height: "26px",
  textTransform: "none",
};
