import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { endOfDay, startOfDay } from "date-fns";
import { useEffect, useState, useMemo } from "react";
import { getReadableTextColor } from "../../utils/styles/theme";
import {
  deleteSBEvent,
  getPlanByIdClient,
  deletePlanClient,
} from "../../services/query";
import { supabase } from "../../services/supabase";
import { useCalendar } from "../../providers/CalendarProvider";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import {
  buttonStyle,
  buttonStyle2,
  buttonStyle3,
  close,
  buttonStyle4,
} from "./style";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const DashboardCalendarComponent = ({
  defaultView,
  height,
  title,
  toggles,
  views,
  calendars,
  onNewEventClick,
  onEventSelect,
  onReloadRequest,
  viewYourCalendar,
  onEventDelete = () => null,
  onCalendarToggle,
}) => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { deleteEvent } = useCalendar();

  const deleteCEvent = async (calId, evId, gc_id, plcid) => {
    await deleteEvent(calId, gc_id);
    await deleteSBEvent(supabase, evId);
    await deletePlanClient(supabase, plcid);

    onEventDelete(calId, evId);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      if (!calendars || calendars.length === 0) {
        return;
      }

      const today = new Date();
      let date = today.getDate();

      const allEvents = await Promise.all(
        calendars
          .filter((cal) => cal.enabled) // Filter enabled calendars
          .flatMap(async (cal) => {
            // Use flatMap to avoid the need for .flat() later
            const eventsWithPlans = await Promise.all(
              cal.events.map(async (event) => {
                // Map over the events array
                const eventDate = new Date(event.date);
                const evendate = eventDate.getDate();
                const { data: plan } = await getPlanByIdClient(
                  supabase,
                  event.plan_client_id
                );
                return {
                  allday: true,
                  color: evendate === date ? "#2998E9" : null,
                  backgroundColor: evendate === date ? "#043A8D" : null,
                  resource: event.payload,
                  start: startOfDay(event.date),
                  end: endOfDay(event.date),
                  id: event.id,
                  calendarId: cal.payload.calendarId,
                  title: cal.athletes.full_name,
                  planName: plan[0]?.name,
                  planID: plan[0]?.id,
                };
              })
            );

            return eventsWithPlans; // Return the array of events with plans
          })
      );

      // Flatten the result since flatMap returns an array of arrays
      const flattenedEvents = allEvents.flat();
      setEvents(flattenedEvents);
    };

    fetchEvents(); // Call the async function
  }, [calendars]);

  return (
    <>
      {!!selectedEvent && (
        <EventModal
          event={selectedEvent}
          open={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onDelete={(calId, gcalEvId, evId, plcid) => {
            if (window.confirm("Are you sure you want to delete this event?")) {
              deleteCEvent(calId, evId, gcalEvId, plcid);
              setSelectedEvent(null);
              onEventDelete(calId, evId, gcalEvId);
            }
          }}
        />
      )}
      <Paper
        variant="outlined"
        mb={3}
        sx={{
          borderRadius: "24px",
          height: height,
          backgroundColor: "#FBFBFB !important",
          border: "none",
        }}
      >
        <Box pl={3} pr={3} pt={3} pb={0} display="flex" alignItems="center">
          <Typography
            variant="subtitle"
            sx={{
              color: "#525A65",
              fontWeight: "600 !important",
              fontSize: "16px",
            }}
          >
            {title}
          </Typography>
          {/* <Button
                        sx={buttonStyle4}
                        onClick={onNewEventClick}
                    >
                        + Add training
                    </Button> */}
        </Box>
        {toggles && (
          <Box px={2} pt={2}>
            {calendars.map((cal) => (
              <Chip
                key={cal.calendarId}
                size="small"
                label={cal?.payload.title?.replace("@gmail.com", "")}
                variant={!cal.enabled ? "outlined" : "filled"}
                sx={{
                  fontWeight: "600 !important",
                  backgroundColor: !cal.enabled
                    ? "transparent"
                    : cal.payload.color,
                  color: !cal.enabled
                    ? cal.payload.color
                    : getReadableTextColor(cal.payload.color),
                  margin: 0.5,
                  "&:hover": {
                    backgroundColor: cal.payload.color,
                    color: cal.enabled
                      ? getReadableTextColor(cal.payload.color)
                      : cal.payload.color,
                  },
                }}
                onClick={() => {
                  onCalendarToggle(cal.id);
                }}
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            px: 1,
            py: 2,
            fontSize: "12px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <MyCalendar
            events={events}
            setSelectedEvent={setSelectedEvent}
          ></MyCalendar>
        </Box>
        {viewYourCalendar ? (
          <>
            <Divider sx={{ padding: "0px" }} />
            <Box
              p={2}
              pb={0}
              display="flex"
              alignItems="center"
              sx={{ justifyContent: "center", paddingBottom: "10px" }}
            >
              <Link
                href="/calendar"
                sx={{
                  cursor: "pointer",
                  color: "#525A65",
                  fontWeight: "600 !important",
                  fontSize: "12px",
                  marginTop: "16px",
                }}
              >
                View your calendar
              </Link>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Paper>
    </>
  );
};

const EventModal = ({ event, onClose, open, onDelete }) => {
  const extractHref = (str) => {
    const match = str.match(/href="([^"]+)"/);
    return match ? match[1] : null;
  };
  const date = new Date(event.start);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{
        ".MuiPaper-root": {
          maxWidht: "529px",
          height: "294px",
          borderRadius: "24px",
          backgroundColor: "#FBFBFB",
        },
      }}
    >
      <Box sx={{ padding: "40px", height: "294px" }}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "14px",
              fontWeight: "400 !important",
              color: "#525A65",
            }}
          >
            {formattedDate}
          </Typography>
          <Button
            startIcon={<CloseIcon />}
            size="small"
            onClick={onClose}
            sx={close}
          ></Button>
        </Box>

        <DialogContent sx={{ padding: "25px 0px 10px 0px" }}>
          {/* <Typography variant="subtitle2">Training Program: </Typography> */}
          {/* <div dangerouslySetInnerHTML={{ __html: event.resource.description }} /> */}
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "20px",
              fontWeight: "600 !important",
              color: "#525A65",
            }}
          >
            {event.title?.split(" - ").pop()}
          </Typography>
        </DialogContent>
        <DialogContent sx={{ padding: "10px 0px 30px 0px" }}>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "400 !important",
              color: "#525A65",
            }}
          >
            for{" "}
            <a
              href="#"
              style={{
                fontSize: "14px",
                fontWeight: "400 !important",
                color: "#2998E9",
              }}
            >
              {event.resource.organizer.displayName}
            </a>
          </span>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "10px 0px 0px 0px !important",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() =>
              (window.location.href = extractHref(event.resource.description))
            }
            type="button"
            style={buttonStyle2}
            className="buttonCal"
          >
            View training plan
          </Button>
          <Button
            onClick={() =>
              onDelete(
                event.calendarId,
                event.resource.id,
                event.id,
                event.planID
              )
            }
            type="button"
            style={buttonStyle3}
            className="buttonCal"
          >
            Delete event
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

// Custom Table Component

export const EventTable = ({ events, currentDate, setSelectedEvent }) => {
  const [sortOrder, setSortOrder] = useState("asc"); // State to track sorting order

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week

  // Memoize filtered events to avoid recalculating on every render
  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      return eventStart <= endOfWeek && eventEnd >= startOfWeek;
    });
  }, [events, startOfWeek, endOfWeek]);

  // Sort events based on the selected order
  const sortedEvents = useMemo(() => {
    return filteredEvents.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
  }, [filteredEvents, sortOrder]);

  // Toggle sort order
  const handleSortToggle = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  return (
    <TableContainer sx={{ height: 432, paddingRight: "16px" }}>
      {sortedEvents.length > 0 ? (
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            borderSpacing: "10px 11px",
            borderCollapse: "unset",
            border: "none !important",
          }}
        >
          <TableBody>
            {sortedEvents.map((event, index) => {
              const eventDate = new Date(event.start).toDateString();
              const isFirstOccurrence =
                index === 0 ||
                new Date(sortedEvents[index - 1].start).toDateString() !==
                  eventDate;
              return (
                <TableRow
                  role="checkbox"
                  tabIndex={-1}
                  key={event.id || event.title}
                >
                  <TableCell
                    sx={{
                      color: event.color,
                      fontSize: "12px",
                      width: "100px",
                      padding: "2px 30px 14px 35px",
                      border: "none !important",
                      lineHeight: "0 !important",
                    }}
                  >
                    {isFirstOccurrence && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <span style={{ width: "35px", textAlign: "center" }}>
                          {new Date(event.end)
                            .toString()
                            .split(" ")[0]
                            .toUpperCase()}
                        </span>
                        <span
                          style={{
                            backgroundColor: event.backgroundColor,
                            width: "35px",
                            height: "35px",
                            textAlign: "center",
                            paddingTop: "17px",
                            fontSize: "18px",
                            borderRadius: "8px",
                          }}
                        >
                          {new Date(event.end).getDate()}
                        </span>
                      </div>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => setSelectedEvent(event)}
                    sx={{
                      cursor: "pointer",
                      fontSize: "12px",
                      borderRadius: "8px",
                      backgroundColor: "#F1F3F4 !important",
                      padding: "18px 22px 18px 22px!important",
                      borderBottom: "none !important",
                      lineHeight: "1 !important",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "600 !important",
                        fontSize: "10px",
                        color: "#0F0F0F",
                      }}
                    >
                      {event.title}
                    </span>
                    <span style={{ fontSize: "12px", color: "#525A65" }}>
                      {event.planName}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Box
          p={2}
          pb={0}
          display="flex"
          alignItems="center"
          sx={{ padding: "25px", justifyContent: "center" }}
        >
          <Typography
            variant="h3 "
            sx={{
              color: "#525A65",
              fontWeight: "600 !important",
              fontSize: "16px",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            Not Found Calendar
          </Typography>
        </Box>
      )}
    </TableContainer>
  );
};

export const MyCalendar = ({ events, setSelectedEvent }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const today = new Date();
    const currentWeekStart = new Date(currentDate);
    currentWeekStart.setDate(
      currentWeekStart.getDate() - currentWeekStart.getDay()
    ); // Get the start of the current week
    setDisable(currentWeekStart.toDateString() === today.toDateString());
  }, [currentDate]);

  const updateDateDisplay = () => {
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set to Sunday of the current week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to Saturday of the current week
    const monthName = startOfWeek
      .toLocaleString("default", { month: "long" })
      .toUpperCase();
    const startDay = startOfWeek.getDate();
    const endDay = endOfWeek.getDate();
    return `${monthName} ${startDay}-${endDay}`;
  };

  const handlePrevClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7); // Subtract 7 days
    setCurrentDate(newDate);
  };

  const handleNextClick = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7); // Add 7 days
    setCurrentDate(newDate);
  };

  return (
    <>
      <div
        className="custom-toolbar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 27px 10px 25px",
        }}
      >
        <Button
          onClick={handlePrevClick}
          type="button"
          style={buttonStyle}
          className="buttonCal"
          disabled={disable}
        >
          Previous Week
        </Button>
        <span style={{ marginBottom: "20px", color: "#525A65" }}>
          {updateDateDisplay().toUpperCase()}
        </span>
        <Button
          onClick={handleNextClick}
          type="button"
          style={buttonStyle}
          className="buttonCal"
        >
          Next Week
        </Button>
      </div>
      <Divider sx={{ paddingTop: "4px" }} />
      <EventTable
        events={events}
        currentDate={currentDate}
        setSelectedEvent={setSelectedEvent}
      />
    </>
  );
};
