export const formtextfieldStyle = {
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "16px  24px",
    color: "#525A65",
    backgroundColor: "#FBFBFB",
    borderRadius: "20px",
    fontWeight: "400 !important",
  },
};

export const formtextfiel2dStyle = {
  marginLeft: "15px",
  width: "9%",
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "16px 24px",
    color: "#525A65",
    backgroundColor: "#FBFBFB",
    borderRadius: "20px",
    fontWeight: "400 !important",
  },
};
export const formtextfiel3dStyle = {
  marginLeft: "15px",
  width: "27%",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiInputBase-root": {
    border: "none",
    padding: "0px !important",
    lineHeight: "1",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    color: "#525A65",
    backgroundColor: "unset",
    fontWeight: "400 !important",
  },
};

export const formtextselectStyle = {
  width: "50%",
  marginLeft: "15px",
  borderRadius: "20px",
  backgroundColor: "#FBFBFB",
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "16px  24px",
    color: "#525A65",
    borderRadius: "20px",
    fontWeight: "400 !important",
  },
};

export const formDescriptionStyle = {
  backgroundColor: "#FBFBFB",
  borderRadius: "20px",
  ".MuiInputBase-root": {
    borderRadius: "20px",
    border: "0px solid #DAD9D8",
  },
  ".MuiInputBase-input": {
    fontSize: "12px",
    padding: "0px 10px",
    fontWeight: "400 !important",
    color: "#525A65",
    backgroundColor: "#FBFBFB",
  },
};

export const close = {
  marginLeft: "auto",
  color: "#FBFBFB",
  border: "solid 1px",
  float: "right",
  textTransform: "none",
  paddingRight: "10px",
  padding: "13px",
  borderRadius: "100% !important",
  minWidth: "37px !important",
  fontSize: "12px",
  border: "0px !important",
  backgroundColor: "#FBFBFB",
  ".MuiSvgIcon-root": {
    fontSize: "18px !important",
    paddingLeft: "0px",
    color: "#525A65",
  },
  "&:hover": {
    color: "#525A65",
    backgroundColor: "#dddddd",
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px",
  },
};

export const AddVidoeandImageStyle = {
  float: "right",
  color: "#525A65",
  border: "solid 1px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  marginLeft: "auto",
  fontSize: "16px",
  maxHeight: "48px",
  padding: "11px 8px !important",
  minWidth: "75px !important",
  backgroundColor: "unset !important",
  "&:hover": {
    color: "#525A65",
    backgroundColor: "unset !important",
  },
};

export const CreateUpdateStyle = {
  float: "right",
  color: "#2998E9",
  border: "solid 1px",
  marginTop: "10px",
  textTransform: "none",
  paddingRight: "10px",
  borderRadius: "20px",
  marginLeft: "auto",
  fontSize: "16px",
  maxHeight: "48px",
  padding: "11px 8px !important",
  minWidth: "95px !important",
  backgroundColor: "unset !important",
  "&:hover": {
    color: "#2998E9",
    backgroundColor: "unset !important",
  },
};

export const DeleteIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5C0 2.23858 2.23858 0 5 0H15C17.7614 0 20 2.23858 20 5V15C20 17.7614 17.7614 20 15 20H5C2.23858 20 0 17.7614 0 15V5Z"
      fill="#EC0B43"
    />
    <g clip-path="url(#clip0_1664_1067)">
      <path
        d="M6.47287 5L9.99648 8.52361L13.5201 5L15 6.47287L11.4764 9.99648L15 13.5201L13.5201 15L9.99648 11.4764L6.47287 15L5 13.5201L8.52361 9.99648L5 6.47287L6.47287 5Z"
        fill="#F1F3F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1664_1067">
        <rect width="10" height="10" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export const VideoIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      filter: "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))",
      transition: "all 250ms ease-in-out",
      cursor: "pointer",
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.filter =
        "drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%))";
      e.currentTarget.style.transform = "translate(-50%, -50%) scale(1.2)";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.filter =
        "drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%))";
      e.currentTarget.style.transform = "translate(-50%, -50%) scale(1)";
    }}
  >
    <path
      d="M16 0C7.172 0 0 7.172 0 16C0 24.828 7.172 32 16 32C24.828 32 32 24.828 32 16C32 7.172 24.828 0 16 0ZM16 1.6C23.964 1.6 30.4 8.036 30.4 16C30.4 23.964 23.964 30.4 16 30.4C8.036 30.4 1.6 23.964 1.6 16C1.6 8.036 8.036 1.6 16 1.6ZM11.6 8.8V23.2L23.6 16L11.6 8.8Z"
      fill="white"
    />
  </svg>
);

export const DragDropIcon = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="14" r="2" transform="rotate(180 2 14)" fill="#999999" />
    <circle cx="2" cy="8" r="2" transform="rotate(180 2 8)" fill="#999999" />
    <circle cx="2" cy="2" r="2" transform="rotate(180 2 2)" fill="#999999" />
    <circle cx="8" cy="14" r="2" transform="rotate(180 8 14)" fill="#999999" />
    <circle cx="8" cy="8" r="2" transform="rotate(180 8 8)" fill="#999999" />
    <circle cx="8" cy="2" r="2" transform="rotate(180 8 2)" fill="#999999" />
  </svg>
);
export const SuperSetIcon = () => {
  return (
    <svg
      style={{
        maxWidth: "100%",
        minWidth: "100px",
        alignSelf: "stretch",
        height: "auto",
      }}
      width="529"
      height="11"
      viewBox="0 0 529 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="5" width="529" height="1" fill="#D9D9D9" />
      <path
        d="M98 5C98 2.23858 100.239 0 103 0H104C106.761 0 109 2.23858 109 5V6C109 8.76142 106.761 11 104 11H103C100.239 11 98 8.76142 98 6V5Z"
        fill="#DAD9D8"
      />
      <g clip-path="url(#clip0_1702_1309)">
        <path
          d="M104.073 2.18414L104.073 4.92486H106.814L106.816 6.07323L104.076 6.07323L104.076 8.81395H102.924L102.924 6.07323L100.184 6.07323L100.186 4.92486H102.927L102.927 2.18414H104.073Z"
          fill="#F1F3F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1702_1309">
          <rect
            width="5.5"
            height="5.5"
            fill="white"
            transform="translate(103.5 1.61133) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export const button1Style = {
  color: "#525A65",
  border: "solid 1px",
  fontSize: "12px",
  textTransform: "none",

  paddingRight: "10px",
  maxWidth: "110px",
  maxHeight: "26px",
  padding: "5px 11px 5px 8px !important",
  borderRadius: "30px",
  letterSpacing: "0em",
  lineHeight: "1 !important",
  ".MuiSvgIcon-root": {
    fontSize: "14px !important",
    paddingLeft: "2px",
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px",
  },
};
export const button2Style = {
  color: "#525A65",
  marginLeft: "auto",
  border: "solid 1px",
  fontSize: "12px",
  textTransform: "none",
  paddingRight: "10px",
  maxWidth: "130px",
  maxHeight: "26px",
  padding: "5px 11px 5px 8px !important",
  borderRadius: "30px",
  letterSpacing: "0em",
  lineHeight: "1 !important",
  ".MuiSvgIcon-root": {
    fontSize: "14px !important",
    paddingLeft: "2px",
  },
  ".MuiButton-icon": {
    widht: "10px",
    paddingLeft: "3px",
    marginRight: "0px",
  },
};
