import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { TypeError } from "./TypeError";

export const GroupSelect = React.memo(({ type, setType, triggered }) => {
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  return (
    <Box sx={{ minWidth: 120, margin: "20px 0" }}>
      {!["Solo", "Group", "Yoga"].includes(type) && triggered && <TypeError />}
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type</InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={type || ""}
          label="Type"
          onChange={handleTypeChange}
          sx={{
            backgroundColor: "#FBFBFB",
            borderRadius: "20px",
            fontSize: "12px",
            padding: "4px",
            color: "#525A65",
          }}
        >
          <MenuItem sx={{ fontSize: "13px" }} value={"Solo"}>
            Training Plan
          </MenuItem>
          <MenuItem sx={{ fontSize: "13px" }} value={"Group"}>
            Group/Gym Class
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
});
