import { PageContainer } from "../../components/PageContainer/PageContainer";
import { AthletesTable } from "../../components/AthletesTable/AthletesTable";
import { CoachProfileCard } from "../../components/CoachProfileCard/CoachProfileCard";
import { DashboardCalendarComponent } from "../../components/DashboardCalendar/dashboardCalendar";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";
// import { useSupabase } from "../../providers/AuthContextProvider";
// import { useCalendar } from "../../providers/CalendarProvider";
import { CreateEventModal } from "../../components/CreateEventModal/CreateEventModal";
import { getCalendarsByCoachId } from "../../services/query";
import { useSupabase } from "../../providers/AuthContextProvider";
import { Box, Button, Modal, Typography } from "@mui/material";

export const DashboardComponent = ({ user }) => {
  const [athletes, setAthletes] = useState([]);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [open, setOpen] = useState(false);
  const supabase = useSupabase();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reloadCalendars = () =>
    getCalendarsByCoachId(supabase, user.id).then((calendars) => {
      setCalendars(calendars.data);
    });
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const from = params.get("from");
    const status = params.get("status");

    if (from === "otp" && status === "success") {
      setOpen(true);
    }
  }, []);
  useEffect(() => {
    // TODO: handle calendar data
    if (!user) {
      return;
    }
    reloadCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    // Update last_dashboard_access when component mounts
    const updateLastAccess = async () => {
      try {
        const localNow = new Date();
        const year = localNow.getFullYear(); // Get full year (e.g., 2025)
        const month = String(localNow.getMonth() + 1).padStart(2, '0'); // Get month, add 1 since months are 0-indexed (e.g., '02' for February)
        const day = String(localNow.getDate()).padStart(2, '0'); // Get day, pad with leading 0 if needed (e.g., '10')
  
        const formattedDate = `${year}-${month}-${day}`; // Format to 'yyyy-mm-dd'
    
        const { data, error } = await supabase
          .from('users')
          .update({
            last_dashboard_access: formattedDate // Update with the formatted date
          })
          .eq('id', user.id);
    
        if (error) {
          console.error('Error updating last_dashboard_access:', error);
        } else {
          console.log('Successfully updated last_dashboard_access:', data);
        }
      } catch (err) {
        console.error('Failed to update last_dashboard_access:', err);
      }
    };
  
    if (user?.id) {
      updateLastAccess();
    }
  }, [user?.id, supabase]);
  
  
  if (!user) {
    return null;
  }

  return (
    <PageContainer>
      {eventModalOpen && (
        <CreateEventModal
          open={eventModalOpen}
          handleClose={() => setEventModalOpen(false)}
          onSuccess={() => {
            reloadCalendars();
          }}
        />
      )}
      <Grid2 container spacing={2} sx={{ position: "relative" }}>
        <Box>
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "540px",
                height: "540px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-evenly",
                outline: "black 1px solid",
                borderRadius: "24px",
              }}
            >
              <Typography sx={{ fontSize: "16px" }} variant="h6" component="h2">
                Success!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="h2">
                  <SuccessIcon />
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: "12px",
                    fontFamily: "'Bricolage Grotesque'",
                  }}
                >
                  Verification successful! You can now proceed.{" "}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{
                  width: "55px",
                  height: "40px",
                  borderRadius: "15px",
                  padding: "20px 35px",
                  fontFamily: "Bricolage Grotesque",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "19px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#EEEEEE",
                  border: "1px,solid,#525A65",
                  color: "#0F0F0F",
                }}
                onClick={handleClose}
              >
                Okay!
              </Button>
            </Box>
          </Modal>
        </Box>
        <Grid2 xs={12} lg={12}>
          <CoachProfileCard user={user} athletes={athletes} />
        </Grid2>
        <Grid2 xs={12} sm={6} md={6}>
          <DashboardCalendarComponent
            title="Weekly Calendar:"
            defaultView="agenda"
            height={"670px"}
            viewYourCalendar={true}
            views={["agenda"]}
            calendars={calendars}
            onNewEventClick={() => {
              setEventModalOpen(true);
            }}
            onEventDelete={() => {
              reloadCalendars();
            }}
          />
        </Grid2>
        <Grid2 xs={12} sm={6} md={6}>
          <AthletesTable
            onAthletesLoad={(athletes) =>
              setAthletes(athletes.map((a) => a.athletes))
            }
          />
        </Grid2>
      </Grid2>
    </PageContainer>
  );
};
export const SuccessIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="45" cy="45" r="45" fill="#013B12" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M66.8721 23.8585C65.701 23.9408 64.6123 24.4923 63.8536 25.3814L38.0645 55.2619L25.9741 42.7993C24.2834 41.0625 21.4959 41.0295 19.764 42.7252C19.764 42.7252 19.7557 42.7335 19.7475 42.7417C18.0238 44.4374 17.9908 47.195 19.6815 48.9236L35.0955 64.8105C36.7862 66.5474 39.5655 66.5968 41.3057 64.9093C41.4047 64.8187 41.4954 64.72 41.5779 64.6212L70.5008 31.0858C72.0843 29.2502 71.8781 26.4844 70.0473 24.9039C69.1648 24.1549 68.0267 23.7844 66.8721 23.8667V23.8585Z"
        fill="#02BD36"
      />
    </svg>
  );
};
